export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_TIMEOUT = "AUTH_TIMEOUT";

export const CART_START = "CART_START";
export const CART_SUCCESS = "CART_SUCCESS";
export const CART_FAIL = "CART_FAIL";
export const CART_NULL = "CART_NULL";
export const CART_RESET = "CART_RESET";
export const ERROR_TIMEOUT = "ERROR_TIMEOUT";

export const ORDER_CREATE = "ORDER_CREATE";
export const ORDER_START = "ORDER_START";
export const ORDER_VALIDATED = "ORDER_VALIDATED";
export const ORDER_FAIL = "ORDER_FAIL";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_FETCHED = "ORDER_FETCHED";
export const ORDER_RESET = "ORDER_RESET";

export const USER_START = "USER_START";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";

export const INFO_START = "INFO_START";
export const INFO_SUCCESS = "INFO_SUCCESS";
export const INFO_FAIL = "INFO_FAIL";

export const PRODUCT_START = "PRODUCT_START";
export const REVIEW_START = "REVIEW_START";
export const PRODUCT_SUCCESS = "PRODUCT_SUCCESS";
export const PRODUCT_DETAIL_SUCCESS = "PRODUCT_DETAIL_SUCCESS";
export const PRODUCT_FAIL = "PRODUCT_FAIL";
export const REVIEW_FAIL = "REVIEW_FAIL";

export const ADDRESS_START = "ADDRESS_START";
export const ADDRESS_SUCCESS = "ADDRESS_SUCCESS";
export const ADDRESS_TEMP = "ADDRESS_TEMP";
export const ADDRESS_FAIL = "ADDRESS_FAIL";
export const ADDRESS_RESET = "ADDRESS_RESET";
export const ADDRESS_UPDATE = "ADDRESS_UPDATE";
export const ADDRESS_CREATE = "ADDRESS_CREATE";
export const STATES_SUCCESS = "STATES_SUCCESS";
export const COUNTRIES_SUCCESS = "COUNTRIES_SUCCESS"

export const PAYMENT_START = "PAYMENT_START";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAIL = "PAYMENT_FAIL";
export const PAYMENT_RESET = "PAYMENT_RESET";
