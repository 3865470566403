import React, { useState, useContext, useEffect, useMemo } from 'react'

import {
  Image,
  Label,
  Card,
} from "semantic-ui-react";

import './ProductCard.css'
import { ccySymbol } from "../../utils";

export function ProductCard (props) {
  const item = props.item

  const getThumbnail = (item,bigScreen) => {

    if (item.img[0]) {
      if (bigScreen) {
        return item.img[0].img_mobile
      }
      else{
        return item.img[0].img_mobile
      }
    }
    else {
      return "https://api.coellalove.com/media/image.png"
    }
  }

  function getCCYsymbol (ccy) {


  }
  return (
    <Card onClick={()=>props.onTrigger(item)} className="product-card" price={item.price} name={item.name} category={item.category} style={{}} >

      <Image src={getThumbnail(item)} slug={item.slug} alt="gold filled jewelry necklaces earrings bracelets anklets"  />
      <Card.Content className="product-card-content" textAlign="left">

          <p className="product-card-header" >{item.title}</p>


          {item.discount_price ? (
            <React.Fragment>
            <span className="product-card-price-slash"><b>{ccySymbol[item.local_ccy]}{item.local_price}</b></span>
            <span className="product-card-price"><b>{ccySymbol[item.local_ccy]}{(item.local_price * (1-item.discount_price)).toFixed(2)}</b></span>

            <Label
            style={{ margin:"0em 0em" }}

            color="black"
            >
            On Sale
            </Label>
            </React.Fragment>
          ):(
            <span className="product-card-price"><b>{ccySymbol[item.local_ccy]}{item.local_price}</b></span>
          )}

          {!item.stocked && (


          <Label style={{margin:"0em 0em"}}>
            Sold Out
          </Label>

        )}

      </Card.Content>


    </Card>
  )
}
