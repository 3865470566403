import React from 'react'


import {
  Message,
  Icon
  } from "semantic-ui-react";


export function ErrorMessage (error) {

  const message = "Oops! We ran into a snag. Please check back later."

  return(
    <div >
    <Message icon >
    <Icon name='frown outline'/>
    <Message.Content>
      <Message.Header>
      {message}
      </Message.Header>
        {error.error.message}

      </Message.Content>
    </Message>
    </div>
  )
}
