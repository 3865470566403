import React, { useState, useEffect} from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { Link,useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import {findIndex, some, isEmpty, has, find, contains} from "lodash"
import "./ProductDetail.css";
import {
  Button,
  Dimmer,
  Grid,
  Header,
  Icon,
  Image,
  Loader,
  Message,
  Segment,
  Select,
  Divider,
  Rating,
  Popup,
  Container,
  Menu,
  Card,
  Input
} from "semantic-ui-react";
import { productDetailURL, urls } from "../../constants";
import { fetchCart, AddToCart } from "../../store/actions/cart";
import {CardCarousel, ImageCarousel} from '../../Components/CardCarousel/CardCarousel'
import {QuantitySelector} from '../../Components/QuantitySelector/QuantitySelector'
import {ProductReview} from '../../Components/ProductReview/ProductReview'
import { Spring,Transition,animated, config, useSpring} from 'react-spring'
import { css } from "@emotion/react";
import BounceLoader from "react-spinners/DotLoader";
import { fetchProductDetail, createProductReview } from "../../store/actions/products";
import {Media} from "../../App";
import {Helmet} from "react-helmet";
import { ccySymbol } from "../../utils";


export default function ProductDetailNew (props) {

  const item = useSelector((state) => state.products.item);
  const loading = useSelector((state) => state.products.loading);
  const cartError = useSelector((state) => state.cart.error);
  const userCCY = useSelector((state) => state.info.userCCY);

  const [itemPrice, setitemPrice] = useState(0);
  const [itemQty, setitemQty] = useState(1);
  const [menuItem, setmenuItem] = useState('description');
  const [btnDisable, setbtnDisable] = useState(false);
  const [formData, setformData] = useState({});
  const [giftCard, setGiftCard] = useState({});
  const [emailVisible, setEmailVisible] = useState(false);
  const [amountVisible, setAmountVisible] = useState(false);
  const [activeVariations, setactiveVariations] = useState([]);

  const {match: { params }} = props;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect( () => {
    if (userCCY) {
      window.scrollTo(0, 0);
      dispatch(fetchProductDetail(params.productID, history, userCCY));
    }
  },[params.productID,userCCY]
  );

  useEffect( () => {
      window.scrollTo(0, 0);
      if (!item.inventory_qty) {
        setbtnDisable(true)
      }
      else{
        setbtnDisable(false)
      };
  },[item]
  );

  const pushItemCallback = (category,slug) => {
    history.push(`/products/${urls[category]}/${slug}`);
  }

  //important!!!
  const getItemPrice = (itemObj) => {
    let filteredArray =[]
    const hasChain = itemObj.variations.find(c => c.name === "Chain")
    if(!!hasChain){
        if ('chain' in formData) {
          filteredArray = itemObj.variations.flatMap(cg => cg.item_variations).find(c => c.id === formData.chain);
          return filteredArray.local_price
        }
        else if ('length' in formData) {
            return item.local_price;
          }
    }
    else if ('length' in formData) {
        filteredArray = itemObj.variations.flatMap(cg => cg.item_variations).find(c => c.id === formData.length);
        return filteredArray.local_price
      }
    else if ('color' in formData) {
        filteredArray = itemObj.variations.flatMap(cg => cg.item_variations).find(c => c.id === formData.color);
        return filteredArray.local_price
      }
    else if ('amount' in formData) {
        filteredArray = itemObj.variations.flatMap(cg => cg.item_variations).find(c => c.id === formData.amount);
        const customAmount = Object.values(filteredArray).includes("Custom")

        if (customAmount) {
          if (has(giftCard,'amount')){
            return giftCard.amount
          }
          else{
            return '-'
          }
        }
        else{
          return filteredArray.local_price
        }
      }
    if (Object.keys(formData).length === 0) {
      return itemObj.local_price
    }
    else{
      return filteredArray.local_price
    }
  }

  const handleVariationBox = (name,id,value) => {
    if (value === "Email" && name==="type") {
      setEmailVisible(true)
    }
    else if (name==="type"){
      setEmailVisible(false)
    }
    if (value === "Custom" && name==="amount") {
      setAmountVisible(true)
    }
    else if (name==="amount"){
      setAmountVisible(false)

    }

    const updatedFormData = {
      ...formData,
      [name]: id
    };
    const updatedVariations = {
      ...activeVariations,
      [name]:value
    };
    setformData(updatedFormData);
    setactiveVariations(updatedVariations);

  };

  const handleChange = e => {
      let value = null;
      if (e.target.name === "amount") {
            value = parseInt(e.target.value, 10);
            if (isNaN(value)) {
              value = null
            }
          }
      else{
            value = e.target.value
      }
      const updatedFormData = {
        ...giftCard,
        [e.target.name]: value
      };
      setGiftCard({...updatedFormData});
  };

  const handleFormatData = formData => {
    // convert {colour: 1, size: 2} to [1,2] - they're all variations
    return Object.keys(formData).map(key => {
      return formData[key];
    });
  };

  const renderLengths = (prodID, category, collection, name, item_variations) => {
    let lengthCutoff = 0
    if (collection === "Stella Antoinette") {
      if(category==="Bracelet"){
        lengthCutoff = 7
      }
      else if(category==="Necklace"){
        lengthCutoff = 15
      }
    }

    const childLengths = find(item_variations, function(itemVar) {
      return Number(itemVar.value) < lengthCutoff;
    });

    if (childLengths) {
        return(
      <React.Fragment>

      <Grid.Row>
      <p style={{marginTop:"1em"}}>Child Size</p>
      {item_variations.map(itemVar => {
        if (itemVar.value <lengthCutoff) {
        return (
          <Button basic
          active={Object.values(activeVariations).includes(itemVar.value)}
          className="variation-box"
          key={itemVar.id}
          onClick={()=>handleVariationBox(name, itemVar.id,itemVar.value)}>
          <p>{itemVar.value}</p>
          </Button>
        )
      };
      })}
      </Grid.Row>
      <Grid.Row>
      <p style={{marginTop:"1em"}}>Mama Size</p>
      {item_variations.map(itemVar => {
        if (itemVar.value >= lengthCutoff) {
        return (
          <Button basic
          active={Object.values(activeVariations).includes(itemVar.value)}
          className="variation-box"
          key={itemVar.id}
          onClick={()=>handleVariationBox(name, itemVar.id,itemVar.value)}>
          <p>{itemVar.value}</p>
          </Button>
        )
      };
      })}
      </Grid.Row>
      </React.Fragment>
    )
    }
    else{
    return(
      <React.Fragment>
      {item_variations.map(itemVar => {
        return (
          <Button basic
          active={Object.values(activeVariations).includes(itemVar.value)}
          className="variation-box"
          key={itemVar.id}
          onClick={()=>handleVariationBox(name, itemVar.id,itemVar.value)}>
          <p>{itemVar.value}</p>
          </Button>
        );
      })}
      </React.Fragment>
    )
  }

  }
  const handleAddToCart = (slug,ccy) => {
    const variations = handleFormatData(formData);
    dispatch(AddToCart(slug, variations, itemQty, ccy, giftCard));
  };

  const IncrementItem = () => {
    if(itemQty < 9) {
      setitemQty(itemQty + 1)
    }
  }

  const DecreaseItem = () => {
    if(itemQty > 1) {
      setitemQty(itemQty - 1)
    }
  }
  const AnimatedImage = animated(Image);
  const AnimatedMessage = animated(Message);

  return (
    <div style={{marginTop:"1em"}}>
    <Helmet>
            <title>{item.title}</title>
            <meta name="description" content={item.description} />
    </Helmet>
      {isEmpty(item) ? (
        <Grid className="container-grid" centered>
          <Grid.Row stretched verticalAlign="middle" centered>
            <Grid.Column textAlign="center">
              <BounceLoader color="#9B9B9B" loading={loading}  />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ):(
      <Grid container stackable >
        <Grid.Row  columns={2} >
          <Grid.Column width={8} >
            <Spring
              config= {{ frequency: 1, tension:	170,friction: 26 }}
              from={{  x: -50, opacity: 0.25}}
              to={[
               {  x: 0, opacity: 1},
              ]}>
              {styles => (
              <animated.div style={styles} >
                <ImageCarousel images={item.img} />
              </animated.div>
              )}
            </Spring>
          </Grid.Column>
          <Grid.Column width={8} style={{ padding: "1em" }}>
            <Dimmer.Dimmable as={Grid} blurring dimmed={loading}>
            <Grid.Row columns={2}>
            <Grid.Column ><Link to={`/products/${urls[item.category]}`}>
            <Icon name='arrow left'/>
            </Link>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Rating icon='heart' defaultRating={0} maxRating={1} disabled={true}/>
            </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
            <Grid.Column width={10}>
            <p className="product-header" >
              {item.title}
            </p>
            </Grid.Column>
            <Grid.Column width={6}>

            {item.label === 'Sale' ? (
              <div>
              <Header>
                ${item.discount_price}
              </Header>

              <Header as='h3' floated='right' style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>
              {ccySymbol[item.local_ccy]}{itemPrice}
              </Header>
              </div>
            ):(
              <Header as='h3' floated='right'>{ccySymbol[item.local_ccy]}{getItemPrice(item)}</Header>
            )}

            </Grid.Column>
            </Grid.Row >
            {item.variations.map(v => {
              const name = v.name.toLowerCase();


              return (
                <React.Fragment key={v.id}>
                <Grid.Row >
                <Grid.Column>
                {name == "length" ? (
                  <React.Fragment>
                    <p style={{fontWeight:"500", color:"#989796", margin:"0em 0em 0.5em 0em", fontSize:"16px"}}>{v.name}</p>
                    {renderLengths(item.product_id,item.category,item.collection,name,v.item_variations)}
                  </React.Fragment>
                ):(
                  <React.Fragment>
                  <p style={{fontWeight:"500", color:"#989796", margin:"0em 0em 0.5em 0em", fontSize:"16px"}}>{v.name}</p>
                    {v.item_variations.map(itemVar => {
                      return (
                        <Button basic
                        active={Object.values(activeVariations).includes(itemVar.value)}
                        className="variation-box"
                        key={itemVar.id}
                        onClick={()=>handleVariationBox(name, itemVar.id,itemVar.value)}>
                        <p>{itemVar.value}</p>
                        </Button>
                      );
                    })}
                  </React.Fragment>
                )
                }

                </Grid.Column>
                </Grid.Row >
                {name == "amount" && amountVisible &&(
                  <Grid.Row>
                  <Grid.Column>
                  <Input
                  name="amount"
                  onChange={handleChange}
                  fluid
                  icon='dollar'
                  iconPosition='left'
                  placeholder='Enter amount'/>
                  </Grid.Column>
                  </Grid.Row>
                )}
                {name == "type" && emailVisible &&(
                  <Grid.Row>
                  <Grid.Column>
                  <Input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  fluid
                  icon='mail'
                  iconPosition='left'
                  placeholder='Email of recipient' />
                  </Grid.Column>
                  </Grid.Row>
                )}
                </React.Fragment>
              )
              })}

            <React.Fragment>
              {cartError && (
                <Grid.Row>
                <Grid.Column>
                <Spring
                  config= {{ velocity: 0.5, tension:	200,friction: 12 }}
                  from={{  y: -5}}
                  to={[
                    {  y: 0},
                 ]}>
                 {styles => (
                   <AnimatedMessage
                    style={styles}
                    size='small'
                    content={cartError}
                   />
                 )}
               </Spring>

               </Grid.Column>
               </Grid.Row>
              )}

              {btnDisable &&(
                <Grid.Row>
                <Grid.Column>
                <Message style={{borderRadius:"0em"}} size='tiny'>
                Out of stock, please check back later
                </Message>
                </Grid.Column>
                </Grid.Row>
              )}
            </React.Fragment>

            <Grid.Row columns={2}>
            <Grid.Column width={5} >
            <QuantitySelector DecreaseItem={DecreaseItem} IncrementItem={IncrementItem} itemQty={itemQty}/>

            </Grid.Column>
            <Grid.Column width={11} >

            <Button
            fluid
            color='black'
            className="checkout-button"
            onClick={() => handleAddToCart(item.slug, item.local_ccy)}
            disabled={btnDisable}
            >
            Add to Bag
            </Button>
            </Grid.Column>
            </Grid.Row >
            <Grid.Row>
              <Grid.Column>
              <Menu text widths={3}>
                <Menu.Item
                  name='description'
                  active={menuItem === 'description'}
                  onClick={()=>setmenuItem('description')}
                />
                <Menu.Item
                  name='specs'
                  active={menuItem === 'specs'}
                  onClick={()=>setmenuItem('specs')}
                />
                <Menu.Item
                  name='care'
                  active={menuItem === 'care'}
                  onClick={()=>setmenuItem('care')}
                />
              </Menu>
              </Grid.Column>
              </Grid.Row>
              <Grid.Row>
              <Grid.Column>
              <React.Fragment>
              {menuItem === 'description' && (
                <React.Fragment>
                <p>{item.description}</p>
                <p>All items are packaged in a pouch and box that's perfect for gift giving.
                Orders consisting of multiple items will be packaged in one pouch and box, up to three items per pouch/box.</p>
                <p>Click <Link to={'/products/extras'}style={{fontWeight:"800"}}>here</Link> if you would like to add additional gift pouches/boxes to your order.</p>
                </React.Fragment>
              )}
              {menuItem === 'specs' && (
                <React.Fragment>

                {item.bullets.map(b => {

                  return (
                  <React.Fragment key={b.id}>
                  <Grid>
                  <Grid.Row columns={2}>
                  <Grid.Column floated='left' width={4} style={{padding: "5px 15px"}}>
                  {b.name}
                  </Grid.Column>

                  <Grid.Column floated='right' width={12} style={{padding: "5px 15px"}}>
                  {b.value}
                  </Grid.Column>
                  </Grid.Row>
                  </Grid>
                  </React.Fragment>
                  )
                })}

                </React.Fragment>
              )}
              {menuItem === 'care' && (
                <p>At Coella Love, almost all of our jewelry is gold filled,
                 which means it is a solid layer of gold that is bonded with heat to another metal such as brass or sterling silver.
                 It is tarnish-resistant, hypoallergenic and will not turn your skin green.
                 <br/><br/>Gold Filled allows you to be active in your jewelry while also giving you the durability, quality and ability to have “your forever pieces”  while not having to pay the price of solid gold.
                 <br/><br/>Avoid harsh chemicals such as perfumes, cleaning products, hairsprays, sunscreens ect.
                 If exposed to these harsher chemicals simply wipe clean.
                 You can clean your gold filled pieces with warm water, mild dish soap and a clean cloth.


                </p>

              )}

              </React.Fragment>
              </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{marginTop:"2em"}}>
              <Grid.Column >
              <p>We Also Recommend</p>
              <Divider />
              <CardCarousel products={item.related_items.slice(0, 4)} pushItem={pushItemCallback}/>
              </Grid.Column>
              </Grid.Row>

              </Dimmer.Dimmable>


          </Grid.Column>

        </Grid.Row>
        <Divider/>
        <Grid.Row>
        <Grid.Column>
        <ProductReview avgRating={item.avg_rating} reviews={item.reviews} slug={item.slug}/>
        </Grid.Column>
        </Grid.Row>
      </Grid>
        )}
        </div>

  )

};
