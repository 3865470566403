import React, { createRef,useState, useContext, useEffect, useMemo,useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {
  Image,
  Label,
  Message,
  Card,
  Header,
  Input,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Dimmer,
  Segment,
  Menu,
  Button,
  Select,

  } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { MuuriComponent,getResponsiveStyle } from "muuri-react";
import {isEmpty} from "lodash";
import { useFilter, options,sortOptions,filterOptions } from "../../utils";
import { urls } from "../../constants";
import './ProductGrid.css'
import { createMedia } from '@artsy/fresnel';
import {Media} from "../../App";
import {ErrorMessage} from '../../Components/ErrorMessage/ErrorMessage'
import {ProductCard} from '../../Components/ProductCard/ProductCard'
import BounceLoader from "react-spinners/BounceLoader";
import { AddToCart } from "../../store/actions/cart";



const Item = ({item, pushItem, addToCart,bigScreen}) => {

  const [formData, setformData] = useState({});
  const style = useContext(ThemeContext);
  const onTrigger = (item) => {

        pushItem(item.category,item.slug);
    }

  const onAddToCart = (item) => {

        addToCart(item.slug, formData);
    }

  const handleChange = (e, { name, value }) => {

      const updatedFormData = {
        ...formData,
        [name]: value
      };

      setformData( updatedFormData );

  };

  const getThumbnail = (item,bigScreen) => {

    if (item.img[0]) {
      if (bigScreen) {
        return item.img[0].img_mobile
      }
      else{
        return item.img[0].img_mobile
      }
    }
    else {
      return "https://api.coellalove.com/media/image.png"
    }
  }

  return (
  <div style={{style}} className="muuri-item">
  <div className="muuri-item-content">
      {/* Safe zone, enter your custom markup */}
      <ProductCard onTrigger={onTrigger} item={item}/>
      {/* Safe zone ends */}
    </div>
  </div>
  )
};

const ThemeContext = React.createContext(null);

// Responsive theme provider.
const ThemeProvider = ({ children }) => {
  const isBigScreen = useMediaQuery({
    query: "(min-width: 768px)"
  });

  // Memoize the style.
  const style = useMemo(() => {
    return getResponsiveStyle({
      columns: isBigScreen ? 1 / 3 : 1 / 2,
      margin: "1%",
      ratio: 1,
    });
  }, [isBigScreen]);

  return (
    <ThemeContext.Provider value={style}>{children}</ThemeContext.Provider>
  );
};

export const ProductGrid = ({props, filterValue, filterCategory, filterSearch, sortValue, isBigScreen,itemCountCallback}) => {
  const dispatch = useDispatch()
  const filterFunction = useFilter(filterCategory,filterValue, filterSearch);
  const gridRef = useRef();

  const pushItemCallback = (category,slug) => {
        props.history.push(`/products/${urls[category]}/${slug}`);
  }

  const handleFormatData = formData => {
    // convert {colour: 1, size: 2} to [1,2] - they're all variations
    return Object.keys(formData).map(key => {
      return formData[key];
    });
  };

  const addToCartCallback = (slug,formData) => {
    const itemQty  = 1;
    const variations = handleFormatData(formData);
    dispatch(AddToCart(slug, variations, itemQty));
  }

  const children = props.products.map((item) => (
    <Item bigScreen={props.isBigScreen} item={item}
    key={item.product_id}
    category={item.category}
    collection={item.collection}
    name={item.title}
    price={item.price}
    pushItem={pushItemCallback}
    addToCart={addToCartCallback}/>
  ));

  useEffect(() => {
  // Update the document title using the browser API
  window.dispatchEvent(new Event('resize'));
  });

  return (
    <ThemeProvider>
      <MuuriComponent
        {...options}
        propsToData={({category, collection, name, price}) => ({category, collection,name, price})}
        filter={filterFunction}
        sort={sortValue}
        ref={gridRef}
        id="products"
        onFilter={function (shownItems, hiddenItems) {
          itemCountCallback(shownItems.length);
        }}
        >
        {children}
      </MuuriComponent>
      </ThemeProvider>
  );
};
