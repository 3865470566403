import React, {useState, useEffect} from "react";

import {
  Button,
  Divider,
  Dropdown,
  Header,
  Image,
  Menu,
  Sidebar,
  Icon,
  Item,
  Grid,
  Segment,
  Container
} from "semantic-ui-react";

import { NavLink, Link, withRouter } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/actions/auth";
import { fetchCart } from "../../store/actions/cart";

import { animated, useSpring} from 'react-spring'

import {QuantitySelector} from '../../Components/QuantitySelector/QuantitySelector'
import Logo from '../../static/img/Coella_Logo3.png';
import TopBanner from "../../Components/TopBanner/TopBanner";
import {LeftSidebar, RightSidebar} from "../../Components/Sidebar/Sidebar";
import './NavMenu.css'
import {Media} from "../../App";
import {endpoint,urls} from "../../constants"

import { categories, getThumbnail} from "../../utils";
import {isEmpty, has} from "lodash"


const CartNumber = (props) => {
  const [qty, setqty] = useState('');
  const [active, setactive] = useState(0);

  useEffect(() => {
    if (props.cart) {
      setqty(props.cart.totalQty)
      setactive(!active)
    }else{
      setqty('')
    }
  },[props.cart]);

  const { x } = useSpring({
    from: { x: 0 },
    x: active ? 1:0,
    config: { duration: 1000 } });


  return (
      <div className="cart-number-container" >

      <animated.div className="cart-number"
                style={{
                  opacity: x.interpolate({ range: [0, 1], output: [1, 1] }),
                  transform: x
                    .interpolate({
                      range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                      output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
                    })
                    .interpolate(x => `scale(${x})`)
                }}>
      {qty}
      </animated.div>
      <div >
      <Icon className="cart-number-icon" onClick={props.handleToggleBag} size="large" name='shopping bag'/>
      </div >
      </div >

  )
}

export function NavMenu2 (props) {
  const dispatch = useDispatch()
  const [menuFixed, setMenuFixed] = useState(false);
  const [overlayFixed, setOverlayFixed] = useState(false);
  const [rightVisible, setRightVisible] = useState(false);
  const [leftVisible, setLeftVisible] = useState(false);

  const authenticated = useSelector((state) => state.auth.token !== null);
  const authLoading = useSelector((state) => state.auth.loading);
  const cart = useSelector((state) => state.cart.shoppingCart);

  useEffect(() => {

    
  },[cart]);


  const handleGoToBag = () => {
    setRightVisible(!rightVisible);
    props.history.push("/order-summary");
  }

  return(
    <div>
      <LeftSidebar visible={leftVisible} hideSidebar={()=>setLeftVisible(false)}/>

      <RightSidebar visible={rightVisible} hideSidebar={()=>setRightVisible(false)}/>

      <Sidebar.Pusher dimmed={rightVisible}>
      <Menu
      fixed='top'
      borderless
      text
      className="top-menu"
      widths={3}
      >
      <Container className="nav-container">
      <Menu.Item>
      <div className="nav-menu-left">
        <Media at="mobile" >
          <Icon size="large" name='bars' onClick={()=>setLeftVisible(!leftVisible)}/>
        </Media>
      <Media greaterThan="mobile" >
      {(authenticated && !authLoading) ? (
        <div style={{textAlign:"left"}}>
           <Link to="/profile"> <span className="nav-link">MY ACCOUNT</span>
           </Link>
          </div>
      ) : (
        <div style={{textAlign:"left"}}>
           <Link to="/profile"> <span className="nav-link">SIGN IN</span>
           </Link>
          </div>
      )}
      </Media>
      </div>
      </Menu.Item>
      <Menu.Item>
      <Link to="/" ><Image
            size="medium"
            src={Logo}
      />
      </Link>
      </Menu.Item>
      <Menu.Item >
      <div className="nav-menu-right">
       <CartNumber cart={cart} handleToggleBag={()=>setRightVisible(!rightVisible)}/>
       </div>
      </Menu.Item>
      </Container>
      </Menu >

      <Media greaterThan="mobile" >
        <Menu text widths={6} className="product-menu">
          <Container className="product-nav-container">

          <Menu.Item className="product-nav" as={Link} to="/products/necklaces">
          Necklaces
          </Menu.Item>
          <Menu.Item className="product-nav" as={Link} to="/products/earrings">
           Earrings
           </Menu.Item>
          <Menu.Item className="product-nav" as={Link} to="/products/bracelets">
          Bracelets
          </Menu.Item >
          <Menu.Item className="product-nav" as={Link} to="/products/anklets">
          Anklets
          </Menu.Item>
          <Menu.Item className="product-nav" as={Link} to="/products/stella">
          Stella Antoinette
          </Menu.Item>
          <Menu.Item className="product-nav" as={Link} to="/products/extras">
          Extras
          </Menu.Item>
          </Container>
        </Menu>
      </Media>
     </Sidebar.Pusher>

    </div>
  )
}
class NavMenu extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      menuFixed: false,
      overlayFixed: false,
      visible: false,
      leftVisible: false,
    };
  }

  componentDidMount() {
    this.props.fetchCart();
    console.log(this.props.cart)
  }

  handleLeftSidebar =()=> {

    const { leftVisible } = this.state;

    this.setState({leftVisible:!leftVisible});
  }

  handleToggleBag = () => {
    const { visible } = this.state;
    const { user } = this.props;
    this.setState({
      visible: !visible
    });
  }

  handleGoToBag = () => {
    const { visible } = this.state;
    const { cart } = this.props;
    this.setState({
      visible: !visible
    });
    this.props.history.push("/order-summary")
  }

  renderVariations = orderItem => {
    let text = "";
    orderItem.item_variations.forEach(iv => {
      text += `${iv.name}: ${iv.value}`;
    });
    return text;
  };



  items = [
  {
    childKey: 0,
    image: '/images/wireframe/image.png',
    header: 'Header',
    description: 'Description',
    meta: 'Metadata',
    extra: 'Extra',
  },
  {
    childKey: 1,
    image: '/images/wireframe/image.png',
    header: 'Header',
    description: 'Description',
    meta: 'Metadata',
    extra: 'Extra',
  },
  ]

  render() {
    const { authenticated, cart, userLoading, token, user,loading, authLoading } = this.props;
    const { visible, leftVisible} = this.state;

    return (
      <React.Fragment>
          <LeftSidebar props={this.props} visible={leftVisible} hideSidebar={()=>this.setState({leftVisible:false})}/>
         <Sidebar
           as={Menu}
           direction='right'
           animation='overlay'
           icon='labeled'
           onHide={() => this.setState({visible:false})}
           vertical
           visible={visible}
           width='wide'
         >
         <Grid >
         <Grid.Row >
          <Grid.Column textAlign="left">
          <Icon name="arrow left" size='large' onClick={this.handleToggleBag}/>
          </Grid.Column >
         </Grid.Row>

         <Grid.Row>
         <Grid.Column textAlign="left">
            <Header as="h3" >
            Shopping Bag
            </Header>
            <Divider />
         </Grid.Column>
         </Grid.Row>
         <Grid.Row>
            <Grid.Column>

            {(has(cart, 'order_items')) ? (
            <React.Fragment>

                {cart.order_items.map((orderItem, i) => {
                  return (

                    <Segment basic key={i}>
                    <Grid>
                    <Grid.Row columns={3}>
                    <Grid.Column width={4} style={{padding:"0em"}}>
                    <Image size='small' src={getThumbnail(orderItem)} />
                    </Grid.Column>
                    <Grid.Column width={7} textAlign="left">
                      <div>
                      <p style={{margin:"0em",fontSize:"1.1em", fontWeight:"bold"}} >{orderItem.item.title}</p>
                      {orderItem.item_variations.length >0 && (
                        <React.Fragment>
                        {orderItem.item_variations.map(iv => {
                          return (
                            <p key={iv.id} style={{margin:"0.5em 0em"}}>{iv.name}: {iv.value}<br/></p>
                          );
                        })}
                        </React.Fragment>
                      )}
                      </div>
                      </Grid.Column>
                      <Grid.Column width={5} textAlign="right">
                      <div >

                      <p style={{fontSize:"1.1em", fontWeight:"bold"}}>Qty: {orderItem.quantity}</p>
                      </div>
                      </Grid.Column>
                      </Grid.Row>
                      </Grid>
                    </Segment>

                  );
                })}

            {cart.order_items.length < 1 ? (
              <Header as="h4">No items in your bag</Header>
            ) : null}
            </React.Fragment>
            ) : (
              <Header as="h4">No items in your bag</Header>
            )}

            </Grid.Column>
            </Grid.Row>
            <Grid.Row verticalAlign="bottom" style={{padding:"0em", marginTop:"4em"}}>
            <Grid.Column>
              <Button
              className="shopping-bag-button"
              fluid
              color='black'

              onClick={this.handleGoToBag}>
              View Shopping Bag
              </Button>
            </Grid.Column>
            </Grid.Row>
           </Grid>
         </Sidebar>

         <Sidebar.Pusher dimmed={visible}>




         <Menu
         fixed='top'
         borderless
         text
         className="top-menu"
         widths={3}
         >
         <Container className="nav-container">
         <Menu.Item>
         <div className="nav-menu-left">
           <Media at="mobile" >
             <Icon size="large" name='bars' onClick={this.handleLeftSidebar}/>
           </Media>
         <Media greaterThan="mobile" >
         {(authenticated && !authLoading) ? (
           <div style={{textAlign:"left"}}>
              <Link to="/profile"> <span className="nav-link">MY ACCOUNT</span>
              </Link>
             </div>
         ) : (
           <div style={{textAlign:"left"}}>
              <Link to="/profile"> <span className="nav-link">SIGN IN</span>
              </Link>
             </div>
         )}
         </Media>
         </div>
         </Menu.Item>
         <Menu.Item>
         <Link to="/" ><Image
               size="medium"
               src={Logo}
         />
         </Link>
         </Menu.Item>
         <Menu.Item >
         <div className="nav-menu-right">
          <CartNumber cart={cart} handleToggleBag={this.handleToggleBag}/>
          </div>
         </Menu.Item>
         </Container>
         </Menu >

         <Media greaterThan="mobile" >
           <Menu text widths={6} className="product-menu">
             <Container className="product-nav-container">

             <Menu.Item className="product-nav" as={Link} to="/products/necklaces">
             Necklaces
             </Menu.Item>
             <Menu.Item className="product-nav" as={Link} to="/products/earrings">
              Earrings
              </Menu.Item>
             <Menu.Item className="product-nav" as={Link} to="/products/bracelets">
             Bracelets
             </Menu.Item >
             <Menu.Item className="product-nav" as={Link} to="/products/anklets">
             Anklets
             </Menu.Item>
             <Menu.Item className="product-nav" as={Link} to="/products/stella">
             Stella Antoinette
             </Menu.Item>
             <Menu.Item className="product-nav" as={Link} to="/products/extras">
             Extras
             </Menu.Item>
             </Container>
           </Menu>
         </Media>


        </Sidebar.Pusher>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {

  return {
    authenticated: state.auth.token !== null,
    authLoading: state.auth.loading,
    cart: state.cart.shoppingCart,
    cartLoading: state.cart.loading,
    user: state.user.userData,
    userLoading: state.user.loading,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    fetchCart: () => dispatch(fetchCart()),

  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NavMenu)
);
