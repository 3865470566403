import React, { useState, useEffect  } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { PayPalMarks, PayPalButtons } from "@paypal/react-paypal-js";
import {ReactComponent as ApplePayMark} from '../../static/Apple_Pay_Mark.svg';
import {StripeForm} from "../../Components/StripeForm/StripeForm";

import PaymentCard from "../PaymentCard/PaymentCard";
import {AddressForm} from "../../Components/AddressForm/AddressForm";
import {isEmpty, has} from "lodash"
import { fetchPayments} from "../../store/actions/payment";
import { buildOrder } from "../../store/actions/order";
import { baseServer} from "../../constants";
import './Payment.css'

import {
  Grid,
  Divider,
  Header,
  Segment,
  Radio,
  Icon,
  Message,
  Button,
  Image
} from "semantic-ui-react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

export default function Payment (props) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch()
  const order = useSelector((state) => state.order.order)
  const orderError = useSelector((state) => state.order.error)
  const cart = useSelector((state) => state.cart.shoppingCart);
  const [billingAddress, setbillingAddress] = useState(order.shipping);
  const [changeBilling, setchangeBilling] = useState(false);
  const [paymentMethod, setpaymentMethod] = useState('S');
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(null);
  const [payment, setpayment] = useState({});
  const [applepayEligible, setApplepayEligible] = useState(false);

  const currency = cart.ccy;
  const style = {"layout":"vertical", "color":"black"};

  useEffect(() => {
    if(!stripe || !elements ) {
      return;
    }

    if (!currency){
      return;
    }

    const pr = stripe.paymentRequest({
      currency:currency,
      country:"US",
      requestPayerEmail:true,
      requestPayerName:true,
      total:{
        label:'Coella LLC',
        amount: 51,
      }
    })
    pr.canMakePayment().then((result)=> {
        if(result) {
          setApplepayEligible(true);

        }
    });
  },[stripe,elements,currency] )

  useEffect( () => {


    //console.log(orderError)
  },[orderError]
  );

  useEffect( () => {


    dispatch(buildOrder({"payment": {"payment_method":paymentMethod}}));
  },[paymentMethod, dispatch]
  );

  useEffect( () => {

    const formData = billingAddress;
    const updatedFormdata = {
      ...formData,
      'address_type':'B'
    };
    dispatch(buildOrder({"billing": updatedFormdata}));

  },[billingAddress, dispatch]
  );

  const buildBilling = (name, value) => {
    const formData = order.billing;

    const updatedFormdata = {
      ...formData,
      [name]: value,
      'address_type':'B'
    };
    const billing={"billing": updatedFormdata};
    dispatch(buildOrder(billing))
  };


  const toggle = () => setchangeBilling(!changeBilling)

  const handleChange = (e, { value }) => {
    setpaymentMethod(value);
    const payment={"payment":{"payment_method": value}}
    dispatch(buildOrder(payment))
  }

  return(
    <Segment basic className="order-segment">
    <p className="order-segment-header">
      Payment
    </p>
    <p><Icon name="lock" /> All transactions are secure and encrypted</p>


    <Grid>
    <Grid.Row>
    <Grid.Column>

    {((has(orderError, 'type'))) && (
      <Message color='violet'>
      <Message.Header>{orderError.message}</Message.Header>
      <p>{orderError.submessage}</p>
      </Message>
    )}

    <Segment.Group>
       <Segment>
         <Grid>
         <Grid.Row>
           <Grid.Column>
           <div style={{display: 'flex', alignItems: 'center'}}>
           <Radio
           label=''
           checked={paymentMethod === 'S'}
           value = "S"
           onChange={handleChange}/>
           <Icon name='credit card' size='large'/>
           <Header as='h4' style={{margin: '0em 0.5em'}}>Credit card</Header>
           </div>
           </Grid.Column>
         </Grid.Row>
         {paymentMethod === 'S' && (
         <Grid.Row>
           <Grid.Column>
             <StripeForm/>
           </Grid.Column>
         </Grid.Row>
          )}
         </Grid>
       </Segment>
       <Segment>
       <Grid>
       <Grid.Row>
        <Grid.Column>
         <div style={{display: 'flex', alignItems: 'center'}}>
         <Radio
         value = "P"
         checked={paymentMethod === 'P'}
         onChange={handleChange}/>
         <PayPalMarks fundingSource={'paypal'} />



         </div>
       </Grid.Column>
       </Grid.Row>

      </Grid>
       </Segment>

       {applepayEligible && (
       <Segment>
       <Grid>
       <Grid.Row>
        <Grid.Column>
         <div style={{display: 'flex', alignItems: 'center'}}>
         <Radio
         value = "A"
         checked={paymentMethod === 'A'}
         onChange={handleChange}/>

         <Image style={{margin:"0em 1em"}} src={`${baseServer}/media/Apple_Pay_Mark.jpg`} size='tiny'/>
         <Image style={{margin:"0em 1em"}} src={`${baseServer}/media/google-pay-mark.png`} size='tiny'/>


         </div>
       </Grid.Column>
       </Grid.Row>

      </Grid>
       </Segment>
     )}
    </Segment.Group>

    </Grid.Column>
    </Grid.Row>
    </Grid>
    {paymentMethod === 'S' && (
      <React.Fragment>
    <Header as='h4'>
     Billing Address
    </Header>
    <p>Select the address that matches your card.</p>
   <Divider />

   <Segment.Group>
      <Segment>
      <Radio label="Same as shipping"
      checked={!changeBilling}
      onChange={toggle}/>
      </Segment>
      <Segment>
      <Radio label="Use a different billing address"
      checked={changeBilling}
      onChange={toggle}/>
      {(changeBilling && (has(order, 'billing'))) && (
      <div style={{ marginTop: "1em" }}>
      <AddressForm addressChange={buildBilling} address={order.billing} addressError={orderError}/>
      </div >
      )}
      </Segment>
   </Segment.Group>
   </React.Fragment>
  )}
  </Segment>
  )
}
