import { CART_START, CART_SUCCESS, CART_FAIL,CART_RESET, CART_NULL } from "./actionTypes";
import axios from "axios";
import { authAxios, authAxiosStore } from "../../utils";
import { endpoint, orderSummaryURL, addToCartURL, reduceCartURL,removeCartURL,getCartURL } from "../../constants";


export const cartStart = () => {
  return {
    type: CART_START,
    loading: true,
  };
};

export const cartSuccess = data => {
  return {
    type: CART_SUCCESS,
    data
  };
};

export const cartNull = error => {
  return {
    type: CART_NULL,
    error: error
  };
};

export const cartFail = error => {
  return {
    type: CART_FAIL,
    error: error
  };
};

export const cartReset = () => {
  return {
    type: CART_RESET,

  };
};

export const fetchCart = () => {
  return dispatch => {
    dispatch(cartStart());
    axios
      .get(getCartURL)
      .then(res => {
        dispatch(cartSuccess(res.data));
        console.log(res.data);
      })
      .catch(err => {
        dispatch(cartNull(err.message));
      });
  };
};

export const resetCart = () => {
  return dispatch => {
    dispatch(cartReset());

  };
};




export const AddToCart = (slug, variations, itemQty, ccy, giftCard) => {
  return dispatch => {
  dispatch(cartStart());
  axios
    .post(addToCartURL, { slug, variations, itemQty, ccy, giftCard})
    .then(
      res => {
      dispatch(cartSuccess(res.data));
      localStorage.setItem("cartID", res.data.id);
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        dispatch(cartFail(error.response.data.message));
        setTimeout(() => {
            dispatch({ type: 'ERROR_TIMEOUT' })
          }, 3000)
      } else if (error.request) {
        // The request was made but no response was received
        console.log("ERROR REQUEST");
        dispatch(cartFail(error.message));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    });
};
};

export const RemoveQuantityFromCart = (id) => {
  return dispatch => {
  dispatch(cartStart());
  axios
    .post(reduceCartURL, { id })
    .then(res => {
      dispatch(cartSuccess(res.data))

    })
    .catch(err => {
      dispatch(cartFail(err));
    });
};
};

export const RemoveItem = (id) => {
  return dispatch => {
  dispatch(cartStart());
  axios
    .post(removeCartURL, {id})
    .then(res => {
      dispatch(cartSuccess(res.data))

    })
    .catch(err => {
      dispatch(cartFail(err));
    });
};
};
