import React, { Component } from "react";
import {
  Container,
  Grid,
  Item,
  Label,
  Dimmer,
  Loader,
  Segment,
  Header,
  Image,
  Divider
} from "semantic-ui-react";

import {endpoint} from "../../constants"
import './OrderPreview.css'
import { getThumbnail } from "../../utils";

export default function OrderPreview (props) {
  const cart = props.cart



  return (
    <div className="order-review">

      {cart && (
        <Segment basic className="order-segment" style={{ margin: "2em 0em" }}>
          <p className="order-segment-header">
             Your Items
          </p>
          <Item.Group relaxed>
            {cart.order_items.map((orderItem, i) => {
              return (
                <Segment basic key={i}>
                <Grid>
                <Grid.Row columns={3}>
                <Grid.Column width={4} style={{padding:"0em"}}>
                <Image size='small' src={getThumbnail(orderItem)} />
                </Grid.Column>
                <Grid.Column width={7} textAlign="left">
                  <div>
                  <p style={{fontSize:"1.1em", fontWeight:"bold"}}>{orderItem.item.title}</p>
                  {orderItem.item_variations.length >0 && (
                    <React.Fragment>
                    {orderItem.item_variations.map(iv => {
                      return (
                        <p key={iv.id} style={{margin:"0.5em 0em"}}>{iv.name}: {iv.value}<br/></p>
                      );
                    })}
                    </React.Fragment>
                  )}
                  </div>
                  </Grid.Column>
                  <Grid.Column width={5} textAlign="right">
                  <div >

                  <p style={{fontSize:"1.0em", fontWeight:"bold"}}>Qty: {orderItem.quantity}</p>
                  </div>
                  </Grid.Column>
                  </Grid.Row>
                  </Grid>
                </Segment>
              );
            })}
          </Item.Group>


        </Segment>
      )}

    </div>
  );
};
