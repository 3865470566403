import { ORDER_CREATE,ORDER_START,ORDER_VALIDATED,ORDER_SUCCESS,ORDER_FETCHED,ORDER_FAIL, ORDER_RESET,ERROR_TIMEOUT} from "./actionTypes";
import axios from "axios";
import { authAxios } from "../../utils";
import { createOrderURL, checkoutURL, orderDetailURL,addPostageURL } from "../../constants";
import { fetchCart, resetCart } from "./cart";

export const buildOrder = data => {

  return {
    type: ORDER_CREATE,
    data
  };

};

export const orderStart = message => {
  return {
    type: ORDER_START,
    loading: true,
    loadingMessage:message
  };
};

export const orderValidated= data => {
  return {
    type: ORDER_VALIDATED,
    loading: false,
    data
  };
};

export const orderSuccess= data => {
  return {
    type: ORDER_SUCCESS,
    loading: false,
    data
  };
};

export const orderFetched= data => {
  return {
    type: ORDER_FETCHED,
    loading: false,
    data
  };
};


export const orderFail = error => {
  return {
    type: ORDER_FAIL,
    error: error
  };
};

export const orderReset = () => {
  return {
    type: ORDER_RESET,

  };
};

export const resetOrder = () => {
  return dispatch => {
    dispatch(orderReset());

  };
};

export const FetchOrder = (orderID) => {

  return dispatch => {
  dispatch(orderStart("Retrieving your order"));
  axios
    .get(orderDetailURL(orderID))
    .then(res => {
      dispatch(orderFetched(res.data));

    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        dispatch(orderFail(error.response.data.message));
        //setTimeout(() => {
          //  dispatch({ type: ERROR_TIMEOUT })
        //  }, 5000)
      } else if (error.request) {
        // The request was made but no response was received
        console.log("ERROR REQUEST");
        dispatch(orderFail(error.message));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }

    });

};
};

export const ValidateOrder = (formData) => {

  return dispatch => {
  dispatch(orderStart("Verifying your address"));
  axios
    .post(createOrderURL, {"order":formData })
    .then(
      res => {

      dispatch(orderValidated(res.data.order_validated));
      dispatch(fetchCart());
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded

        dispatch(orderFail(error.response.data.message));
        setTimeout(() => {
            dispatch({ type: ERROR_TIMEOUT })
          }, 3000)
      } else if (error.request) {
        // The request was made but no response was received

        dispatch(orderFail(error.message));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }

    });
};
};

export const AddPostage = (formData) => {

  return dispatch => {
  dispatch(orderStart("Applying shipping"));
  axios
    .post(addPostageURL, {"order":formData })
    .then(
      res => {

      dispatch(orderValidated(res.data.order_validated));
      dispatch(fetchCart());
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded

        dispatch(orderFail(error.response.data.message));
        setTimeout(() => {
            dispatch({ type: ERROR_TIMEOUT })
          }, 3000)
      } else if (error.request) {
        // The request was made but no response was received

        dispatch(orderFail(error.message));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }

    });
};
};

export const SubmitOrder = (validatedOrder, userID, history) => {

  return dispatch => {

  axios
    .post(checkoutURL, {"order":validatedOrder, "user":userID})
    .then(
      res => {
      dispatch(orderSuccess(res.data));
      //dispatch(fetchCart());
      history.push(`/order/${res.data.redirect_URL}`);
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        //console.log(error.response.data.message);

        dispatch(orderFail(error.response.data));
        //setTimeout(() => {
          //  dispatch({ type: ERROR_TIMEOUT })
        //  }, 5000)
      } else if (error.request) {
        // The request was made but no response was received
        console.log("ERROR REQUEST");
        dispatch(orderFail(error.message));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('ERROR', error.message);
      }

    });
};
};
