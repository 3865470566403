import React, {useState, useEffect} from "react";
import {
  Icon,
} from "semantic-ui-react";
import './QuantitySelector.css'


export const QuantitySelector = (props) => {

  return(
  <div className="button-box">
  <div className="button-box-text">
  <span>
  <a onClick={props.DecreaseItem}>
  <Icon size="small" name="minus" />
  </a>
  </span>

  <span style={{padding: "0px 15px"}}>{props.itemQty}</span>
  <span>
  <a onClick={props.IncrementItem}>
  <Icon size="small" name="plus" />
  </a>
  </span>
</div>
  </div>
)
}
