import React, { Component, useEffect, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import {
  Form,
  Dimmer,
  Loader,
  Accordion,
  Header,
  Segment,
  Button,
  Grid,
  Icon
} from "semantic-ui-react";
import { connect, useSelector, useDispatch } from "react-redux";

import { buildOrder } from "../../store/actions/order";
import {exists, has} from "lodash"

function formatPhoneNumber(phoneNumber) {
  const cleanNum = phoneNumber.toString().replace(/\D/g, '');
  const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return '(' + match[1] + ') ' + (match[2] ? match[2] + "-" : "") + match[3];
  }
  return cleanNum;
}

export function ContactForm (props) {
  const contact= props.order;
  const formError = props.contactError.contact
  const user = props.user

  
  return(
    <React.Fragment>
      {!user && (
      <div style={{padding:'10px 0px'}}>
        <Header sub textAlign="left">
          Already have an account? <NavLink to="/login">Login</NavLink>
        </Header>
      </div>
      )}
      <Form>

      <Form.Field required>

        <Form.Input
          name="email"
          type="email"
          value={contact.email || ''}
          placeholder="Email (for order updates)"
          error={(has(formError, 'email')) ? {
                content: formError.email[0]
            }:false}
          onChange={props.contactChange}
        />
      </Form.Field >

      <Form.Field required>
        <Form.Input
          name="phone"
          value={contact.phone || ''}
          placeholder="Phone (for order questions)"
          error={(has(formError, 'phone')) ? {
                content: formError.phone[0]
            }:false}
          onChange={props.contactChange}
        />
      </Form.Field >
    </Form>
    </React.Fragment>
  )

};
