import axios from "axios";
import * as actionTypes from "./actionTypes";
import { fetchCart, resetCart } from "./cart";
import { loginAPI, registrationAPI, } from "../../constants";
import { fetchUser, fetchUserTest } from "./user";
import { fetchAddresses, resetAddresses } from "./address";
import { fetchPayments, resetPayments } from "./payment";


export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = token => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  return dispatch => {
    dispatch(
      {type: actionTypes.AUTH_LOGOUT});
    //dispatch(resetCart());
    dispatch(resetPayments());
    dispatch(resetAddresses());
  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const authLogin = (email, password, history) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post(loginAPI, {
        email: email,
        password: password
      })
      .then(res => {
        const token = res.data.key;
        const expirationDate = new Date(new Date().getTime() + 43200 * 1000);
        localStorage.setItem("token", token);
        localStorage.setItem("expirationDate", expirationDate);
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout(43200));
        dispatch(fetchUserTest(token));
        //dispatch(fetchCart());
        dispatch(fetchPayments(token));
        dispatch(fetchAddresses("S", token));
        localStorage.getItem("token");
        history.goBack();

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data.non_field_errors);
          dispatch(authFail(error.response.data.non_field_errors));
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          dispatch(authFail(error.message));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }

      });
  };
};

export const authSignup = (email, first_name, last_name, password1, password2) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post(registrationAPI, {
        email: email,
        password1: password1,
        password2: password2,
        first_name: first_name,
        last_name: last_name,
      })
      .then(res => {
        const token = res.data.key;
        const expirationDate = new Date(new Date().getTime() + 43200 * 1000);
        localStorage.setItem("token", token);
        localStorage.setItem("expirationDate", expirationDate);

        dispatch(fetchUserTest());
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout(43200));

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response);
          dispatch(authFail(error.response.data))
          //setTimeout(() => {
          //    dispatch({ type: actionTypes.AUTH_TIMEOUT })
          //  }, 3000);
        } else if (error.request) {
          // The request was made but no response was received
          dispatch(authFail(error.message));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }

      });
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem("token");
    if (token === undefined) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        
        dispatch(fetchUserTest(token));
        dispatch(fetchPayments(token));
        dispatch(fetchAddresses("S", token));
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};
