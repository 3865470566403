import React, { useState, useEffect} from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { Redirect } from "react-router-dom";

import {
  Divider,
  Grid,
  Header,
  Menu,
  Icon,
  Button,
  Card,
  Image,
  Modal
} from "semantic-ui-react";

import {Media} from "../../App";
import {ErrorMessage} from '../../Components/ErrorMessage/ErrorMessage'
import { logout } from "../../store/actions/auth";
import { updateAddress,createAddress } from "../../store/actions/address";
import { updatePayment,createPayment } from "../../store/actions/payment";
import AddressCard from "../../Components/AddressCard/AddressCard";
import PaymentCard from "../../Components/PaymentCard/PaymentCard";
import {AddressForm} from "../../Components/AddressForm/AddressForm";
import {StripeForm} from "../../Components/StripeForm/StripeForm";

import OrderHistory from "../../Components/OrderHistory/OrderHistory";
import {isEmpty, has} from "lodash"
import {endpoint} from "../../constants";
import { useSpring, animated } from 'react-spring'

const profileCategories = [
  {value:'profile', name:'Profile', icon:'user outline'},
  {value:'orderHistory', name:'Order History', icon:'list alternate outline'},
  {value:'addressBook', name:'My Address Book', icon:'address book outline'},
  {value:'wallet', name:'My Wallet', icon:'credit card outline'},
  {value:'favorites', name:'My Favorites', icon:'heart outline'}
]




const findIcon = (activeItem) => {
  return profileCategories.filter((e) => e.value === activeItem)
}

const sectionName = (activeItem) => {

  if (activeItem === 'addressBook') {
    return "Address Book"
  }
  if (activeItem === 'wallet') {
    return "Wallet"
  }
  if (activeItem === 'favorites') {
    return "Favorites"
  }
  if (activeItem === 'orderHistory') {
    return "Order History"
  }
}

const AvatarModal = (props) => {

  return (
    <Modal
       dimmer={true}
       open={props.openModal}

     >
       <Modal.Header>Choose an image</Modal.Header>
       <Modal.Content>
        <p>No images yet. Please check back later.</p>
        <a onClick={()=>props.closeModal()}>Cancel</a>
       </Modal.Content>

     </Modal>
  )
}
const ProfileDetails = (props) => {
  const userData = useSelector((state) => state.user.userData);
  const [toggleModal, settoggleModal] = useState(false);

  

  return (
    <Grid>
    <Grid.Row columns={2}>
    <AvatarModal openModal={toggleModal} closeModal={()=>settoggleModal(false)}/>
    <Grid.Column>
    <p style={{margin:"1em 0em 0em 0em"}}>name</p>
    <Header as={"h4"} style={{margin:"0em"}}>{userData.full_name}
    </Header>
    <p style={{margin:"1em 0em 0em 0em"}}>email</p>
    <Header as={"h4"} style={{margin:"0em"}}>{userData.email}
    </Header>
    <p style={{margin:"1em 0em 0em 0em"}}>member since</p>
    <Header as={"h4"} style={{margin:"0em"}}>{new Date(userData.date_joined).toLocaleDateString('en-us',{ year:"numeric", month:"short", day:"numeric"})}
    </Header>
    </Grid.Column>
    <Grid.Column>
    <div style={{textAlign:"center"}}>

    <Image centered  bordered src={`${endpoint}${userData.avatar}`} size='small' circular />
    <a style={{margin:"1em"}} onClick={()=>settoggleModal(true)}>Change profile pic</a>

    </div>

    </Grid.Column>
    </Grid.Row>
    </Grid>
  )

}
const CardList = (props) => {
    const dispatch = useDispatch();
    const userID = useSelector((state) => state.user.userData.userID);
    const addressError = useSelector((state) => state.address.error);
    const listType = props.type
    const cardarray = props.array
    const [editItem, seteditItem] = useState(false);
    const [formData, setformData] = useState({'address_type':'S','user':userID});

    useEffect(() => {

      if ((has(addressError, 'shipping'))){
        seteditItem(true)
      }


    },[addressError]);

    const emptymessage = () => {
      if (listType === 'addresses') {
        return "addresses"
      }
      if (listType === 'wallet') {
        return "cards"
      }
      if (listType === 'favorites') {
        return "favorites"
      }
    }

    const toggleForm = (address) =>{
      const updatedFormdata = {
        ...address,
        'address_type':'S',
        'user':userID
      };
      console.log(updatedFormdata);
      seteditItem(!editItem);
      setformData(updatedFormdata);
    }

    const buildAddress = (name, value) => {
      const updatedFormdata = {
        ...formData,
        [name]: value,
      };
      setformData(updatedFormdata);
    };

    const saveAddress = (e) => {
      e.preventDefault();
      if ('id' in formData){
        dispatch(updateAddress(formData))
      }
      else{
        dispatch(createAddress(formData));
      }

      seteditItem(!editItem);
    }

    const savePayment = (e) => {
      e.preventDefault();
      dispatch(createPayment(formData));
      seteditItem(!editItem);
    }



    return (

      <div>

      {cardarray.length > 0 && !editItem ? (
        <Grid verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column>
            <Card.Group>
              {cardarray.map((object,i) => {
                return (
                  <AddressCard showEdit={true} showDelete={true} address={object} onEdit={()=>toggleForm(object)} key={i}/>
                )}
              )}
              <Card fluid onClick={()=>toggleForm()}>
              <Card.Content>
                <Icon name="plus" />
                <p>Add address</p>
              </Card.Content>
              </Card>
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
        </Grid>
      ):(
        <React.Fragment>
        {editItem ? (
          <React.Fragment>
          {props.type === 'addresses' && (
            <AddressForm address={formData}
            addressError={addressError}
            addressChange={buildAddress}
            showSave={true}
            showCancel={true}
            onCancel={toggleForm}
            onSave={saveAddress}
            showSetPrimary={true}
            />
          )}
          {props.type === 'cards' && (
          <StripeForm showSave={true} showCancel={true} onCancel={toggleForm} onSave={toggleForm}/>
          )}

          </React.Fragment>
          ):(
          <Grid style={{height:"100%"}} verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column>
              <Header as="h5">
                You don't have any {emptymessage()} saved
              </Header>
              <Button basic color={'black'} onClick={()=>toggleForm()}>Add Now</Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>

      )}
      </React.Fragment>
      )}

      </div>

    )
}

const RenderProfile = (props) => {
  const activeItem = props.activeItem
  const addresses = useSelector((state) => state.address.addresses);
  const payments = useSelector((state) => state.payment.payments);

  return (
    <React.Fragment>
      {activeItem === 'profile' && (
      <ProfileDetails/>

      )}
      {activeItem === 'addressBook' && (
      <CardList
      type={"addresses"}
      array = {addresses}
      />
    )}
    {activeItem === 'wallet' && (
      <CardList
      type={"wallet"}
      array = {payments}
      />
    )}
    {activeItem === 'favorites' && (
      <CardList
      type={"favorites"}
      array = {payments}
      />
    )}
    {activeItem === "orderHistory" && (
      <OrderHistory />
    )}
    </React.Fragment>
  )


}



function ProfileMobile (props) {
  const [activeItem, setactiveItem] = useState('orderHistory');

  return(
    <Grid container>
    <Grid.Column style={{padding:"0em 0em"}}>
      <Grid.Row>
          <Header as='h3'>
          Hello, {props.userData.first_name}
          </Header>
      </Grid.Row>
      <Divider style={{margin:"0em 0em"}}/>

      <Grid.Row >

      <Menu text widths={4} >
        <Menu.Item active={activeItem==="profile"} onClick={()=>setactiveItem('profile')}>Profile</Menu.Item>
        <Menu.Item active={activeItem==="orderHistory"} onClick={()=>setactiveItem('orderHistory')}>Order History</Menu.Item>
        <Menu.Item active={activeItem==="addressBook"} onClick={()=>setactiveItem('addressBook')}>Address Book</Menu.Item>
        <Menu.Item active={activeItem==="wallet"} onClick={()=>setactiveItem('wallet')}>Wallet</Menu.Item>
        <Menu.Item active={activeItem==="favorites"} onClick={()=>setactiveItem('favorites')}>Favorites</Menu.Item>
      </Menu>

      </Grid.Row>
      <Grid.Row style={{margin:"2em 0em"}}>

        <Header>
          <Icon name={findIcon(activeItem)[0].icon} />
          {findIcon(activeItem)[0].name}
        </Header>

      </Grid.Row>
      <Grid.Row >

          <div >
            <RenderProfile activeItem={activeItem}/>
          </div>

      </Grid.Row>
      </Grid.Column>
    </Grid>
  )
}

function ProfileDesktop (props) {
  const dispatch = useDispatch();
  const [activeItem, setactiveItem] = useState('profile');

  return(
    <Grid container >
    <Grid.Row >

      <Grid.Column >
          <Header as='h3' style={{margin: "0em 0em"}}>

            <Image  src={`${endpoint}${props.userData.avatar}`} size='small' circular />


        <Header.Content>
           Hello, {props.userData.first_name}
         <Header.Subheader><a onClick={()=>dispatch(logout())} style={{margin: "0.5em 0em"}}>Sign Out</a></Header.Subheader>
       </Header.Content>
        </Header>

      </Grid.Column>
    </Grid.Row>
    <Divider style={{margin: "0em 1em"}}/>
    <Grid.Row style={{margin: "2em 0em"}} columns={2}>
      <Grid.Column width={4}>
      <Grid.Row>
        <Grid.Column >

            <Menu text vertical fluid>
              <Menu.Item active={activeItem==="profile"} onClick={()=>setactiveItem('profile')}>Profile</Menu.Item>
              <Menu.Item active={activeItem==="orderHistory"} onClick={()=>setactiveItem('orderHistory')}>Order History</Menu.Item>
              <Menu.Item active={activeItem==="addressBook"} onClick={()=>setactiveItem('addressBook')}>Address Book</Menu.Item>
              <Menu.Item active={activeItem==="wallet"} onClick={()=>setactiveItem('wallet')}>Wallet</Menu.Item>
              <Menu.Item active={activeItem==="favorites"} onClick={()=>setactiveItem('favorites')}>Favorites</Menu.Item>
            </Menu>

        </Grid.Column>
      </Grid.Row>
      </Grid.Column>
      <Grid.Column width={12}>

        <Grid.Row style={{margin: "1em 0em"}} >
          <Header as="h4">
            <Icon name={findIcon(activeItem)[0].icon} />
            {findIcon(activeItem)[0].name}
          </Header>
        </Grid.Row>
        <Grid.Row style={{margin: "2em 0em"}} >


              <RenderProfile activeItem={activeItem} />

        </Grid.Row>

      </Grid.Column>
    </Grid.Row>
    </Grid>
    )
}

export default function Profile (props) {

  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  const userData = useSelector((state) => state.user.userData);

  const [activeItem, setactiveItem] = useState('addressBook');

  const activeItemCallback = (item) =>{
    setactiveItem(item);
  }

  useEffect(() => {

  })

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <div style={{margin:"3em 0em"}}>
    <Media at="mobile">
      <ProfileMobile activeItemCallback={activeItemCallback} userData={userData}/>
    </Media>
    <Media greaterThan="mobile">
      <ProfileDesktop activeItemCallback={activeItemCallback} userData={userData}/>
    </Media>
    </div>
  );

}
