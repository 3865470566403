import React, {useState, useEffect, useCallback, createRef} from "react";
import { useDispatch, useSelector} from 'react-redux'
import {useStripe,
  useElements,
  CardNumberElement,
  } from '@stripe/react-stripe-js';

import {
  Button,
  Card,
  Grid,
  Breadcrumb,
  Segment,
  Header,
  Divider,
  Container,
  Sticky,
  Ref,
  Rail,
  Icon
} from "semantic-ui-react";
import { Redirect, useHistory } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import CouponForm from "../../Components/CouponForm/CouponForm";
import GiftCardForm from "../../Components/GiftCardForm/GiftCardForm";
import OrderReview from "../../Components/OrderReview/OrderReview";
import OrderPreview from "../../Components/OrderPreview/OrderPreview";

import Payment from "../../Components/Payment/Payment";
import {ApplePay} from "./ApplePay";
import Shipping from "../../Components/Shipping/Shipping";
import Information from "../../Components/Information/Information";

import { ValidateOrder,buildOrder, SubmitOrder, orderStart, orderFail} from "../../store/actions/order";

import {findIndex, some, isEmpty, has} from "lodash"
import {Media} from "../../App";
import './Checkout.css'
import { shipping_types } from "../../constants";

const amount = "2";
const currency = "USD";
const style = {"layout":"vertical", "color":"black"};


const sections = [
  { key: 'cart', content: 'Cart'},
  { key: 'contact', content: 'Information'},
  { key: 'shipping', content: 'Shipping'},
  { key: 'payment', content: 'Payment'},
]



function capitalize(str) {
  if (str.length > 1) {
  return str.
    split(' ').
    map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).
    join(' ');
  }
  else {
    return str
  }
}

function BreadcrumbCheckout (props) {
  const dispatch = useDispatch()

  const [breadcrumbList, setbreadcrumbList] = useState([
      { key: 'cart', content: 'Cart'},
      { key: 'contact', content: 'Information'}
  ]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.activeItem === 'shipping'){
      setbreadcrumbList((breadcrumbList) => [
        { key: 'cart', content: 'Cart'},
        { key: 'contact', content: 'Information'},
        { key: 'shipping', content: 'Shipping'},
      ])
    }
    else if (props.activeItem === 'payment'){
      setbreadcrumbList((breadcrumbList) => [
        { key: 'cart', content: 'Cart'},
        { key: 'contact', content: 'Information'},
        { key: 'shipping', content: 'Shipping'},
        { key: 'payment', content: 'Payment'}
      ])
    }
  },[props.activeItem])

  const handleActive = (section) => {
    console.log(section)
    if (section === "cart") {
      props.history.push("/order-summary")
    }
    else {
      if (section === "contact") {
        const validated={"validated": false}
        dispatch(buildOrder(validated))
      }
      else {
        props.activeItemCallback(section)
      }
    }
  }
  return(
    <div style={{ marginTop: "0em" }}>
      {breadcrumbList.map((section, i) => (
        <React.Fragment key={section.key}>
          <Breadcrumb >
          <Breadcrumb.Section
          style={{ color: "#505050" }}
          active={props.activeItem === section.key||false}
          onClick={()=>{handleActive(section.key)}}
          >
          {section.content}
          </Breadcrumb.Section>
          {i < breadcrumbList.length-1 && (
          <Breadcrumb.Divider icon='right chevron'/>
          )}
          </Breadcrumb>
        </React.Fragment>
     ))}
   </div>
  )
}


const OrderStatus = (props) => {
  const [testList, settestList] = useState([]);
  const order = props.order
  const dispatch = useDispatch()
  const countries = useSelector((state) => state.info.countries )

  function formatName (str) {
    const result = str.charAt(0).toUpperCase() + str.slice(1);
    return result
  }
  function formatAddress (address) {

    if (address.country == "US"){
      return [
        address.first_name + " " + address.last_name,
        address.street_address,
        address.apartment_address,address.city + ", " + address.state + " " + address.zip
      ]

    }
    else{
      return [
        address.first_name + " " + address.last_name,
        address.street_address,
        address.apartment_address,address.city + " " + address.zip,
        countries.find(obj => {return obj.key === address.country;}).text
      ]
    }
  }

  useEffect(() => {
    if (props.activeItem === 'contact'){
      settestList([]);
    }
    else if (props.activeItem === 'shipping'){
      settestList((testList) => [
        {name:'Contact',
        value:'contact',
        info:[order.contact.email,order.contact.phone],
        },
        {name:'Ship to',
        value:'contact',
        info:formatAddress(order.shipping)
        }
      ])
    }
    else if (props.activeItem === 'payment'){
      settestList((testList) => [
        {name:'Contact',
        value:'contact',
        info:[order.contact.email,order.contact.phone]
        },
        {name:'Ship to',
        value:'contact',
        info:formatAddress(order.shipping)
        },
        {name:'Shipping method',
        value:'shipping',
        info:[shipping_types.find(obj => {return obj.key === order.postage.type;}).content]
        }
      ])
    }
  },[props.activeItem, order])

  const editCallback = (section) => {

    if (section === "contact") {
      const validated={"validated": false,
                      "postage": {}}
      dispatch(buildOrder(validated))
    }

    else {
      const postage={"postage": {}}
      dispatch(buildOrder(postage))
      props.activeItemCallback(section)
    }
  }

  return(
    <Segment basic className="order-segment">
    {testList.map((section, i) => (
      <Segment basic key={i}>
          <Grid>

            <Grid.Row columns={2} style={{padding:"0em",marginTop:"0.5em"}}>

              <Grid.Column width={10} style={{padding:"0em"}}>
                <p style={{color:"#929292",marginBottom:"1em"}}>{section.name}</p>

                </Grid.Column>
                <Grid.Column width={6} textAlign="right">
              <p style={{cursor: "pointer",fontSize:"10px"}} onClick={()=>editCallback(section.value)}>Change</p>
              </Grid.Column>
            </Grid.Row>
            <div style={{margin:"0em"}}></div>
            <Grid.Row style={{padding:"0em"}}>
            <div>
            {section.info.map((info, i) => (
              <p style={{margin:"0em"}} key={i}>{info}</p>
            ))
            }
            </div>
            </Grid.Row>

          </Grid>
      </Segment>
    ))}
    </Segment>
  )
}

const RenderOrder = (props) => {
  const activeItem = props.activeItem;
  const dispatch = useDispatch();

  const handleNext = (nextSection) => {
      window.scrollTo(0, 0);
      if (nextSection === 'shipping') {
        dispatch(ValidateOrder(props.order));
      }
      else if (nextSection === 'payment') {
        if (props.order.validated && "type" in props.order.postage){
          props.activeItemCallback("payment")
        }
      }
  }

  const ProceedButton = () => {

    const currentIndex = findIndex(sections, ['key', activeItem]);
    const nextIndex = (currentIndex + 1) % sections.length;
    if (nextIndex > 0) {
      return (
        <Button className="checkout-button" color="black" fluid onClick={()=>handleNext(sections[nextIndex].key)}>
        Proceed to {sections[nextIndex].content}
        </Button>
      )
    }
    else{
      return (
        <React.Fragment>
        {props.order.payment.payment_method === 'P' && (

          <PayPalButtons
               style={style}
               disabled={false}
               forceReRender={[amount, currency, style]}
               fundingSource="paypal"
               createOrder={props.createPaypalOrder}
               onApprove={props.onPaypalApprove}
           />

        )}
        {props.order.payment.payment_method === 'S' && (
          <Button className="checkout-button" color="black" fluid onClick={()=>props.submitCheckout(props.order)}>
          Complete Order
          </Button>
        )}
        {props.order.payment.payment_method === 'A' && (
          <ApplePay userCCY={props.cart.ccy} chargeAmount={props.cart.order_total} onApprove={props.onApplePayApprove} onError={props.onApplePayError}/>
        )}
      </React.Fragment>
      )
    }
  }

  return (
    <React.Fragment>
      <div style={{ margin: "0em 0em" }}>
        {activeItem === 'contact' && props.order && (
          <Information user={props.user} order={props.order} orderError={props.orderError}/>
        )}
        {activeItem === 'shipping' && (
          <Shipping address={props.order.shipping}/>
        )}
        {activeItem === 'payment' && (
          <Payment paymentError={props.paymentError} orderError={props.orderError}/>
        )}
      </div>
      <div style={{ margin: "3em 0em" }}>
        <ProceedButton />
      </div>
    </React.Fragment>
  )
};

export default function Checkout (props) {
  const contextRef = createRef()
  const dispatch = useDispatch()
  const history = useHistory();
  const [activeItem, setactiveItem] = useState('contact');
  const user = useSelector((state) => state.user.userData);
  const order = useSelector((state) => state.order.order);
  const orderError = useSelector((state) => state.order.error);
  const orderLoading = useSelector((state) => state.order.loading);
  const cart = useSelector((state) => state.cart.shoppingCart);

  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentError, setpaymentError] = useState(null);
  const neededKeys = ['contact','shipping'];
  //PROCESS CHECKOUT
  useEffect( () => {
    if(order.validated){
      setactiveItem("shipping")
    }
    else {
      setactiveItem("contact")
    }

  }, [order.validated]
  );

  useEffect( () => {

    if(order.redirect_url){

    }
  }, [order.redirect_url]
  );


  //PROCESS CHECKOUT
  const checkoutTest = async () => {
    window.scrollTo(0, 0);
    dispatch(orderStart("Processing your order"));
    if (cart){
      var token = ''
      if (cart.order_total>0){
        switch (order.payment.payment_method) {
          case "S" :
            if (!stripe || !elements) {
              // Stripe.js has not yet loaded.
              // Make sure to disable form submission until Stripe.js has loaded.
              setpaymentError("Uh oh! We ran into a snag, please try again")
              dispatch(orderFail({"type":"payment","message":"Oops! We ran into a network error","submessage":"Please try again."}));
              return;
            }
            // Use elements.getElement to get a reference to the mounted Element.
            const card = elements.getElement(CardNumberElement);
            const result = await stripe.createToken(card);

            if (result.error) {

               setpaymentError(result.error.message);
               dispatch(orderFail({"type":"payment","message":result.error.message,"submessage":"Please try again."}));

            }
            else {
               token = result.token.id
               const updatedFormdata = {
                 ...order,"payment":
                 {
                 "charge_id": token,
                 "amount": cart.order_total,
                 "payment_method":order.payment.payment_method
                 },
                 "billing":{...order.billing}
               };

               dispatch(SubmitOrder(updatedFormdata, user.userID, history))
            }
            break;
          case "P" :

            token = "test";
            break;
          default :

            token = "";
            break;
        }

      }
      else{
        const updatedFormdata = {
          ...order,"payment":
          {
          "charge_id": "",
          "amount": cart.order_total,
          "payment_method":"C"
          },
          "billing":{...order.billing}
        };
        dispatch(SubmitOrder(updatedFormdata, user.userID, history))
      }
    }
    else{
      setpaymentError("No items in your cart!");
      dispatch(orderFail({"type":"cart","message":"No items in your cart!"}));
    }



  };

  const createPaypalOrder = useCallback((data, actions) => {
    if (cart) {

      if ('payment' in order) {
        const cartTotal = cart.order_total;
        setIsLoading(false);

        return actions.order
          .create({
            purchase_units: [
              {
                amount: {
                  currency_code: cart.ccy.toUpperCase(),
                  value: cart.order_total,
                },
              },
            ],
            application_context: {
                          shipping_preference: "NO_SHIPPING"
                      }
          });
        }
      }
      else{
        setpaymentError("No items in your cart!");
        dispatch(orderFail({"type":"cart","message":"No items in your cart!"}));
      }



  },[order])

  function onPaypalApprove(data, actions) {
    var token = ''

    return actions.order.authorize().then(authorization => {
      if (authorization.error) {
        console.log(authorization.error)
        setpaymentError({"message":"Paypal Error"});
        dispatch(orderFail({"type":"payment","message":"Oh no! Something wen't wrong with your Paypal payment","submessage":"You were not charged please try again."}));
        setIsLoading(false);
      } else {
        console.log("Paypal approved")

        token = authorization.purchase_units[0].payments.authorizations[0].id
        const updatedFormdata = {
         ...order,"payment":
          {
          "charge_id": token,
          "amount": cart.order_total,
          "payment_method":order.payment.payment_method
          },
          "billing":{...order.shipping,
            "address_type":"B"
          }
        };

        dispatch(orderStart("Processing your order"));
        dispatch(SubmitOrder(updatedFormdata, user.userID, history))
        setIsLoading(false);
      }
        });
  }

  function onApplePayApprove (paymentIntent) {

    const token = paymentIntent.id
    const updatedFormdata = {
     ...order,"payment":
      {
      "charge_id": token,
      "amount": cart.order_total,
      "payment_method":order.payment.payment_method
      },
      "billing":{...order.shipping,
        "address_type":"B"
      }
    };

    dispatch(orderStart("Processing your order"));
    dispatch(SubmitOrder(updatedFormdata, user.userID, history))
    setIsLoading(false);

  }

  function onApplePayError (error) {

    dispatch(orderFail({"type":"payment","message":"Oops! We ran into a network error","submessage":"Please try again."}));

  }

  const activeItemCallback = (section) => {
    window.scrollTo(0, 0);
    setactiveItem(section);

  };

  return (
    <div className="order-container">
    {cart ? (
      <PayPalScriptProvider options={{
          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
          "currency": cart.ccy.toString().toUpperCase(),
          "intent": "authorize",
          "enable-funding":"venmo",
          components: "buttons,marks,funding-eligibility" }}>
      <Media at="mobile">
        <OrderReview cart={cart}/>
        <Grid container stackable style={{margin:"0em",padding:"0em 0em"}}>
        <Grid.Column >
        <Grid.Row>

        </Grid.Row>
          <Grid.Row >
            <Grid.Column width={10} >
            <BreadcrumbCheckout {...props} activeItem={activeItem} activeItemCallback={activeItemCallback}/>
            </Grid.Column>
          </Grid.Row >
          <Grid.Row >
            <Grid.Column width={10} style={{ margin: "2em 0em" }}>
            {activeItem !== 'contact' && (
              <OrderStatus activeItem={activeItem} activeItemCallback={activeItemCallback} order={order}/>
            )}
            <div style={{ margin: "2em 0em" }}>
            {order && (
              <RenderOrder
              activeItem={activeItem}
              order={order}
              cart={cart}
              user={user}
              orderError={orderError}
              paymentError={paymentError}
              activeItemCallback={activeItemCallback}
              submitCheckout={checkoutTest}
              createPaypalOrder={createPaypalOrder}
              onPaypalApprove={onPaypalApprove}
              onApplePayApprove={onApplePayApprove}
              onApplePayError={onApplePayError}
              />
            )}
              </div>
            </Grid.Column>
          </Grid.Row >
          </Grid.Column>
        </Grid >
      </Media>
      <Media greaterThan="mobile" style={{margin:"0em 0em"}}>
        <Grid container stackable >
          <Grid.Row >
            <Grid.Column>
            <BreadcrumbCheckout {...props} activeItem={activeItem} activeItemCallback={activeItemCallback}/>
            </Grid.Column>
          </Grid.Row >

          <Grid.Row >

            <Grid.Column width={10}>
            <Grid.Row >
              <Grid.Column >
              {activeItem !== 'contact' && (
                <OrderStatus activeItem={activeItem} activeItemCallback={activeItemCallback} order={order}/>
              )}
              {order.contact && (
              <RenderOrder
              activeItem={activeItem}
              order={order}
              user={user}
              cart={cart}
              orderError={orderError}
              paymentError={paymentError}
              activeItemCallback={activeItemCallback}
              submitCheckout={checkoutTest}
              createPaypalOrder={createPaypalOrder}
              onPaypalApprove={onPaypalApprove}
              onApplePayApprove={onApplePayApprove}
              onApplePayError={onApplePayError}
              />
            )}
              </Grid.Column>
            </Grid.Row >
            </Grid.Column>

            <Grid.Column width={6}>
              <Grid.Row >
                <Grid.Column>
                  <OrderReview cart={cart}/>
                  <GiftCardForm />
                  <CouponForm />

                  <OrderPreview cart={cart}/>
                </Grid.Column>
              </Grid.Row >
            </Grid.Column>

          </Grid.Row>

        </Grid >



      </Media>

      </PayPalScriptProvider>
  ):(
    <Grid container textAlign='center' style={{margin:"3em 0em"}}>
      <Grid.Row centered columns={1}>
        <Grid.Column textAlign='center'>

          <Header as='h2' className="cart-empty-header">
          <Icon name='frown outline' />
          <Header.Content>Your shopping bag is empty!</Header.Content>
          </Header>

        </Grid.Column>
      </Grid.Row>
    </Grid>
  )}
  </div>
  );

}
