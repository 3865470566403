import React, { useState, useEffect  } from "react";

import { PayPalButtons,

  usePayPalScriptReducer
} from "@paypal/react-paypal-js";

import {isEmpty} from "lodash"
import { fetchPayments} from "../../store/actions/payment";
import { fetchCart } from "../../store/actions/cart";
import { buildOrder } from "../../store/actions/order";
import {
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const initialValues = {
  fullname: "",
  email: "",
  address:""
};

// This values are the props in the UI
const amount = "2";
const currency = "USD";
const style = {"layout":"vertical"};

export const PayPalCheckout = ({ currency, showSpinner }) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner,options, dispatch]);


    return (<>
            { (showSpinner && isPending) && <div className="spinner" /> }
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[amount, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: amount,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            return orderId;
                        });
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function () {
                        // Your code here after capture the order
                    });
                }}
            />
        </>
    );
}

const Payment = (props) => {
  const [state, setState] = useState({
     paypalError: null,
     loading: false,

  });
  const [{ isPending }] = usePayPalScriptReducer();

  function createPaypalOrder(data, actions) {
    if (props.cart['order_total'] !== 0){
      return actions.order.create({
        intent: "AUTHORIZE",
        purchase_units: [
          {
            amount: {
              currency_code: "USD",
              value: props.cart['order_total'],
            },

          },
        ],
      });
    }
    else {
      console.log(props.cart['order_total'])
    }

  }

  function paymentCallback(payment) {

  }

  function onPaypalApprove(data, actions) {
    return actions.order.authorize().then(authorization => {
      if (authorization.error) {
        setState({...state,
          paypalError: authorization.error.message,
          loading: false  });

      } else {
        console.log(authorization);

        const order = {
          paymentToken: authorization.purchase_units[0].payments.authorizations[0].id,
          email: authorization.payer.email_address,
          address: authorization.purchase_units[0].shipping.address,
          name_shipping: authorization.purchase_units[0].shipping.name.full_name,
          name_first: authorization.payer.name.given_name,
          name_last: authorization.payer.name.surname,
          paymentMethod: "P",
        };
        console.log(order);
        props.tempOrder(order);

      }
        });
  }

  return(

    <Segment className="checkout-segment">

        {!isPending && (
              <PayPalButtons
              style={{ color: "black", shape: "rect", label: "pay", height: 40 }}
              fundingSource={'paypal'}
              commit={false}
              createOrder={(data, actions) => createPaypalOrder(data, actions)}
              onApprove={(data, actions) => onPaypalApprove(data, actions)}/>
            )}

    </Segment>

  )
}

const mapStateToProps = state => {

  return {
    authenticated: state.auth.token !== null,
    cart: state.cart.shoppingCart,
    userID: state.user.userID,
    addresses: state.address.addresses,
    payments: state.payment.payments,
    defaultShippingAddress: state.address.defaultShippingAddress,
    defaultPayment: state.payment.defaultPayment,
    paymentLoading: state.payment.loading,
  };
};

const mapDispatchToProps = dispatch => {

  return {
    tempOrder: (data) => dispatch(buildOrder(data)),
    fetchPayments: () => dispatch(fetchPayments()),
    fetchCart: () => dispatch(fetchCart()),
    };
};


export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Payment)
;
