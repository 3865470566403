import { CART_START, CART_SUCCESS, CART_FAIL, CART_RESET, CART_NULL,ERROR_TIMEOUT } from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  shoppingCart: null,
  error: null,
  loading: false,
};

const cartStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const cartSuccess = (state, action) => {
  return updateObject(state, {
    shoppingCart: action.data,
    error: null,
    loading: false
  });
};

const cartFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    
  });
};

const cartNull = (state, action) => {
  return updateObject(state, {

    loading: false,

  });
};

const cartReset = (state, action) => {
  return updateObject(state, {
    shoppingCart: null,

  });
};

const errorTimeout = (state, action) => {
  return updateObject(state, {
    error: false,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_START:
      return cartStart(state, action);
    case CART_SUCCESS:
      return cartSuccess(state, action);
    case CART_FAIL:
      return cartFail(state, action);
    case CART_NULL:
      return cartNull(state, action);
    case CART_RESET:
      return cartReset(state, action);
    case ERROR_TIMEOUT:
      return errorTimeout(state, action);
    default:
      return state;
  }
};

export default reducer;
