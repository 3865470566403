import React from "react";

import {
  Table,
  Header,
  Grid,
  Image,
  Segment,
  Icon
} from "semantic-ui-react";
import {
  endpoint,orderHistoryURL
} from "../../constants";
import { authAxios } from "../../utils";
import axios from "axios";
import AddressCard from "../AddressCard/AddressCard";

export const OrderDetail = (props) => {
  const order = props.order;
  console.log(order);
  window.scrollTo(0, 0);
  return (

    <Grid>
    <Grid.Row>
    <Grid.Column textAlign="left">
          <p style={{margin:"1em 0em 0em 0em"}}>order #</p>
          <Header style={{margin:"0em"}} as='h2'>{order.order_id}</Header>
          </Grid.Column>
          </Grid.Row>
          <Grid.Row>
          <Grid.Column textAlign="left">
          <p style={{margin:"1em 0em 0em 0em"}} >Shipped to</p>
          {order && (

            <Header style={{margin:"0em"}}>
            {order.shipping_address.first_name} {order.shipping_address.last_name}<br/>
            {order.shipping_address.street_address}<br/>
            {order.shipping_address.city}, {order.shipping_address.state} {order.shipping_address.zip}<br/>
            </Header>

          )}
          </Grid.Column>
          </Grid.Row>
          <Grid.Row>
          <Grid.Column textAlign="left">
          <p>Items</p>
          <Table basic='very' unstackable>
            <Table.Header >
              <Table.Row>
                <Table.HeaderCell>Item</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {order.order_items.map((item,i) => {
                return (

                  <Table.Row verticalAlign='top' key={i}>

                    <Table.Cell verticalAlign='top' width={6}>
                    <Image onClick={()=>props.history.push(`/products/${item.item.slug}`)}size='tiny' verticalAlign='middle' src={`${endpoint}${item.item.img[0].img_thumb}`} />
                      <p>{item.item.title}</p>
                    </Table.Cell>
                    <Table.Cell width={4}>{item.quantity}</Table.Cell>
                    <Table.Cell width={6}>${item.final_price}</Table.Cell>
                  </Table.Row>

                );
              })}
            </Table.Body>

          </Table>
          </Grid.Column>
          </Grid.Row>
    </Grid>

  );

}


class OrderHistory extends React.Component {
  state = {
    orders: [],
    selectedOrder:{}
  };

  componentDidMount() {
    this.handleFetchOrderHistory();
    window.scrollTo(0, 0);
  }

  handleFetchOrderHistory = () => {
    this.setState({ loading: true });
    const token = localStorage.getItem("token");
    axios
        .create({
        baseURL: endpoint,
        headers: {
          Authorization: `Token ${token}`
        }
        })
      .get(orderHistoryURL)
      .then(res => {
        this.setState({
          loading: false,
          orders: res.data,

        });

      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });

  };

  handleOrderDetail = (order) => {
    this.setState({ selectedOrder: order });
  }

  render() {
    const { orders, selectedOrder } = this.state;
    return (
      <React.Fragment>
      {Object.entries(selectedOrder).length !== 0 ? (
        <OrderDetail order={selectedOrder}/>
      ):(

        <Grid.Row>

        <Table basic='very' unstackable columns={3}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Order ID</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {orders.map((p,i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell width={6}><p onClick={()=>this.handleOrderDetail(p)}>{p.order_id}</p></Table.Cell>
                  <Table.Cell width={4}>${p.payment.amount}</Table.Cell>
                  <Table.Cell width={6}>{new Date(p.payment.timestamp).toLocaleDateString()}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        </Grid.Row>

    )}
    </React.Fragment>
    );
  }
}

export default OrderHistory;
