import React, { useState } from "react";
import './StripeForm.css'

import {useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  CardElement} from '@stripe/react-stripe-js';

import {
  Form,
  Message,
} from "semantic-ui-react";

const ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: 400,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87BBFD',
      },
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
  },
};

export const StripeForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const payment= props.payment;
  const [isLoading, setIsLoading] = useState(false);
  const [stripeError, setStripeError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    // Use elements.getElement to get a reference to the mounted Element.

    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);

    if (result.error) {
       // Show error to your customer.
       setStripeError(result.error.message);
       console.log(result.error);
     } else {
       // Send the token to your server.
       // This function does not exist yet; we will define it in the next step.

       console.log(result.token);
     }
     props.stripeCallback(result);
  };

  return (
    <Form >
      {stripeError && (
        <Message>
        {stripeError}
        </Message>
      )}
      <Form.Field>
         <label htmlFor="cardNumber">Card number</label>
         <CardNumberElement id="cardNumber"
         />
      </Form.Field>
      <Form.Group widths='equal'>
      <Form.Field  width={6}>
         <label>Expiry</label>
         <CardExpiryElement />
      </Form.Field>
      <Form.Field  width={6}>
         <label>Security code</label>
         <CardCvcElement />
      </Form.Field>
      </Form.Group>
      {props.showSetPrimary && (
      <Form.Checkbox
        value={payment.default || ''}
        name="default"
        label="Set as primary address"
        onChange={props.paymentChange('default',!payment.default)}

      />
      )}
      <span>
      {props.showSave && (
        <Form.Button onClick={props.onSave} disabled={props.saving} loading={props.saving} >
        Save
        </Form.Button>
      )}
      {props.showCancel && (
        <p onClick={props.onCancel}>Cancel</p>
      )}
      </span>
    </Form>
  );
};
