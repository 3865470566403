import { PRODUCT_START, REVIEW_START, PRODUCT_SUCCESS, PRODUCT_DETAIL_SUCCESS, PRODUCT_FAIL, REVIEW_FAIL } from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  products: [],
  item: {},
  error: null,
  errorReview: null,
  loading: false,
  btnDisable: false,
};

const productStart = (state, action) => {
  return updateObject(state, {
    error: null,
    errorReview: null,
    item: {},
    loading: true
  });
};

const reviewStart = (state, action) => {
  return updateObject(state, {
    errorReview: null,
    loading: true
  });
};

const productListSuccess = (state, action) => {
  return updateObject(state, {
    products: action.data,
    error: null,
    errorReview: null,
    loading: false
  });
};

const productDetailSuccess = (state, action) => {
  return updateObject(state, {
    item: action.data,
    error: null,
    errorReview: null,
    loading: false
  });
};

const productFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const reviewFail = (state, action) => {
  return updateObject(state, {
    errorReview: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_START:
      return productStart(state, action);
    case REVIEW_START:
      return reviewStart(state, action);
    case PRODUCT_SUCCESS:
      return productListSuccess(state, action);
    case PRODUCT_DETAIL_SUCCESS:
      return productDetailSuccess(state, action);
    case PRODUCT_FAIL:
      return productFail(state, action);
    case REVIEW_FAIL:
      return reviewFail(state, action);
    default:
      return state;
  }
};

export default reducer;
