import React from "react";
import {
  Header,
  Image,
  Button,
  Grid,
  Divider,
  Icon,
  Segment
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { endpoint} from '../../constants'
import './OrderSummary.css'
import { fetchCart,AddToCart,RemoveQuantityFromCart, RemoveItem} from "../../store/actions/cart";
import {Media} from "../../App";
import { useTrail, a } from '@react-spring/web'
import OrderReview from "../../Components/OrderReview/OrderReview";
import { getThumbnail, ccySymbol } from "../../utils";
import {isEmpty, has} from "lodash"

const renderVariations = orderItem => {
  let text = "";
  orderItem.item_variations.forEach(iv => {
    text += `${iv.name}: ${iv.value}`;
  });
  return text;
};

const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
  const items = React.Children.toArray(children)
  const trail = useTrail(items.length, {
    config: { mass: 10, tension: 1000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? 150 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  })
  return (
    <div className="trail-item">
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} style={{margin:"0em 0em 4em 0em", display: "block"}}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  )
}



function AddRemove ({orderItem,props}) {

  return(
    <div className="button-box">
    <div className="button-box-text">
      <a onClick={() =>
        props.handleRemoveQuantityFromCart(
          orderItem.id
        )}>
        <Icon size="small" name="minus" />
        </a>
      <span style={{padding: "0px 10px"}}>{orderItem.quantity}</span>
      <a onClick={() =>
        props.handleAddToCart(
          orderItem.item.slug,
          orderItem.item_variations
        )}
      >
      <Icon size="small" name="plus" />
      </a>
      </div>
      </div>
    )
}



function SummaryDesktop (props) {

  return(
    <Grid container stackable style={{margin:"0em 0em"}}>
    <Grid.Row style={{height: "8em"}}>
      <Grid.Column verticalAlign="middle" textAlign="center" >
        <Header className="product-header">
        My Shopping Bag
        </Header>
      </Grid.Column>
    </Grid.Row>
      <Divider clearing style={{margin:"0em 1em"}}/>
      {props.cart ? (
      <Grid.Row columns={2}>
      <Grid.Column width={10}>
        <Grid.Row >
        <Grid.Column style={{padding: "0em"}}>
        {(has(props.cart, 'order_items')) && (
          <Trail open={true}>
          {props.cart.order_items.map((orderItem, i) => {

                return (
                  <Segment basic key={i} className="item-segment">
                  <Media at="mobile">
                  <Grid>
                  <Grid.Row columns={3}>
                    <Grid.Column width={4}>
                    <Image size='small' src={getThumbnail(orderItem)} />
                    </Grid.Column>
                    <Grid.Column width={9}>
                      <div>
                      <Header as="h4" >{orderItem.item.title}</Header>
                      <div>
                      {orderItem.item_variations.length >0 && (
                        <React.Fragment>
                        {orderItem.item_variations.map(iv => {
                          return (
                            <p key={iv.id} style={{margin:"0.5em 0em"}}>{iv.name}: {iv.value}<br/></p>
                          );
                        })}
                        </React.Fragment>
                      )}
                      </div>
                      </div>
                      <div style={{height:"3em", width:"50%",marginTop:"0.5em"}}>
                      {orderItem.item.slug != 'gift-card' ? (
                      <AddRemove props={props} orderItem={orderItem} />
                      ):(
                        <p>Recipient: {orderItem.gift_card_recipient}</p>
                      )}
                      <p
                        style={{cursor: "pointer",marginTop:"0.5em"}}
                        onClick={() => props.handleRemoveItem(orderItem.id)}
                      >
                      Remove
                      </p>
                      </div>

                    </Grid.Column>

                    <Grid.Column width={3} textAlign="center">
                      <div style={{verticalAlign:"middle"}}>
                      <p style={{fontSize:"1.1em", fontWeight:"bold"}}>{ccySymbol[orderItem.ccy]}{orderItem.quantity*orderItem.price}</p>

                      </div>
                    </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  </Media>
                  <Media greaterThan="mobile">
                    <Grid>
                    <Grid.Row columns={4}>
                      <Grid.Column width={3}>
                      <Image size='small' src={getThumbnail(orderItem)} />
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <div>
                        <Header as="h4" >{orderItem.item.title}</Header>
                        <div>
                        {orderItem.item_variations.length >0 && (
                          <React.Fragment>
                          {orderItem.item_variations.map(iv => {
                            return (
                              <p key={iv.id} style={{margin:"0.5em 0em"}}>{iv.name}: {iv.value}<br/></p>
                            );
                          })}
                          </React.Fragment>
                        )}
                        </div>
                        <p>{ccySymbol[orderItem.ccy]}{orderItem.price}</p>
                        </div>


                      </Grid.Column>
                      <Grid.Column width={4}>
                        <div style={{height:"3em"}}>
                        <p>Qty</p>
                        {orderItem.item.slug != 'gift-card' ? (
                        <AddRemove props={props} orderItem={orderItem} />
                        ):(
                          <p>Recipient: {orderItem.gift_card_recipient}</p>
                        )}
                        <p
                          style={{margin:"0.5em 0em",cursor: "pointer",}}
                          onClick={() => props.handleRemoveItem(orderItem.id)}
                        >
                        Remove
                        </p>
                        </div>
                      </Grid.Column>
                      <Grid.Column width={3} textAlign="center">
                        <div style={{verticalAlign:"middle"}}>
                        <p style={{fontSize:"1.1em", fontWeight:"bold"}}>{ccySymbol[orderItem.ccy]}{orderItem.quantity*orderItem.price}</p>

                        </div>
                      </Grid.Column>
                      </Grid.Row>
                    </Grid>
                      </Media>
                  </Segment>
                )
          })}
          </Trail>
        )}
      </Grid.Column>
      </Grid.Row>


      </Grid.Column>
      <Grid.Column width={6}>
      <Grid.Row style={{margin:"2em 0em"}}>
      <OrderReview />
      </Grid.Row>
      <Grid.Row style={{margin:"2em 0em"}}>
        {props.props.cart && (
          <Link to="/checkout">
          <Button className="checkout-button" fluid color="black" >
          Checkout
          </Button>
          </Link>
        )}
      </Grid.Row>
      </Grid.Column>
      </Grid.Row>
      ):(
        <Grid.Row>
        <Grid.Column textAlign="center">
        <div style={{margin:"2em 0em",textAlign:"center", fontSize:"14px",overflowWrap: "break-word"}}>
        <p>Oh no! You don't have any items in your bag</p>
        </div>
        </Grid.Column>
        </Grid.Row>
      )}


      </Grid>
  )
}

class OrderSummary extends React.Component {
  state = {

    error: null,
    loading: false
  };

  componentDidMount() {

    window.scrollTo(0, 0);

  }


  renderVariations = orderItem => {
    let text = "";
    orderItem.item_variations.forEach(iv => {
      text += `${iv.variation.name}: ${iv.value}, `;
    });
    return text;
  };

  handleFormatData = itemVariations => {
    // convert [{id: 1},{id: 2}] to [1,2] - they're all variations
    return Object.keys(itemVariations).map(key => {
      return itemVariations[key].id;
    });
  };

  handleAddToCart = (slug, itemVariations) => {
    const variations = this.handleFormatData(itemVariations);
    this.props.addToCart(slug, variations);
  };

  handleRemoveQuantityFromCart = (id) => {
    //const variations = this.handleFormatData(itemVariations);
    this.props.removeFromCart(id);
  };

  handleRemoveItem = id => {
    this.props.removeItem(id);

  };

  render() {

    const { cart, error, cartLoading } = this.props;

    return (
      <div >

        <SummaryDesktop props = {this.props}
        cart={this.props.cart}
        filterValue={this.filterValue}
        handleAddToCart={this.handleAddToCart}
        handleRemoveQuantityFromCart={this.handleRemoveQuantityFromCart}
        handleRemoveItem={this.handleRemoveItem}/>

      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    cart: state.cart.shoppingCart,
    cartLoading: state.cart.loading,
    error: state.cart.error,
    order: state.order.order,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCart: () => dispatch(fetchCart()),
    addToCart: (slug, variations) => dispatch(AddToCart(slug, variations)),
    removeFromCart: (slug, variations) => dispatch(RemoveQuantityFromCart(slug, variations)),
    removeItem: (slug) => dispatch(RemoveItem(slug)),
  };
};

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrderSummary);
