import React, {useState, useEffect} from "react";
import { useDispatch, useSelector} from 'react-redux'

import { authAxios, ccySymbol } from "../../utils";
import {
  Container,
  Divider,
  Grid,
  Header,
  Item,
  Message,
  Segment,
  Icon,
  Image
} from "semantic-ui-react";

import axios from "axios";
import { orderDetailURL, baseServer, shipping_types } from "../../constants";
import { FetchOrder, resetOrder } from "../../store/actions/order";
import { fetchCart,resetCart } from "../../store/actions/cart";
import AddressCard from "../../Components/AddressCard/AddressCard";
import PaymentCard from "../../Components/PaymentCard/PaymentCard";

import { animated, useSpring} from 'react-spring'



function handleCCicon (brand) {
  return "cc " + brand.toLowerCase()
};

function getThumbnail(orderItem) {
  if (orderItem.item.img[0]) {
    return `${baseServer}${orderItem.item.img[0].img_thumb}`
  }
  else {
    return "https://api.coellalove.com/media/image.png"
  }
};

const getShipping = (type) => {
    let text = "";
    shipping_types.forEach(i => {
      if (i.key === type) {

        text += `${i.content}`;
      }
    });
    return text;
};

const renderVariations = orderItem => {
  let text = "";
  orderItem.item_variations.forEach(iv => {
    text += `${iv.variation.name}: ${iv.value}`;
  });
  return text;
};

const getTrackingLink = (trackingID) => {
  let baseurl="https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={trackingID}"
  return baseurl
}



export function OrderCompleteNew (props) {
  const dispatch = useDispatch();
  const {match: { params }} = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [completedOrder, setcompletedOrder] = useState(null);

  const { x } = useSpring({
    from: { x: 0 },
    x: completedOrder ? 1:0,
    config: { duration: 1700 } });

  const handleFetchOrder = (orderID) => {
    setLoading(true);

    axios
      .get(orderDetailURL(orderID))
      .then(res => {

        setLoading(false);
        setcompletedOrder(res.data);

      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });

  };

  useEffect(() => {
    handleFetchOrder(params.orderID);

    dispatch(resetCart());
    dispatch(resetOrder());
  },[params.orderID]);

  useEffect(() => {
    console.log(completedOrder)
  },[completedOrder]);

  const RenderPaymentType = () => {
    if (completedOrder.payment.payment_method == "S") {
      return(
        <div>
        <Icon name={handleCCicon(completedOrder.payment.brand)} size='big'/>
        **** **** **** {completedOrder.payment.last_4}<br/>
        </div>
        )
    }
    else if (completedOrder.payment.payment_method == "P") {
      return(
        <div>
        <Icon name='cc paypal' size='big'/>
        Paypal<br/>
        </div>
      )
    }
    else if (completedOrder.payment.payment_method == "A") {
      return(
        <div>
        <Icon name='apple pay' size='big'/>
        Apple Pay<br/>
        </div>
      )
    }
    else {
      return(
        <div>
        <p>No payment required</p>
        </div>
      )
    }

  }

  return (
      <div className="order-container">
      {error ? (
        <Segment basic>
        <Container>
          <Header>

          Oops! This order does not exist
          </Header>
          </Container>
        </Segment>
      ):(

      <Grid container stackable style={{padding:"2em 0em"}}>
      <Grid.Row columns={2} style={{margin:"0em 0em"}}>

        <Grid.Column>
        <Grid.Row style={{margin:"0em 0em 1em 0em"}}>
        <Segment basic className="order-segment">
        <Header>
        Thank you for your order!
        </Header>
        <Divider clearing />
        <p>Your order id is <span ><b>{params.orderID}</b></span></p>
        <Header as="h5">
        Order Summary
        </Header>
        {completedOrder && (

          <Grid >

          <Grid.Row columns={2}>
            <Grid.Column textAlign='left'>
            <p>Your items </p>
            {completedOrder.applied_discount > 0 && (
            <p>Discount Applied</p>
            )}
            {completedOrder.giftcard > 0 && (
            <p>Gift Card Applied</p>
            )}
            <p>Shipping</p>

            </Grid.Column>
            <Grid.Column textAlign='right'>
            <p>{ccySymbol[completedOrder.order_ccy]}{completedOrder.subtotal.toFixed(2)}</p>
            {completedOrder.applied_discount > 0 && (
            <p>-{ccySymbol[completedOrder.order_ccy]}{completedOrder.applied_discount.toFixed(2)}</p>
            )}
            {completedOrder.shipping_applied == 0 ? (
                <p>FREE</p>
              ):(
                <p>completedOrder.shipping_applied</p>
              )
            }



            </Grid.Column>
          </Grid.Row>
          <Divider clearing style={{margin:"0em 1em"}}/>
          <Grid.Row columns={2}>
            <Grid.Column textAlign='left'>

            <p><b>Total</b></p>
            </Grid.Column>
            <Grid.Column textAlign='right'>
            <p><b>{ccySymbol[completedOrder.order_ccy]}{completedOrder.total.toFixed(2)}</b></p>
            </Grid.Column>
          </Grid.Row>
          </Grid>

      )}
        </Segment>
        </Grid.Row>
        <Grid.Row style={{margin:"2em 0em 0em 0em"}}>
          <Segment basic className="order-segment">
            <Header as="h3">
            Order Items
            </Header>
            {completedOrder && (
                <React.Fragment>
                  {completedOrder.order_items.map((orderItem, i) => {
                    return (
                      <Segment basic key={i}>
                      <Grid>
                      <Grid.Row columns={3}>
                      <Grid.Column width={4} style={{padding:"0em"}}>
                      <Image size='small' src={getThumbnail(orderItem)} />
                      </Grid.Column>
                      <Grid.Column width={7}>
                        <div>
                        <Header as="h4" >{orderItem.item.title}</Header>
                        {orderItem.item_variations.length >0 && (
                          <React.Fragment>
                          {orderItem.item_variations.map(iv => {
                            return (
                              <p key={iv.id} style={{margin:"0.5em 0em"}}>{iv.variation.name}: {iv.value}<br/></p>
                            );
                          })}
                          </React.Fragment>
                        )}
                        {orderItem.item.slug=="gift-card" &&(
                          <p>Recipient: {orderItem.gift_card_recipient.email}</p>
                        )

                        }
                        </div>
                        </Grid.Column>
                        <Grid.Column width={5} textAlign="right">
                        <div style={{verticalAlign:"middle"}}>
                        <p style={{fontSize:"1.1em", fontWeight:"bold"}}>{ccySymbol[completedOrder.order_ccy]}{orderItem.final_price}</p>
                        <p style={{fontSize:"1.1em", fontWeight:"bold"}}>Qty: {orderItem.quantity}</p>
                        </div>
                        </Grid.Column>
                        </Grid.Row>
                        </Grid>
                      </Segment>
                    );
                  })}
                </React.Fragment>
            )}
          </Segment>
          </Grid.Row>
          </Grid.Column>
          <Grid.Column>
          <Grid.Row>
            <Segment basic className="order-segment">
                <Grid>
                <Grid.Row >
                    <Grid.Column>
                    <Header as="h3">
                      Shipping
                    </Header>

                    {completedOrder && (
                      <p>
                      {completedOrder.shipping_address.first_name} {completedOrder.shipping_address.last_name}<br/>
                      {completedOrder.shipping_address.street_address}<br/>
                      {completedOrder.shipping_address.city}, {completedOrder.shipping_address.state} {completedOrder.shipping_address.zip}<br/>
                      </p>
                    )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                  <Grid.Column>
                      <Header as="h5" style={{marginBottom:"0.5em"}}>
                        Shipping method
                      </Header>
                      {completedOrder && (
                        <div>
                        <p>

                        {getShipping(completedOrder.shipping_type)}<br/>
                        </p>
                        {completedOrder.shipping.tracking_num && (
                          <div>
                          <Header as="h5" style={{marginBottom:"0.5em"}}>
                            Tracking ID
                          </Header>
                        <p><a href={getTrackingLink(completedOrder.shipping.tracking_num)}>{completedOrder.shipping.tracking_num}</a></p>
                        </div>
                        )}
                        </div>
                      )}
                  </Grid.Column>
                </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Row>
            <Grid.Row style={{margin:"2em 0em"}}>
              <Segment basic className="order-segment">
              <Grid  verticalAlign='middle' >
              <Grid.Row style={{minHeight:"6em"}}>
              <Grid.Column>
                <Header as="h3">
                  Payment
                </Header>
                {completedOrder && (
                  <React.Fragment>
                    <RenderPaymentType/>
                  </React.Fragment>
                )}
                </Grid.Column>
                </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Row>
            <Grid.Row style={{margin:"2em 0em"}}>
              <Segment basic className="order-segment">
                <Grid  verticalAlign='middle' >
                  <Grid.Row style={{minHeight:"6em"}}>
                  <Grid.Column textAlign="center">
                  <animated.div
                            style={{
                              opacity: x.interpolate({ range: [0, 1], output: [1, 1] }),
                              transform: x
                                .interpolate({
                                  range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                                  output: [1, 0.95, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
                                })
                                .interpolate(x => `scale(${x})`)
                            }}>
                  <Header style={{fontWeight:"500", fontSize:"16px"}} as="a" href="https://instagram.com/coellalove/">
                  <Icon name="instagram" />
                    Follow us on Instagram!
                  </Header>
                  </animated.div>

                  </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Row>
          </Grid.Column>

      </Grid.Row>
      </Grid>

    )}
    </div>
);
}


export default OrderCompleteNew;
