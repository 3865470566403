import React, { Component, useEffect, uesSte, useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect,useDispatch, useSelector } from "react-redux";
import {BaseRouter, GoldRouter} from "./routes";
import * as actions from "./store/actions/auth";

import { fetchInfo } from "./store/actions/siteInfo";
import { fetchCart } from "./store/actions/cart";

import "semantic-ui-css/semantic.min.css";
//import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { createMedia } from '@artsy/fresnel';

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import { PayPalScriptProvider} from "@paypal/react-paypal-js";
import MainContainer from "./containers/MainContainer/MainContainer";
import TopBanner from "./Components/TopBanner/TopBanner";
import {NavMenu2} from "./Components/NavMenu/NavMenu";
import Footer from "./containers/Footer/Footer";
import ParkingPage from "./Pages/Parking/Parking";
import useGeoLocation from "react-ipgeolocation"

export const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function usePreviousValue(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


export function App (props) {
  const dispatch = useDispatch()
  const userCCY = useSelector((state) => state.info.userCCY);
  const userCountry = useSelector((state) => state.info.userCountry);
  const authenticated = useSelector((state) => state.auth.token !== null);
  const location = useGeoLocation();
  const previousValue = usePreviousValue(location.country);

  useEffect( () => {

    dispatch(actions.authCheckState());
    dispatch(fetchCart());


  }, [])

  useEffect( () => {

    if (location.country !== previousValue) {
      if (!location.error) {
        dispatch(fetchInfo(location.country));

      }
      else{
        dispatch(fetchInfo("US"));

      }
    }

  }, [location])

  return (
      <>


      <Elements stripe={stripePromise}>
      <MediaContextProvider>

      <Router>
            <TopBanner/>
            <NavMenu2/>
              <MainContainer>
                <BaseRouter/>
              </MainContainer>
            <Footer/>
        </Router>

        </MediaContextProvider>
      </Elements>

      </>
    );

}
