import { PAYMENT_START, PAYMENT_SUCCESS, PAYMENT_FAIL, PAYMENT_RESET } from "./actionTypes";
import { authAxios, authAxiosStore } from "../../utils";
import axios from "axios";
import { endpoint, paymentListURL, paymentCreateURL, paymentUpdateURL,paymentDeleteURL } from "../../constants";

export const paymentStart = () => {
  return {
    type: PAYMENT_START
  };
};

export const paymentSuccess = data => {
  return {
    type: PAYMENT_SUCCESS,
    data
  };

};

export const paymentCreated = data => {
  return {
    type: PAYMENT_SUCCESS,
    data
  };

};

export const paymentFail = error => {
  return {
    type: PAYMENT_FAIL,
    error: error
  };
};

export const paymentReset = () => {
  return {
    type: PAYMENT_RESET,

  };
};


export const resetPayments = () => {
  return dispatch => {
    dispatch(paymentReset());

  };
};

export const fetchPayments = (token) => {
  return dispatch => {
    dispatch(paymentStart());
    const token = localStorage.getItem("token");
    axios
        .create({
        baseURL: endpoint,
        headers: {
          Authorization: `Token ${token}`
        }
        })
      .get(paymentListURL)
      .then(res => {
        dispatch(paymentSuccess(res.data));
      })
      .catch(err => {
        dispatch(paymentFail(err));
      });
  };
};

export const createPayment = (userID, stripeToken) => {
  return dispatch => {
    dispatch(paymentStart());
    const token = localStorage.getItem("token");
    axios
        .create({
        baseURL: endpoint,
        headers: {
          Authorization: `Token ${token}`
        }
        })
      .post(paymentCreateURL, {
        userID:userID,
        stripeToken: stripeToken,
      })
      .then(res => {
        dispatch(fetchPayments());

      })
      .catch(err => {
        dispatch(paymentFail(err));
      });
  };
};

export const updatePayment = (userID, cardID, authToken) => {
  return dispatch => {
    dispatch(paymentStart());
    const token = localStorage.getItem("token");
    axios
        .create({
        baseURL: endpoint,
        headers: {
          Authorization: `Token ${token}`
        }
        })
      .get(paymentUpdateURL)
      .then(res => {
        return dispatch(fetchPayments(authToken));

      })
      .catch(err => {
        dispatch(paymentFail(err));
      });
  };
};

export const deletePayment = paymentID => {
  return dispatch => {
    dispatch(paymentStart());
    const token = localStorage.getItem("token");
    axios
        .create({
        baseURL: endpoint,
        headers: {
          Authorization: `Token ${token}`
        }
        })
    .delete(paymentDeleteURL(paymentID))
    .then(res => {
      return dispatch(fetchPayments());

    })
    .catch(err => {
      dispatch(paymentFail(err));
    });
  };
};
