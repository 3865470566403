import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector} from 'react-redux'
import { fetchCart } from "../../store/actions/cart";

import {
  Input,
  Message,
  Grid,
  Segment,
  Header,
  Divider
} from "semantic-ui-react";
import axios from "axios";
import {
    addCouponURL,
  } from "../../constants";
import { Spring, animated } from 'react-spring'
import './CouponForm.css'
import {Media} from "../../App";

export default function CouponForm (props) {
  const dispatch = useDispatch()
  const [code, setcode] = useState("");
  const [couponerror, setcouponerror] = useState("");
  const [visible, setvisible] = useState(true);
  const [loading, setloading] = useState(false);

  const handleChange = e => {
    setcode(e.target.value);
  };

  const handleAddCoupon = (code) => {

      setloading(true);
      axios
        .post(addCouponURL, { code })
        .then(res => {
          setloading(false);
          setcouponerror(res.data.message);
          dispatch(fetchCart());
        })
        .catch(err => {

          setcouponerror(err.response.data.message);
          setloading(false);
          setTimeout(() => {
            setcouponerror(null)
          }, 2000);
        });


  };


  const handleDismiss = () => {
    setvisible(false)

    setTimeout(() => {
      setvisible(true)
    }, 100)
  }


  const AnimatedMessage = animated(Message);

  return (
    <Segment basic className="coupon-segment" style={{ margin: "2em 0em" }}>
    <Media greaterThan="mobile">
    <p className="order-segment-header">
       Promotions
    </p>
    </Media>
      <Grid style={{marginTop:"0.5em"}}>
      <Grid.Row>
      <Grid.Column>
      <Input fluid action={{content:'Apply', color:'black', onClick: () => handleAddCoupon(code)}} placeholder='Discount code' onChange={handleChange}/>
      {(couponerror) && (
        <Spring
          config= {{ velocity: 0.5, tension:	200,friction: 12 }}
          from={{  y: -5}}
          to={[
            {  y: 0},
         ]}>
         {styles => (
           <AnimatedMessage
            style={styles}
            color="purple"
             content={couponerror}
           />
         )}
       </Spring>
      )}
      </Grid.Column>
      </Grid.Row>
      </Grid>
      </Segment>
    );
}
