import React, { useState, useEffect } from 'react'

import {
  Container,
  Button,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Visibility,
  Sidebar,
  Icon,
  Item,
  Label,
  Input,
} from "semantic-ui-react";
import './Sidebar.css'

import { Link, useHistory } from "react-router-dom";
import {Media} from "../../App";
import {endpoint} from "../../constants"
import { useDispatch, useSelector} from 'react-redux'
import { logout } from "../../store/actions/auth";
import { filterOptions,getThumbnail} from "../../utils";
import {isEmpty, has} from "lodash"

const categories = [

  { key: 0, value: 'Necklace', url:'necklaces', text: 'Necklaces' },
  { key: 1, value: 'Bracelet', url:'bracelets',text: 'Bracelets' },
  { key: 2, value: 'Earring', url:'earrings',text: 'Earrings' },
  { key: 3, value: 'Anklet', url:'anklets',text: 'Anklets' },
  { key: 4, value: 'Stella', url:'stella', text: 'Stella Antoinette' },
  { key: 5, value: 'Extra', url:'extras',text: 'Extras' }
]

export function LeftSidebar (props){
  const dispatch = useDispatch()
  let history = useHistory();
  const [visible, setvisible] = useState(false);
  const authenticated = useSelector((state) => state.auth.token !== null);
  const handleLogout = () => {
    props.hideSidebar();
    dispatch(logout())
  }

  const handleMenuOption =(path)=>{
    props.hideSidebar();
    history.push(path)
  }

  return (

    <Sidebar
      as={Menu}
      direction='left'
      animation='overlay'
      icon='labeled'
      width='wide'
      vertical
      visible={props.visible}
      onHide={props.hideSidebar}
    >
    <Grid style={{padding: "1em"}}>
      <Grid.Row className='left-sidebar-exit'>
      <Grid.Column textAlign="right">
      <Icon name="arrow left" size='large' onClick={props.hideSidebar}/>
      </Grid.Column >
      </Grid.Row>
      {authenticated ? (
        <React.Fragment>
        <Grid.Row columns={2} verticalAlign="middle">
            <Grid.Column >
              <Button fluid className="left-siderbar-button" onClick={()=>handleLogout()}>
                Logout
              </Button>
            </Grid.Column>
            <Grid.Column  >
              <Button fluid className="left-siderbar-button" onClick={()=>handleMenuOption("/profile")}>
                Profile
              </Button>
            </Grid.Column >
            </Grid.Row>
        </React.Fragment>
      ):(
        <React.Fragment>
        <Grid.Row columns={2} verticalAlign="middle">
        <Grid.Column >
        <Button fluid className="left-siderbar-button"  style={{float:"center"}} onClick={()=>handleMenuOption("/login")}>

            Sign in
            </Button>

        </Grid.Column>
        <Grid.Column  verticalAlign="middle" textAlign="right">
        <a href="https://instagram.com/coellalove/" >
        <Icon size='big' name='instagram'/>
        </a>
        </Grid.Column >
        </Grid.Row>
        </React.Fragment>
      )}

      <Grid.Row>
      <Grid.Column>

      <div style={{textAlign:"center"}}>
      <Divider style={{margin:"0em"}}/>
      {categories.map((filter) => {
        return(
          <React.Fragment key={filter.key}>
          <Segment basic style={{padding:"0.5em", margin:"1em"}} >
          <Link to={`/products/${filter.url}`}>
          <p className="left-siderbar-menu" onClick={()=>props.hideSidebar()}>

          {filter.text}
          </p>
          </Link>

          </Segment>
          <Divider style={{margin:"0em"}}/>
          </React.Fragment>
        )
      })
      }
      </div>



      </Grid.Column>
      </Grid.Row>


      </Grid>
    </Sidebar>

  )
}


export function RightSidebar (props){
  const [visible, setVisible] = useState(false);
  const cart = useSelector((state) => state.cart.shoppingCart);
  let history = useHistory();

  useEffect(() => {


    console.log(cart)
  },[cart]);


  const handleGoToBag = () => {
    props.hideSidebar();
    history.push("/order-summary")
  }

  return (
    <Sidebar
      as={Menu}
      direction='right'
      animation='overlay'
      icon='labeled'
      onHide={() => props.hideSidebar()}
      vertical
      visible={props.visible}
      width='wide'
    >
    <Grid >
    <Grid.Row >
     <Grid.Column textAlign="left">
     <Icon name="arrow left" size='large' onClick={()=>props.hideSidebar()}/>
     </Grid.Column >
    </Grid.Row>

    <Grid.Row>
    <Grid.Column textAlign="left">
       <Header as="h3" >
       Shopping Bag
       </Header>
       <Divider />
    </Grid.Column>
    </Grid.Row>
    <Grid.Row>
       <Grid.Column>

       {(has(cart, 'order_items')) ? (
       <React.Fragment>

           {cart.order_items.map((orderItem, i) => {
             return (

               <Segment basic key={i}>
               <Grid>
               <Grid.Row columns={3}>
               <Grid.Column width={4} style={{padding:"0em"}}>
               <Image size='small' src={getThumbnail(orderItem)} />
               </Grid.Column>
               <Grid.Column width={7} textAlign="left">
                 <div>
                 <p style={{margin:"0em",fontSize:"1.1em", fontWeight:"bold"}} >{orderItem.item.title}</p>
                 {orderItem.item_variations.length >0 && (
                   <React.Fragment>
                   {orderItem.item_variations.map(iv => {
                     return (
                       <p key={iv.id} style={{margin:"0.5em 0em"}}>{iv.name}: {iv.value}<br/></p>
                     );
                   })}
                   </React.Fragment>
                 )}
                 </div>
                 </Grid.Column>
                 <Grid.Column width={5} textAlign="right">
                 <div >

                 <p style={{fontSize:"1.1em", fontWeight:"bold"}}>Qty: {orderItem.quantity}</p>
                 </div>
                 </Grid.Column>
                 </Grid.Row>
                 </Grid>
               </Segment>

             );
           })}

       {cart.order_items.length < 1 ? (
         <Header as="h4">No items in your bag</Header>
       ) : null}
       </React.Fragment>
       ) : (
         <Header as="h4">No items in your bag</Header>
       )}

       </Grid.Column>
       </Grid.Row>
       <Grid.Row verticalAlign="bottom" style={{padding:"0em", marginTop:"4em"}}>
       <Grid.Column>
         <Button
         className="shopping-bag-button"
         fluid
         color='black'

         onClick={handleGoToBag}>
         View Shopping Bag
         </Button>
       </Grid.Column>
       </Grid.Row>
      </Grid>
    </Sidebar>
  )
}
