import { ADDRESS_START, ADDRESS_SUCCESS, ADDRESS_FAIL, ADDRESS_UPDATE, ADDRESS_CREATE, STATES_SUCCESS, COUNTRIES_SUCCESS,ADDRESS_RESET, ADDRESS_TEMP } from "./actionTypes";
import { authAxios } from "../../utils";
import axios from "axios";
import { endpoint, addressListURL,addressCreateURL, addressDeleteURL, addressUpdateURL, stateListURL,countryListURL} from "../../constants";

export const addressStart = () => {
  return {
    type: ADDRESS_START
  };
};

export const addressSuccess = data => {
  return {
    type: ADDRESS_SUCCESS,
    data
  };

};

export const createTempAddress = data => {
  return {
    type: ADDRESS_TEMP,
    data
  };

};

export const statesSuccess = data => {
  return {
    type: STATES_SUCCESS,
    data
  };

};

export const countriesSuccess = data => {
  return {
    type: COUNTRIES_SUCCESS,
    data
  };

};

export const addressUpdated = data => {
  return {
    type: ADDRESS_UPDATE,
    data
  };

};

export const addressFail = error => {
  return {
    type: ADDRESS_FAIL,
    error: error
  };
};

export const addressReset = () => {
  return {
    type: ADDRESS_RESET,

  };
};

export const resetAddresses = () => {
  return dispatch => {
    dispatch(addressReset());

  };
};

export const fetchAddresses = (type) => {
  return dispatch => {
  dispatch(addressStart());
  const token = localStorage.getItem("token");
  axios
      .create({
      baseURL: endpoint,
      headers: {
        Authorization: `Token ${token}`
      }
      })
    .get(addressListURL(type))
    .then(res => {

      dispatch(addressSuccess(res.data));

    })
    .catch(err => {
      dispatch(addressFail(err));

    });
  };
};

export const createAddress = (formData) => {
  return dispatch => {
  dispatch(addressStart());
  const token = localStorage.getItem("token");
  axios
      .create({
      baseURL: endpoint,
      headers: {
        Authorization: `Token ${token}`
      }
      })
    .post(addressCreateURL, formData

    )

    .then(res => {
      return dispatch(fetchAddresses("S"));

    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        dispatch(addressFail({"shipping":error.response.data}));
        //setTimeout(() => {
          //  dispatch({ type: ERROR_TIMEOUT })
        //  }, 5000)
      } else if (error.request) {
        // The request was made but no response was received
        console.log("ERROR REQUEST");
        dispatch(addressFail(error.message));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }

    });
  };
};


export const deleteAddress = addressID => {
  return dispatch => {
    dispatch(addressStart());
    const token = localStorage.getItem("token");
    axios
        .create({
        baseURL: endpoint,
        headers: {
          Authorization: `Token ${token}`
        }
        })
    .delete(addressDeleteURL(addressID))
    .then(res => {
      return dispatch(fetchAddresses("S"));

    })
    .catch(err => {
      dispatch(addressFail(err));
    });
  };
};

export const updateAddress = (formData) => {
  return dispatch => {
    dispatch(addressStart());
    const token = localStorage.getItem("token");
    axios
        .create({
        baseURL: endpoint,
        headers: {
          Authorization: `Token ${token}`
        }
        })
      .put(addressUpdateURL(formData.id), {
        ...formData,

      })
      .then(res => {

        return dispatch(fetchAddresses("S"));
      })

      .catch(err => {
        dispatch(addressFail(err));
      });
    };
};



export const fetchStates = () => {
  return dispatch => {
    dispatch(addressStart());
    axios
    .get(stateListURL)
    .then(res => {
      dispatch(statesSuccess(res.data));
    })
    .catch(err => {
        dispatch(addressFail(err));
    });
  };
};

export const fetchCountries = () => {
  return dispatch => {
    dispatch(addressStart());
    axios
    .get(countryListURL)
    .then(res => {
      dispatch(countriesSuccess(res.data));
    })
    .catch(err => {
        dispatch(addressFail(err));
    });
  };
};
