export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export function handleGetDefault (array) {
  const filteredArray = array.filter(el => el.default === true);
  if (filteredArray.length > 0) {

    return filteredArray[0];
  }
  return false;
};

export function handleFormatStates (states) {
  const keys = Object.keys(states);
  return keys.map(k => {
    return {
      key: k,
      text: states[k],
      value: k
    };
  });
};
