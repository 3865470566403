import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector} from 'react-redux'
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Container,
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Icon,
  Input
} from "semantic-ui-react";
import { connect } from "react-redux";
import { NavLink, Redirect,useHistory } from "react-router-dom";
import { authLogin } from "../../store/actions/auth";
import {exists, has} from "lodash"
import './Email.css'
import { emailCreateURL} from "../../constants";
import axios from "axios";
import { Spring, animated, config, useSpring} from 'react-spring'

export default function Email (props) {
  const dispatch = useDispatch()
  const [formData, setformData] = useState({"email":"","password":""});
  const [emailList, setEmailList] = useState('');
  const [emailButton, setEmailButton] = useState('Subscribe');
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [emailSaving, setEmailSaving] = useState(false);
  const [emailPlaceholder, setEmailPlaceholder] = useState('Enter your email');
  const [error, setError] =  useState(false);

  const from = props.location.state;
  const history = useHistory();
  const AnimatedMessage = animated(Message);

  const handleCreateEmail = () => {

    setEmailSaving(true)

    axios
      .post(emailCreateURL, {

        email: emailList,

      })
      .then(res => {
        setEmailSuccess(true)
        setEmailSaving(false)
        setEmailPlaceholder('Thank you for joining!')
        setEmailButton('Thank You!')
        setEmailList('')
      })
      .catch(err => {
        setEmailSaving(false)
        setError(true)
        console.log(err)
      });
  };

  const onChangeEmail = (e) => {
    setError(false)
    setEmailList(e.target.value)

  };

  return(
    <div className="email-container">
    <Grid
    centered
    container

    className="email-grid"

    >
    <Grid.Row verticalAlign="middle" style={{ margin:"0em 0em"}} >

      <Grid.Column textAlign="center" style={{ padding:"0em 0em"}}>
      <Segment basic className="email-segment">
        <Header as="h3" textAlign="left">
          Suscribe to our email list
        </Header>
        <div style={{margin:"2em 0em"}}>
        {emailSuccess && (
          <Spring
            config= {{ velocity: 0.5, tension:	200,friction: 12 }}
            from={{  y: -5}}
            to={[
              {  y: 0},
           ]}>
           {styles => (
             <AnimatedMessage
              style={styles}
              size='small'
              content='Thank you for subscribing!'
             />
           )}
         </Spring>

        )}
        <Input
        fluid
        action={{
          labelPosition: 'left',
          icon: 'mail',
          content: emailButton,
          onClick:()=> handleCreateEmail(),
          disabled:emailSuccess,
          loading:emailSaving
        }}
        error={error}
        onChange={onChangeEmail}
        placeholder={emailPlaceholder}
        />
        </div>

        </Segment>
      </Grid.Column>
      </Grid.Row>
    </Grid>
    </div>
  )

};
