import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import {App} from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import './index.css';

import authReducer from "./store/reducers/auth";
import cartReducer from "./store/reducers/cart";
import userReducer from "./store/reducers/user";
import addressReducer from "./store/reducers/address";
import paymentReducer from "./store/reducers/payment";
import productsReducer from "./store/reducers/products";
import orderReducer from "./store/reducers/order";
import infoReducer from "./store/reducers/siteInfo";

import ParkingPage from "./Pages/Parking/Parking";
import axios from 'axios';
import { endpoint } from "./constants";

axios.defaults.withCredentials = true
axios.defaults.baseURL = endpoint
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'x-csrftoken'

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  user: userReducer,
  address: addressReducer,
  payment: paymentReducer,
  products: productsReducer,
  order: orderReducer,
  info: infoReducer,
});

const store = createStore(rootReducer, composeEnhances(applyMiddleware(thunk)));

const park = (
  <ParkingPage/>
);

const app = (

  <Provider store={store}>
    <App />
  </Provider>

);

ReactDOM.render(app, document.getElementById("root"));
//registerServiceWorker();
