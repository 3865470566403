import React, { useState } from "react";
import { useDispatch } from 'react-redux'

import {
  Card,
  Confirm,
  Label
} from "semantic-ui-react";

import { deleteAddress } from "../../store/actions/address";


export default function AddressCard (props) {
  const dispatch = useDispatch();
  const address = props.address
  const showEdit = props.showEdit
  const showDelete = props.showDelete

  const [showConfirm, setshowConfirm] = useState(false);

  const handleConfirm = ()=>{
    dispatch(deleteAddress(address.id))
  }

  return (
    <Card fluid>
      <Card.Content>
      {address.default && (
        <Label attached='top right' >Default</Label>
      )}
        <Card.Header>
        {address.first_name} {address.last_name}

        </Card.Header>
        <Card.Description>
          {address.street_address}<br/>
          {address.apartment_address && (
          <segment>{address.apartment_address}<br/></segment>
          )}
          {address.city}, {address.state} {address.zip}
        </Card.Description>
      </Card.Content>
      {(showEdit === true || showDelete ===true) && (
      <Card.Content extra>
        {showEdit && (
          <div className="cardOptions">
          <a onClick={props.onEdit}>Edit</a>
          </div>
        )}
        {showDelete && (
          <React.Fragment>
          <div className="cardOptions">
          <a onClick={()=>setshowConfirm(true)}>Delete</a>
          </div>
          <Confirm
            open={showConfirm}
            header='Confirm delete'
            content='Are you sure?'
            onCancel={()=>setshowConfirm(false)}
            onConfirm={()=>handleConfirm()}
          />
          </React.Fragment>
        )}
      </Card.Content>
      )}
    </Card>
  )
}
