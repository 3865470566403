import React from "react";
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import Hoc from "./hoc/hoc";
import { connect, useDispatch, useSelector } from "react-redux";

import Login from "./Pages/Login/Login";
import Signup from "./Pages/Signup/Signup";
import Email from "./Pages/Email/Email";
import {FrontPage} from "./Pages/FrontPage/FrontPage_fall_2023";
import ProductList from "./Pages/ProductList/ProductList";
import ProductDetail from "./Pages/ProductDetail/ProductDetail";
import OrderSummary from "./Pages/OrderSummary/OrderSummary";
import Checkout from "./Pages/Checkout/Checkout";
import Profile from "./Pages/Profile/Profile";
import OrderComplete from "./Pages/OrderComplete//OrderComplete";
import MainContainer from "./containers/MainContainer/MainContainer";
import ParkingPage from "./Pages/Parking/Parking";
import Info from "./Pages/Info/Info";

import Inspirations from "./Pages/Info/Inspirations";

const Page404 = () => {
  return (
    <h3>404 - Not found</h3>
  );
};

export const BaseRouter = (props) => (



  <Switch>

    <Route exact path="/" component={FrontPage} />
    <Route exact path="/products/" component={ProductList} />
    <Route exact path="/products/:category" component={ProductList} />
    <Route exact path="/products/:category/:productID"  component={ProductDetail} />

    <Route path="/signup" component={Signup} />
    <Route path="/order-summary" component={OrderSummary} />
    <Route exact path="/checkout" component={Checkout} />

    <Route exact path="/inspirations" component={Inspirations} />
    <Route path="/order/:orderID" component={OrderComplete} />
    <Route path="/profile" component={Profile} />
    <Route path="/about" component={Info} />
    <Route path="/terms" component={Info} />
    <Route path="/returns" component={Info} />
    <Route path="/login" component={Login} />
    <Route path="/email" component={Email} />
    <Route path="/parking" component={ParkingPage} />
    <Route component={Page404} />
  </Switch>
);

export const GoldRouter = () => (

  <Switch>
    <Route path="/" component={ParkingPage} />
    <Route path="/login" component={Login} />
  </Switch>

);

function PrivateRoute({ children, ...rest }) {
  const authenticated = useSelector((state) => state.auth.token !== null);

  return (
    <Route
      {...rest}
      render={() => {
        return authenticated ? (
          children
        ) : (
          <Redirect to="/parking" />
        );
      }}
    />
  );
}
