import React, { useState, useEffect  } from "react";
import { useSelector, useDispatch } from 'react-redux'

import {AddressForm} from "../../Components/AddressForm/AddressForm";
import AddressCard from "../../Components/AddressCard/AddressCard";
import ExpressPayment from "../../Components/ExpressPayment/ExpressPayment";
import {ContactForm} from "../../Components/ContactForm/ContactForm";

import {isEmpty, has} from "lodash"

import {
  Divider,
  Header,
  Segment
} from "semantic-ui-react";

import { buildOrder } from "../../store/actions/order";
import parsePhoneNumber from 'libphonenumber-js'
import { AsYouType } from 'libphonenumber-js'
function formatPhoneNumber(value) {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}

export default function Information (props) {
  const dispatch = useDispatch()
  const order = props.order
  const contact = props.order.contact
  const shipping = props.order.shipping
  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  const defaultShippingAddress = useSelector((state) => state.address.defaultShippingAddress);
  const userCountry = useSelector((state) => state.info.userCountry);
  const orderError = props.orderError

  const user = props.user;


  const [editShipping, seteditShipping] = useState(false);

  useEffect(() => {

    if (isAuthenticated) {

      var buildDict= {"contact":{"email": user.email,"phone":user.phone}}
      if (defaultShippingAddress) {

        var buildDict={"shipping": defaultShippingAddress,
                        "contact":{"email": user.email,"phone":user.phone}}


      }else{

      }
        dispatch(buildOrder(buildDict))
    }
  },[isAuthenticated, defaultShippingAddress,dispatch]);


  useEffect(() => {

    if ((has(orderError, 'shipping'))){
      seteditShipping(true)
    }


  },[orderError]);


  const buildShipping = (name, value) => {
    const formData = order.shipping;
    const updatedFormdata = {
      ...order.shipping,
      [name]: value,
      'address_type':'S'
    };
    const buildDict={"shipping": updatedFormdata}
    dispatch(buildOrder(buildDict))
  };

  const toggleForm = () => {
    console.log(order)
    seteditShipping(!editShipping)
  }

  const buildContact = e => {
    const formData = order.contact;
    var value = e.target.value
    if (e.target.name === "phone") {

      const phoneNumber = parsePhoneNumber(value, userCountry)

      console.log(phoneNumber, userCountry)
      if (phoneNumber){

      var value = phoneNumber.number
    }
    }
    const updatedFormdata = {
      ...order.contact,
      [e.target.name]: value,
    };
    const buildDict={"contact": updatedFormdata}
    dispatch(buildOrder(buildDict))
  };

  return(
      <div>
      <Segment basic className="order-segment">

      <p className="order-segment-header">
         Contact Info
      </p>


      {contact && (

      <ContactForm user={user} contactChange={buildContact} order={contact} contactError={orderError}/>
            )}
      </Segment>
      <Segment basic className="order-segment" style={{ margin: "2em 0em" }}>

        <p className="order-segment-header">
        Shipping Address
        </p>


        {editShipping ? (
          <AddressForm addressChange={buildShipping} address={shipping} addressError={orderError} onCancel={toggleForm}/>
        ):(
        <React.Fragment>
        {defaultShippingAddress ? (
          <AddressCard address={shipping} showEdit={true} onEdit={toggleForm}/>
        ):(
          <React.Fragment>
          {shipping && (
          <AddressForm addressChange={buildShipping} address={shipping} addressError={orderError}/>
            )}
          </React.Fragment>
        )}
        </React.Fragment>
      )}
      </Segment>
      </div>
  )
}
