import React, { useState, useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux'
import {
  Form,
  Select,
  Segment
  } from "semantic-ui-react";
import {exists, has} from "lodash"
import { fetchCountries,fetchStates} from "../../store/actions/address";

const addressTypes = {'S':'shipping','B':'billing'}

export function AddressForm (props) {
  const dispatch = useDispatch()
  const address = props.address

  const USstates = useSelector((state) => state.info.states);
  const countries = useSelector((state) => state.info.countries );
  const formError = props.addressError.shipping

  useEffect(() => {

  }, [countries]);

  useEffect(() => {

  }, [USstates]);

  const handleChange = e => {
    props.addressChange(e.target.name, e.target.value);
  };

  const handleSelectChange = (e, { name, value }) => {
    props.addressChange(name, value);
  };

  const toggleCheckBox = () => {
    const defaultAddress = !address.default;
    props.addressChange("default", defaultAddress);
  }

  return (

      <Form>

      <Form.Group widths='equal'>
        <Form.Input
          required
          fluid
          value={address.first_name || ''}
          name="first_name"
          placeholder="First name"
          onChange={handleChange}
          error={(has(formError, 'first_name')) ? {
                content: formError.first_name[0]
            }:false}
        />
        <Form.Input
          required
          fluid
          value={address.last_name || ''}
          name="last_name"
          placeholder="Last name"
          onChange={handleChange}
          error={(has(formError, 'last_name')) ? {
                content: formError.last_name[0]
            }:false}
        />
        </Form.Group>
        <Form.Input
          required
          value={address.street_address || ''}
          name="street_address"
          placeholder="Street address"
          onChange={handleChange}
          error={(has(formError, 'street_address')) ? {
                content: formError.street_address[0]
            }:false}
        />
        <Form.Input
          value={address.apartment_address || ''}
          name="apartment_address"
          placeholder="Apartment/Unit"
          onChange={handleChange}

        />
        <Form.Group widths='equal'>
        <Form.Input
          fluid
          width={6}
          value={address.city || ''}
          name="city"
          placeholder="City"
          onChange={handleChange}
          error={(has(formError, 'city')) ? {
                content: formError.city[0]
            }:false}
        />
        {address.country == "US" && (
        <Form.Field required width={6}>
          <Select
            loading={USstates.length < 1}

            search
            fluid
            options={USstates}
            value={address.state || ""}
            name="state"
            placeholder="State"
            onChange={handleSelectChange}
            error={(has(formError, 'state')) ? true:false}
            autoComplete='state'

          />
        </Form.Field>
        )}

        <Form.Input
          fluid
          width={4}
          required
          value={address.zip || ''}
          name="zip"
          placeholder="Postal code"
          onChange={handleChange}
          error={(has(formError, 'zip')) ? {
                content: formError.zip[0]
            }:false}
            autoComplete='zip code'
        />

        </Form.Group>
        <Form.Field required >
        <Select
          loading={countries.length < 1}

          search
          fluid
          options={countries}
          value={address.country || ""}
          name="country"
          placeholder="Country"
          onChange={handleSelectChange}
          error={(has(formError, 'country')) ? true:false}
          autoComplete='country'

        />
        </Form.Field>
        {props.showSetPrimary && (
        <Form.Checkbox
          checked={address.default || false}
          name="default"
          label="Set as primary address"
          onChange={toggleCheckBox}

        />
        )}
        <span>
        {props.showSave && (
          <Form.Button onClick={props.onSave} disabled={props.saving} loading={props.saving} >
          Save
          </Form.Button>
        )}
        {props.showCancel && (
          <p onClick={props.onCancel}>Cancel</p>
        )}
        </span>
      </Form>

    );

}
