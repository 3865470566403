import { PAYMENT_START, PAYMENT_SUCCESS, PAYMENT_FAIL, PAYMENT_RESET } from "../actions/actionTypes";
import { updateObject, handleGetDefault } from "../utility";

const initialState = {
  payments: [],
  defaultPayment: {},
  error: null,
  loading: false
};

const paymentStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const paymentSuccess = (state, action) => {
  return updateObject(state, {
    payments: action.data,
    defaultPayment: [handleGetDefault(action.data)],
    error: null,
    loading: false
  });
};

const paymentFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const paymentReset = (state, action) => {
  return updateObject(state, {
    payments: [],

  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_START:
      return paymentStart(state, action);
    case PAYMENT_SUCCESS:
      return paymentSuccess(state, action);
    case PAYMENT_FAIL:
      return paymentFail(state, action);
    case PAYMENT_RESET:
      return paymentReset(state, action);
    default:
      return state;
  }
};

export default reducer;
