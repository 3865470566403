import { INFO_START, INFO_SUCCESS, INFO_FAIL } from "../actions/actionTypes";
import { updateObject, handleGetDefault, handleFormatStates } from "../utility";

const initialState = {
  siteInfo: [],
  userCountry: 'US',
  userCCY: '',
  states: [],
  countries: [],
  error: null,
  loading: false
};

const infoStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const infoSuccess = (state, action) => {
  return updateObject(state, {
    userCountry: action.data.userCountry,
    userCCY: action.data.userCCY,
    siteInfo: action.data.segments,
    states: handleFormatStates(action.data.states),
    countries: handleFormatStates(action.data.countries),
    error: null,
    loading: false
  });
};

const infoFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    userCCY: 'usd',
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INFO_START:
      return infoStart(state, action);
    case INFO_SUCCESS:
      return infoSuccess(state, action);
    case INFO_FAIL:
      return infoFail(state, action);
    default:
      return state;
  }
};

export default reducer;
