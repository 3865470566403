import React, { useEffect } from "react";
import { useDispatch, useSelector} from 'react-redux'

import {
  Container,
  Grid,
  Button,
  Message,
  Icon,
  Header,
  Divider,
  Segment
} from "semantic-ui-react";

import './OrderReview.css'

import {Media} from "../../App";
import CouponForm from "../CouponForm/CouponForm";
import GiftCardForm from "../GiftCardForm/GiftCardForm";
import { ccySymbol } from "../../utils";

export default function OrderReview (props) {
  const cart = useSelector((state) => state.cart.shoppingCart);

  useEffect( () => {

    }, [cart]
  );

  return (
    <div className="order-review">
      {cart && (

        <Segment basic className="order-segment">
          <p className="order-segment-header">
             Order Summary
          </p>

                <Grid >

                <Grid.Row columns={2}>
                  <Grid.Column textAlign='left'>
                  <p>Your items </p>
                  {cart.discount > 0 && (
                      <p>Applied discount</p>
                  )}
                  {cart.giftcard > 0 && (
                      <p>Gift card applied</p>
                  )}
                  {cart.sales_tax > 0 && (
                  <p>Sales Tax</p>
                  )}
                  <p>Shipping</p>

                  </Grid.Column>
                  <Grid.Column textAlign='right'>
                  <p>{ccySymbol[cart.ccy]}{cart.order_subtotal.toFixed(2)}</p>
                  {cart.discount > 0 && (
                      <p>-{ccySymbol[cart.ccy]}{cart.discount.toFixed(2)}</p>
                  )}
                  {cart.giftcard > 0 && (
                      <p>-{ccySymbol[cart.ccy]}{cart.giftcard.toFixed(2)}</p>
                  )}
                  {cart.sales_tax > 0 && (
                  <p>{ccySymbol[cart.ccy]}{cart.order_salestax.toFixed(2)}</p>
                  )}
                  {cart.postage == 0 ? (
                    <p>FREE</p>
                  ):(
                    <p>{ccySymbol[cart.ccy]}{cart.postage}</p>
                  )
                  }
                  </Grid.Column>
                </Grid.Row>
                <Divider clearing style={{margin:"0em 1em"}}/>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign='left'>

                  <p><b>Total</b></p>
                  </Grid.Column>
                  <Grid.Column textAlign='right'>
                  <p><b>{ccySymbol[cart.ccy]}{cart.order_total.toFixed(2)}</b></p>
                  </Grid.Column>
                </Grid.Row>
                </Grid>
                <Media at="mobile">
                <Grid>
                <Grid.Row>
                  <Grid.Column textAlign='left'>
                    <GiftCardForm/>
                    <CouponForm/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
                </Media>

          </Segment>

      )}

    </div>
  );

};
