import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector} from 'react-redux'
import './Info.css'
import {endpoint} from "../../constants";
import {
  Container,
  Button,
  Grid,
  Header,
  Message,
  Segment,
  Icon,
  Divider,
  Image,
  Dimmer
} from "semantic-ui-react";

import { NavLink, Redirect,useHistory,Link } from "react-router-dom";
import { authLogin } from "../../store/actions/auth";
import {find,isEmpty} from "lodash"

export default function About (props) {
  const dispatch = useDispatch()
  const [pageData, setpageData] = useState({});
  const [pageError, setpageError] = useState({});
  const page = props.location.pathname.slice(1)

  const from = props.location.state;
  const history = useHistory();

  useEffect(() => {

  },[]);

  return(
    <div>

      <Grid
      centered
      container
      style={{margin:"3em 0em"}}
      >
        <Grid.Row >
          <Grid.Column >
            <Segment padded basic>
              <Header as="h2" textAlign="center">
                Inspirations
              </Header>
            </Segment>
          </Grid.Column >
        </Grid.Row>
        <Divider style={{margin:"0em"}}/>
        <Grid.Row >
          <Grid.Column >
            <Segment padded basic>
            <Link to="/products">
            <Image.Group size='small'>
             <Image src={"https://api.coellalove.com/media/img_1a.jpg"} />
             <Image src={"https://api.coellalove.com/media/img_2a.jpg"} />

            </Image.Group>
            </Link>
            <Link to="/products">
            <Button basic size="large" style={{margin:"2em 0em"}} color="black" >Shop Now</Button>
            </Link>
            </Segment>
          </Grid.Column>
          </Grid.Row>
      </Grid>

  </div>
  )

};
