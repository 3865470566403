import React, {useEffect} from 'react'
import { useDispatch, useSelector} from 'react-redux'
 import { withRouter } from "react-router-dom";

import './MainContainer.css';
import {Header,
    Segment, Container, Dimmer, Loader, Sidebar} from "semantic-ui-react";
import ReactLoader from "react-spinners/SyncLoader";


const ProgressBar = props => {
  const y = 24 - (24 * props.percentage) / 100;
  return (
    <div className="ProgressBarH">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <defs>
          <clipPath id="cut-off-bottom">
            <rect x="0" y="50" width="24" height="24" />
          </clipPath>
        </defs>
        <path
          style={{ fill: "red" }}
          d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"
          clipPath="url(#cut-off-bottom)"
        />
      </svg>
    </div>
  );
};



export const MainContainer = ({ children, location: { pathname } }) => {
  const orderLoading = useSelector((state) => state.order.loading);
  const loadingMessage = useSelector((state) => state.order.loadingMessage);

  useEffect(() => {
       window.scrollTo({
         top: 0,
         left: 0,
         behavior: "smooth"
       });
     }, [pathname]);

  return (

        <Dimmer.Dimmable className="main-container" dimmed={orderLoading} blurring>
          <Dimmer className="dimmer-container" active={orderLoading} >
          <ReactLoader color="#FFFFFF"/>
          <Header as="h4" style={{color:"#FFFFFF"}}>{loadingMessage}</Header>
          </Dimmer>
          {children}
        </Dimmer.Dimmable>

     )
};



export default withRouter(MainContainer);
