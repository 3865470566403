import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';
import './CardCarousel.css'
import React from "react";
import {
  Card,
  Image,
  Header,
  Label,
  Icon
} from "semantic-ui-react";

import {endpoint} from "../../constants";
import { useMediaQuery } from "react-responsive";

import CustomDotGroup from "./CustomDotGroup";

export function CardCarousel ({products, pushItem}) {

  const isBigScreen = useMediaQuery({
    query: "(min-width: 768px)"
  });

  const onTrigger = (item) => {

        pushItem(item.category,item.slug);
    }

  const getThumbnail = (item) => {
    if (item.img[0]) {
      return item.img[0].img_thumb
    }
    else {
      return "https://api.coellalove.com/media/image.png"
    }
  }

  const slides = products.map((item, i) => (
    <Slide index={i} key={i} style={{height:"225px"}}>
    <div style={{ padding: 10 }}>
      <Card
      className="product-card"
        index={i}
        fluid
        onClick={()=>onTrigger(item)}
      >
      <Image src={getThumbnail(item)} slug={item.slug} alt="gold filled jewelry necklaces earrings bracelets anklets" />
      <Card.Content className="carousel-card-content" textAlign="left">
        <Card.Header>
          <Header className="carousel-card-header" as="h5">{item.title}</Header>
          {item.discount_price && (
            <Label
            style={{ marginLeft: '1em' }}
            size="tiny"
            horizontal
            color={
                item.label === "primary"
                  ? "blue"
                  : item.label === "secondary"
                  ? "green"
                  : "black"
              }
            >
              {item.label}
            </Label>
          )}
        </Card.Header>

          <span className="carousel-card-price">${item.price}</span>

    </Card.Content>
      </Card>
      </div>
      </Slide>
  ));
  return (
  <CarouselProvider
    naturalSlideWidth={1}
    naturalSlideHeight={1.25}
    totalSlides={products.length}
    visibleSlides={isBigScreen ? 4 : 2}
    infinite={true}
  >

    <Slider >
    {slides}
    </Slider>

    <CustomDotGroup slides={products.length}/>
  </CarouselProvider>
)
};




export function ImageCarousel (props) {

  const slides = props.images.map((image, i) => (
    <Slide index={i} key={i} style={{ height: "100%" }}>
    <div style={{ padding: "1em" }}>
    <Image src={image.img_base} alt="gold filled jewelry necklaces earrings bracelets anklets" rounded/>

    </div>
    </Slide>
  ));

  return (
    <div className="carousel-container">
  <CarouselProvider
    naturalSlideWidth={1}
    naturalSlideHeight={1}
    totalSlides={props.images.length}
    visibleSlides={1}
    infinite={true}
  >

    <Slider >
    {slides}
    </Slider>
    {props.images.length > 1 && (
      <React.Fragment>
    <CustomDotGroup slides={props.images.length}/>
    <ButtonBack className="button-back"><Icon style={{color:"#000000"}} size="large" name="chevron left"/></ButtonBack>
      <ButtonNext className="button-next"><Icon style={{color:"#000000"}} size="large" name="chevron right"/></ButtonNext>
        </React.Fragment>
      )
    }
  </CarouselProvider>
  </div>
)
};
