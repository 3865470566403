import { INFO_START, INFO_SUCCESS, INFO_FAIL } from "./actionTypes";
import { authAxios } from "../../utils";
import axios from "axios";
import { endpoint, siteInfoURL } from "../../constants";


export const infoStart = () => {
  return {
    type: INFO_START
  };
};

export const infoSuccess = data => {
  return {
    type: INFO_SUCCESS,
    data
  };

};

export const infoFail = error => {
  return {
    type: INFO_FAIL,
    error: error
  };
};

export const fetchInfo = (country) => {
  return dispatch => {
    dispatch(infoStart());

    axios
      .get(siteInfoURL(country))
      .then(res => {

        dispatch(infoSuccess(res.data));
        })
      .catch(err => {

        dispatch(infoFail(err));
      });
  };
};
