import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Icon
} from "semantic-ui-react";
import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import { authSignup } from "../../store/actions/auth";
import {exists, has} from "lodash"

class RegistrationForm extends React.Component {
  state = {

    email: "",

    password1: "",
    password2: ""
  };

  handleSubmit = e => {
    e.preventDefault();

    const { email, first_name, last_name, password1, password2 } = this.state;
    this.props.signup(email, first_name, last_name, password1, password2);
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { firstName, lastName, email, password1, password2 } = this.state;
    const { error, loading, token } = this.props;

    return (
      <Grid
        centered
        container
        style={{margin:'3em 0em'}}

      >
      <Grid.Row verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>

        {token ? (
          <Segment basic padded>
          <Header as="h2" textAlign="left">
            Thank you for joining us!
          </Header>
          <p>You are now logged in.</p>
          </Segment>

        ):(
          <Segment padded basic>
            <Header as="h2" textAlign="left">
              Create account
            </Header>
            <Header sub textAlign="left">
              Already have an account? <NavLink to="/login">Login</NavLink>
            </Header>

            <React.Fragment>
              <Form size="small" onSubmit={this.handleSubmit} style={{ marginTop: "2em" }}>

                  <Form.Input
                    onChange={this.handleChange}
                    value={firstName}
                    name="first_name"
                    fluid
                    placeholder="First Name"
                    error={(has(error, 'first_name')) ? {
                          content: error.first_name[0]
                      }:false}
                  />
                  <Form.Input
                    onChange={this.handleChange}
                    value={lastName}
                    name="last_name"
                    fluid
                    placeholder="Last Name"
                    error={(has(error, 'last_name')) ? {
                          content: error.last_name[0]
                      }:false}
                  />
                  <Form.Input
                    onChange={this.handleChange}
                    value={email}
                    name="email"
                    fluid
                    icon="mail"
                    iconPosition="left"
                    placeholder="E-mail address"
                    error={(has(error, 'email')) ? {
                          content: error.email[0]
                      }:false}
                  />
                  <Form.Input
                    onChange={this.handleChange}
                    fluid
                    value={password1}
                    name="password1"
                    icon="lock"
                    iconPosition="left"
                    placeholder="Password"
                    type="password"
                    error={(has(error, 'password1')) ? {
                          content: error.password1[0]
                      }:false}
                  />
                  <Form.Input
                    onChange={this.handleChange}
                    fluid
                    value={password2}
                    name="password2"
                    icon="lock"
                    iconPosition="left"
                    placeholder="Confirm password"
                    type="password"
                    error={(has(error, 'password2')) ? {
                          content: error.password2[0]
                      }:false}
                  />
                  {(has(error, 'non_field_errors')) && (
                    <Message negative>
                    {error.non_field_errors}
                    </Message>

                  )

                  }
                  <Button
                    color="black"
                    fluid
                    className = "login-button"
                    loading={loading}
                    disabled={loading}
                  >
                    Signup
                  </Button>

              </Form>

            </React.Fragment>
              </Segment>

        )
        }

        </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signup: (email, first_name, last_name, password1, password2) =>
      dispatch(authSignup(email, first_name, last_name, password1, password2))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationForm);
