import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector} from 'react-redux'
import './Info.css'
import {endpoint} from "../../constants";
import {
  Container,
  Button,
  Grid,
  Header,
  Message,
  Segment,
  Icon,
  Divider,
  Image,
  Dimmer
} from "semantic-ui-react";

import { NavLink, Redirect,useHistory } from "react-router-dom";
import { authLogin } from "../../store/actions/auth";
import {find,isEmpty} from "lodash"

export default function About (props) {
  const dispatch = useDispatch()
  const [pageData, setpageData] = useState({});
  const [pageError, setpageError] = useState({});
  const page = props.location.pathname.slice(1)
  const info = useSelector((state) =>  state.info.siteInfo);
  const states = useSelector((state) =>  state.info.states);

  const from = props.location.state;
  const history = useHistory();

  useEffect(() => {

    if(find(info, {"section":page})){

      setpageData(info.filter(obj => obj.section === page)[0])
    }
    else{

      setpageData({})

    }

  },[info,page]);

  return(
    <div>
    {!isEmpty( pageData ) ? (
      <Grid
      centered
      container
      style={{margin:"3em 0em"}}
      >
        <Grid.Row >
          <Grid.Column >
            <Segment padded basic>
              <Header as="h2" textAlign="center">
                {pageData.header}
              </Header>
            </Segment>
          </Grid.Column >
        </Grid.Row>
        <Divider style={{margin:"0em"}}/>
        <Grid.Row >
          <Grid.Column >
            <Segment padded basic>
              {(page === 'about') && (
                <Image src={pageData.img_raw} rounded size='medium' floated='right'/>
              )}
              <div dangerouslySetInnerHTML={{__html: pageData.body}}></div>
            </Segment>
          </Grid.Column>
          </Grid.Row>
      </Grid>
    ):(
      <Grid container style={{margin:"3em 0em"}}>
        <Grid.Row >
          <Grid.Column>
          <Segment placeholder basic>
          <Header icon>
            <Image src={`${endpoint}${"/media/55-error-outline.gif"}`} />
            Oops! We can't find this page. Please try again later.
          </Header>

        </Segment>
          </Grid.Column>
        </Grid.Row >
      </Grid>
  )}
  </div>
  )

};
