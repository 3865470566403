import React, { useRef, useState, useEffect, useCallback } from 'react'

import { render } from 'react-dom'
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Header,
  Segment,
  Input,
  Button,
  Modal,
  Icon,
  Form
} from "semantic-ui-react";

import { useTransition, animated } from 'react-spring'
import { emailCreateURL} from "../../constants";
import axios from "axios";
import { useDispatch, useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import {HeaderAnimation} from '../../Components/HeaderAnimation/HeaderAnimation'
import { authLogin } from "../../store/actions/auth";
import './Parking.css'


function LoginModal(props) {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const loading = useSelector((state) => state.auth.loading);
  const [formData, setformData] = useState({"email":"","password":""});
  const history = useHistory();
  const handleChange = e => {
    const updatedFormdata = {
      ...formData,
      [e.target.name]: e.target.value
    };
    setformData(updatedFormdata);
  };

  const handleSubmit = () => {
    console.log(formData)

    dispatch(authLogin(formData.email, formData.password, history));
  };

  return (
    <Modal
      basic
      onClose={() => props.modalChange}
      onOpen={() => props.modalChange}
      open={props.modalOpen}
      size='small'

    >
      <Header icon>
        <Icon name='lock' />
        Admin login
      </Header>
      <Modal.Content>
      <Form inverted size="small" onSubmit={handleSubmit} style={{ marginTop: "2em" }}>

          <Form.Field

            onChange={handleChange}
            value={formData.email}
            name="email"
            label='Email'
            placeholder="Email"
            control='input'

          />

          <Form.Field
            onChange={handleChange}
            value={formData.password}
            name="password"
            label='Password'
            placeholder="Password"
            type="password"
            control='input'
          />



      </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='red' inverted onClick={() => props.modalChange(false)}>
          <Icon name='remove' /> Cancel
        </Button>
        <Button color='blue' inverted onClick={() => handleSubmit()}>
          <Icon name='checkmark' /> Login
        </Button>
      </Modal.Actions>
    </Modal>
  )
}


class ParkingPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      emailList:'',
      emailSaving:false,
      emailSuccess:false,
      emailButton:'Subscribe',
      emailPlaceholder:'Enter your email',
      error:false,
      modalOpen:false,
    };
  }

  handleCreateEmail = () => {
    const {emailList} = this.state;
    this.setState({
      emailSaving: true,

    });
    axios
      .post(emailCreateURL, {

        email: emailList,

      })
      .then(res => {
        this.setState({
          emailSaving: false,
          emailSuccess: true,
          emailList: '',
          emailButton: 'Thank You!',
          emailPlaceholder: 'Thank you for joining!'
        });

      })
      .catch(err => {
        this.setState({
          emailSaving: false,
          emailList: '',
          error: true });
          console.log(err)
      });
  };

  onChangeEmail = (e) => {
    this.setState({
      error:false,
      emailList:  e.target.value
    });
  };

  modalChange = (bool) => {
    if (bool) {
      this.setState({
        modalOpen:  bool
      });
    }
    else{
    this.setState({
      modalOpen:  !this.state.modalOpen
    });
    }
  };

  render() {
    const { emailSaving, emailSuccess, emailButton, emailPlaceholder, emailList, error, modalOpen} = this.state;
    return (
      <div className='parking-container'>
      <div className='parking-center'>
      <LoginModal modalOpen={modalOpen} modalChange={this.modalChange} />
      <Grid container className='parking-grid'  columns={1} stackable>
      <Grid.Row verticalAlign="top" columns={2} style={{minWidth:"80%"}}>
      <Grid.Column>
      <div>
      <HeaderAnimation />
      </div>
      </Grid.Column>
      <Grid.Column >

      <div className='parking-input'>
      <p className="subscribe-header">Please subscribe to our mailing list!</p>
      <Input
      fluid
      error={error}
      value={emailList}
      onChange={this.onChangeEmail}
      placeholder={emailPlaceholder}
      />
      </div>
      <div className='parking-input'>
      <Button basic inverted fluid
      disabled={emailSuccess} loading={emailSaving} onClick={()=> this.handleCreateEmail()} style={{ marginTop: "1em"}}>
      {emailButton}
      </Button>
      </div>

      </Grid.Column>
      </Grid.Row>

      </Grid>

      </div>
      <Button color="black" onClick={()=> this.modalChange()}>
      
      </Button>
      </div>
    );

  }
}
export default ParkingPage;
