import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector} from 'react-redux'
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Container,
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Icon
} from "semantic-ui-react";
import { connect } from "react-redux";
import { NavLink, Redirect,useHistory } from "react-router-dom";
import { authLogin } from "../../store/actions/auth";
import {exists, has} from "lodash"
import './Login.css'


export default function Login (props) {
  const dispatch = useDispatch()
  const [formData, setformData] = useState({"email":"","password":""});

  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) =>  state.auth.error);
  const token = useSelector((state) =>  state.auth.token);

  const from = props.location.state;
  const history = useHistory();

  if (token) {

  }



  const handleChange = e => {
    const updatedFormdata = {
      ...formData,
      [e.target.name]: e.target.value
    };
    setformData(updatedFormdata);
  };

  const handleSubmit = e => {

    e.preventDefault();
    dispatch(authLogin(formData.email, formData.password, history));
  };

  return(
<div className="order-container">
    <Grid
    centered
    container
    stackable
    style={{padding:"2em 0em"}}
    >
    <Grid.Row verticalAlign="middle" style={{ margin:"0em 0em"}} >

      <Grid.Column >
      <Segment basic className="order-segment">
        <Header as="h2" textAlign="left">
          Sign in
        </Header>

        <Header sub textAlign="left">
          New to Coella? <NavLink to="/signup">Sign Up</NavLink>
        </Header>

          <Form size="small" onSubmit={handleSubmit} style={{ marginTop: "2em" }}>

              <Form.Field
                onChange={handleChange}
                value={formData.email}
                name="email"
                label='Email'
                placeholder="Email"
                control='input'
              />

              <Form.Field
                onChange={handleChange}
                value={formData.password}
                name="password"
                label='Password'
                placeholder="Password"
                type="password"
                control='input'
              />

              <Button
                color="black"
                fluid
                className = "login-button"
                loading={loading}
                disabled={loading}
              >
                Login
              </Button>
              {(has(error, 'non_field_errors')) && (
                <Message negative>
                {error.non_field_errors}
                </Message>

              )

              }
          </Form>

        </Segment>
      </Grid.Column>
      </Grid.Row>
    </Grid>
    </div>
  )

};
