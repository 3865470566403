import { ORDER_CREATE,ORDER_SUCCESS,ORDER_START,ORDER_FETCHED,ORDER_FAIL,ORDER_VALIDATED,ORDER_RESET,ERROR_TIMEOUT } from "../actions/actionTypes";
import { updateObject } from "../utility";
import produce from 'immer';

const initialState = {
  order:{
    contact:{},
    shipping:{'country':'US'},
    postage:{},
    payment:{},
    validated:false
    },
  completedOrder:{},
  loading: false,
  loadingMessage: '',
  redirect_URL:'',
  complete: false,
  error:{},
  control_total:0,
  };


const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {

      case ORDER_START:
        draft.loadingMessage = action.loadingMessage;
        draft.loading = true;
        draft.error = {};
        break;

      case ORDER_CREATE:
        if ("shipping" in action.data) {
          draft.order['shipping'] = (action.data.shipping);
        };
        if ("contact" in action.data) {
          draft.order['contact'] = (action.data.contact);
        };
        if ("validated" in action.data) {
          draft.order['validated'] = (action.data.validated);
        };
        if ("postage" in action.data) {
          draft.order['postage'] = (action.data.postage);
        };
        if ("billing" in action.data) {
          draft.order['billing'] = (action.data.billing);
        };
        if ("payment" in action.data) {
          draft.order['payment'] = (action.data.payment);
        };
        break;

      case ORDER_FAIL:
        return { ...state, loading: false, error:action.error }

      case ERROR_TIMEOUT:
        draft.error = {};
        break;

      case ORDER_VALIDATED:
        draft.order = (action.data);
        draft.loading = false;
        draft.error = {};
        break;

      case ORDER_FETCHED:
        draft.completedOrder = (action.data);
        draft.loading = false;
        break;

      case ORDER_SUCCESS:
        draft.redirect_URL = (action.data.redirect_URL);
        draft.loading = false;
        draft.error = {};
        draft.order = initialState.order

      case ORDER_RESET:
        draft.redirect_URL = initialState.redirect_URL;
        break;

      default:
        break;
  }

},);

export default reducer;
