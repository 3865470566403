import axios from "axios";
import { connect } from "react-redux";
import { endpoint, shipping_types, collections,categories,urls } from "./constants";
import { useCallback } from "react";

export const ccySymbol = {
  "usd":"$",
  "gbp":"£",
  "eur":"€",
}

export const authAxios =

  axios.create({
    baseURL: endpoint,
    headers: {
      Authorization: `token ${localStorage.getItem("token")}`
    }
});

export const authAxiosStore = (token) => {
  return
    axios.create({
    baseURL: endpoint,
    headers: {
      Authorization: `Token ${token}`
    }
    });
};

export const getShipping = (type) => {
    let text = "";
    shipping_types.forEach(i => {
      if (i.key === type) {
        console.log(i.content)
        text += `${i.content}`;
      }

    });
    return text;
};

// Return filtered products
const filterProducts = (category,array) => {
  let filteredArray = array.filter(
    (product) => product.category === category
  );
  if (category==="Stella Antoinette"){
    filteredArray = array.filter(
      (product) => product.collection === category
    );
  }
  return filteredArray.length > 0 ?  filteredArray : array;
}

function getCollectionArray (array) {
  let newArray = []
  let dataKeys = Object.values(array)
  let newData = dataKeys.map((itemValue,i) => {
    newArray[i] = itemValue[1]
  })
  return newArray
}

// Return the filter method.
export function useFilter(filterCollection, filterValue, search) {
  return useCallback(
    function(data) {

      const collectionNames = getCollectionArray(collections)

      if (collectionNames.includes(filterCollection)){

        if (data.collection === filterCollection && data.category === filterValue){
          var isFilterMatch = true

        }
        if (data.collection === filterCollection && filterValue ==="All"){
          var isFilterMatch = true

        }
      }
      else if (data.category === filterValue && data.collection != "Stella Antoinette")
        {
          var isFilterMatch = true
        }
      else if (filterValue === "All"){
        var isFilterMatch = true
      }

      var filterItem = filterValue === "Stella Antoinette" ? data.collection : data.category
      var isSearchMatch = !search
        ? true
        : data.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
      /*var isFilterMatch = filterValue === "All" ? true : filterItem === filterValue;*/
      return isSearchMatch && isFilterMatch;
    },
    [filterValue, search]
  );

}

export const sortOptions = [
  { key: 0, value: 'price', text: 'Lowest price'},
  { key: 1, value: 'price:desc', text: 'Highest Price' },
  { key: 2, value: 'name', text: 'Alphabetically, A-Z' },
  { key: 3, value: 'category', text: 'Item Type' },
]

export const filterOptions = [
  { key: 0, value: 'All', url:'', text: 'All Items' },
  { key: 1, value: 'Necklace', url:'necklaces',text: 'Necklaces' },
  { key: 2, value: 'Bracelet', url:'bracelets',text: 'Bracelets' },
  { key: 3, value: 'Earring', url:'earrings',text: 'Earrings' },
  { key: 4, value: 'Anklet', url:'anklets',text: 'Anklets' }
]

export function getThumbnail(orderItem) {
  if (orderItem.item.img[0]) {
    return `${orderItem.item.img[0].img_thumb}`
  }
  else {
    return "https://api.coellalove.com/media/image.png"
  }
};

// Grid static options.
export const options = {
  dragSortHeuristics: {
    sortInterval: 70
  },
  layoutDuration: 400,
  dragRelease: {
    duration: 400,
    easing: "ease-out"
  },
  dragEnabled: false,
  dragCssProps: {
    touchAction: 'pan-y',
    userSelect: '',
    userDrag: '',
    tapHighlightColor: '',
    touchCallout: '',
    contentZooming: '',
  },
  dragContainer: document.body,
  // The placeholder of an item that is being dragged.
  dragPlaceholder: {
    enabled: true,
    createElement: function(item) {
      // The element will have the Css class ".muuri-item-placeholder".
      return item.getElement().cloneNode(true);
    }
  }
};
