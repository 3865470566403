import React, { createRef,useState, useContext, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import {
  Image,
  Label,
  Message,
  Card,
  Header,
  Input,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Dimmer,
  Segment,
  Menu
  } from "semantic-ui-react";

import { fetchCart } from "../../store/actions/cart";
import { fetchProducts } from "../../store/actions/products";

import { MuuriComponent,getResponsiveStyle } from "muuri-react";
import {isEmpty, countBy, filter, has} from "lodash";
import { useFilter, options, sortOptions, filterOptions } from "../../utils";
import './ProductList.css'

import {Media} from "../../App";
import { collections,categories,urls } from "../../constants";
import {ErrorMessage} from '../../Components/ErrorMessage/ErrorMessage'
import {ProductGrid} from '../../Components/ProductGrid/ProductGrid'
import ScaleLoader from "react-spinners/ScaleLoader";


import {Helmet} from "react-helmet";


const renderProductCount =(productCount) => {
    if (productCount === 1) {
      return "1 Item"
    }
    else{
      return productCount + " Items"
    }
}

const triggerFilter = (
    <span>
      <Icon name='filter' /> Filter by
    </span>
)

const triggerSort = (
    <span>
      <Icon name='sort amount down' /> Sort by
    </span>
)

function ProductMobile (props) {

  const [filterSearch, setfilterSearch] = useState('');
  const [sortValue, setsortValue] = useState('category');
  const [filterValue, setfilterValue] = useState(props.activeItem);
  const [productCount, setProductCount] = useState(0);

  useEffect( () => {
    setfilterValue(props.activeItem)
  },[props.activeItem]
  );

  const handleCategoryFilter = (e, data) => {

    if (!has(categories, urls[props.activeItem[0]])){
      const newFilter = [...filterValue];
      newFilter[0] = data.value;
      setfilterValue(newFilter)
    }
    else{
      setfilterValue(props.activeItem)
    }
  };

  const handleSearch = e => {
    setfilterSearch(e.target.value);
  };

  const handleSort = (e, data) => {
    setsortValue(data.value);
  }

  const pushItemCallback = (category,slug) => {
    var catUrl = urls[category]
    props.history.push(`/products/${catUrl}/${slug}`)
  }

  return(
    <Grid container style={{padding:"1em 0em",margin:"0em"}}>
    <Grid.Row>
    <Grid.Column style={{padding:"0"}}>
    <Input fluid icon='search' placeholder='What are you looking for?' onKeyUp={handleSearch} />
    </Grid.Column>
    </Grid.Row>
    <Grid.Row columns= {2}>
    <Grid.Column style={{padding:"0em 1em 0em 0em"}}>
    <Dropdown
      className="filter-dropdown"
      fluid
      wrapSelection={false}
      onChange={handleCategoryFilter}
      options={filterOptions}
      value={filterValue[0]}
      button
      trigger={triggerFilter}
    />
    </Grid.Column>
    <Grid.Column style={{padding:"0em 0em 0em 1em"}}>
    <Dropdown
      className="filter-dropdown"
      fluid
      onChange={handleSort}
      options={sortOptions}
      button
      trigger={triggerSort}
    >
    </Dropdown>
    </Grid.Column>
    </Grid.Row>
    <Grid.Row centered>
    <p><b>{renderProductCount(productCount)}</b></p>
    </Grid.Row>
    <Grid.Row stretched>
    <Grid.Column style={{ padding:"0", height:"100%"}}>
    <div >
      {props.error && (
        <ErrorMessage error={props.error}/>
      )}
      {props.loading ? (
        <div style={{ margin:"2em 0em",display: "flex", justifyContent:"center" }}>
          <ScaleLoader color="#000000" loading={props.loading}  />
        </div>
      ):
      (
        <ProductGrid
        props = {props}
        filterValue={filterValue[0]}
        filterCategory={filterValue[1]}
        filterSearch={filterSearch}
        sortValue={sortValue}
        pushItem={pushItemCallback}
        itemCountCallback={(c)=>setProductCount(c)}
        />
      )}
    </div>
    </Grid.Column>
    </Grid.Row>
    </Grid>
  )
}

function ProductDesktop (props) {
  const [filterSearch, setfilterSearch] = useState('');
  const [sortValue, setsortValue] = useState('category');
  const [productCount, setProductCount] = useState(0);
  const [filterValue, setfilterValue] = useState(props.activeItem);

  useEffect( () => {
    setfilterValue(props.activeItem)
  },[props.activeItem]
  );

  const handleCategoryFilter = (e, data) => {

    if (!has(categories, urls[props.activeItem[0]])){
      const newFilter = [...filterValue];
      newFilter[0] = data.value;
      setfilterValue(newFilter)
    }
    else{
      setfilterValue(props.activeItem)
    }
  };

  const handleSearch = e => {
    setfilterSearch(e.target.value);
  };

  const handleSort = (e, data) => {
    setsortValue(data.value);
  };

  const pushItemCallback = (category,slug) => {
    props.history.push(`/products/${category}/${slug}`)
  };

return(
    <Grid style={{margin:"1em 0em"}}>
    <Grid.Row columns={2}>
    <Grid.Column textAlign="left">
    <Input  icon='search' placeholder='What are you looking for?' onKeyUp={handleSearch} />
    </Grid.Column>
    <Grid.Column textAlign="right">
    <Dropdown
      className="filter-dropdown"
      onChange={handleSort}
      options={sortOptions}
      button
      trigger={triggerSort}
    >

   </Dropdown>
    </Grid.Column>
    </Grid.Row>
    <Grid.Row>
    <Grid.Column>
    <p>{renderProductCount(productCount)}</p>
    </Grid.Column>
    </Grid.Row>
    <Grid.Row>
    <Grid.Column >
    <div >
      {props.error && (
        <ErrorMessage error={props.error}/>
      )}
      {props.loading ? (
        <div style={{ margin:"2em 0em",display: "flex", justifyContent:"center" }}>
        <ScaleLoader color="#000000" loading={props.loading}  />
        </div>
      ):
      (
      <ProductGrid
      props = {props}
      filterValue={filterValue[0]}
      filterCategory={filterValue[1]}
      filterSearch={filterSearch}
      sortValue={sortValue}
      pushItem={pushItemCallback}
      itemCountCallback={(c)=>setProductCount(c)}
      />
      )}
    </div>
    </Grid.Column>
    </Grid.Row>
    </Grid>
  )
}

export default function ProductList (props) {
  const {match: { params }} = props;
  const { category } = useParams()
  const dispatch = useDispatch()
  const userCCY = useSelector((state) => state.info.userCCY);
  const products = useSelector((state) => state.products.products);
  const loading = useSelector((state) => state.products.loading);
  const error = useSelector((state) => state.products.error);
  var [filteredProducts, setfilteredProducts] = useState([]);
  const [activeItem, setactiveItem] = useState(["All","Gold Filled Jewelry"]);

  const getCategory = (category) => {
    if (category in categories) {
      return categories[category]
    }
    else if (category in collections) {
      return collections[category]
    }
    else {
      return ["All","Gold Filled Jewelry"]
    }
  }

  const filterItem = (url) => {
    setactiveItem(getCategory(url));
  }

  const filterProducts = (category,array) => {
    let filteredArray = array.filter(
      (product) => product.category === category
    );
    if (category==="Stella Antoinette"){
      filteredArray = array.filter(
        (product) => product.collection === category
      );
    }
    return filteredArray.length > 0 ?  filteredArray : array;
  }

  useEffect(() => {
    if (userCCY){
      window.scrollTo(0, 0);
      dispatch(fetchProducts(userCCY));
    }
  }, [userCCY])

  useEffect(() => {
    setactiveItem(getCategory(category));
  }, [category])



  return (
    <div>
    <Helmet>
      <title>{activeItem[1]}</title>
      <meta name="description" content={activeItem[1]} />
    </Helmet>
    <Grid  container className="container-grid" style={{padding:"0em 0em", margin:"0em 0em", }}>
    <Grid.Row style={{height: "8em"}}>
      <Grid.Column verticalAlign="middle" textAlign="center" >
        <Header className="product-header">
        {activeItem[1]}
        </Header>
      </Grid.Column>
    </Grid.Row>
    <Divider style={{margin: "0em 1em"}}/>
    <Grid.Row >
    <Grid.Column style={{padding:"0"}}>
    <Media at="mobile" >

    <ProductMobile {...props}
    filterItem={(url)=>filterItem(url)}
    activeItem={activeItem}
    products={products}
    loading={loading}
    error={error}/>
    </Media>

    <Media greaterThan="mobile">

    <ProductDesktop {...props}
    filterItem={(url)=>filterItem(url)}
    activeItem={activeItem}
    products={products}
    loading={loading}
    error={error}/>

    </Media>
    </Grid.Column>
    </Grid.Row>
    </Grid>
    </div>
  );

}
