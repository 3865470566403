import { ADDRESS_START, ADDRESS_SUCCESS, ADDRESS_FAIL, STATES_SUCCESS, COUNTRIES_SUCCESS, ADDRESS_RESET, ADDRESS_TEMP } from "../actions/actionTypes";
import { updateObject, handleGetDefault, handleFormatStates } from "../utility";

const initialState = {
  addresses: [],
  defaultShippingAddress: false,
  error: {},
  loading: false,
  states:[],
  countries:[]
};

const addressStart = (state, action) => {
  return updateObject(state, {
    error: {},
    loading: true
  });
};

const addressSuccess = (state, action) => {
  return updateObject(state, {
    addresses: action.data,
    defaultShippingAddress: handleGetDefault(action.data),
    error: {},
    loading: false
  });
};

const addressTemp = (state, action) => {
  return updateObject(state, {
    defaultShippingAddress: action.data,
    error: null,
    loading: false
  });
};

const statesSuccess = (state, action) => {
  return updateObject(state, {
    states: handleFormatStates(action.data),
    error: {},
    loading: false
  });
};

const countriesSuccess = (state, action) => {
  return updateObject(state, {
    countries: handleFormatStates(action.data),
    error: {},
    loading: false
  });
};

const addressUpdated = (state, action) => {
  return updateObject(state, {
    error: {},
    loading: false
  });
};

const addressFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const addressReset = (state, action) => {
  return updateObject(state, {
    addresses: [],

  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADDRESS_START:
      return addressStart(state, action);
    case ADDRESS_SUCCESS:
      return addressSuccess(state, action);
    case ADDRESS_TEMP:
      return addressTemp(state, action);
    case STATES_SUCCESS:
      return statesSuccess(state, action);
    case COUNTRIES_SUCCESS:
      return countriesSuccess(state, action);
    case ADDRESS_FAIL:
      return addressFail(state, action);
    case ADDRESS_RESET:
      return addressReset(state, action);
    default:
      return state;
  }
};

export default reducer;
