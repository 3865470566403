import React, { useState, useEffect } from 'react'

import {
  Segment,
  Header,

} from "semantic-ui-react";

import './TopBanner.css'
import { Spring, animated, useTransition, useSpringRef} from 'react-spring'

const bannerMessages = [
  "FREE STANDARD SHIPPING on all orders",
  "Use code GRATEFUL20 for 20% off site wide!",
]

function BannerCarousal() {
  const [index, set] = useState(0)
  const onClick = () => set(state => (state + 1) % 3)
  const transRef = useSpringRef()
  const transitions = useTransition(index, {
    ref: transRef,
    keys: null,
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  })

  useEffect(() => {
    transRef.start()
  }, [index,transRef])

  return (
    <div onClick={onClick}>
      {transitions((i) => {

        return <p>{bannerMessages[i]}</p>
      })}
    </div>
  )
}

function Toggle() {
  const [toggle, set] = useState(false)
  const transitions = useTransition(toggle, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: toggle,
    delay: 8000,

    onRest: () => set(!toggle),
  })
  return transitions(({ opacity }, item) =>
    item ? (
      <animated.div style={{
        position: 'absolute',
        opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
      }}>
      <Header className='banner-item' as="h5" style={{color:'#ffffff'}}>
         FREE STANDARD SHIPPING on all orders
      </Header>
      </animated.div>
    ) : (
      <animated.div

        style={{
          position: 'absolute',
          opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
        }}>
        <Header className='banner-item' as="h5" style={{color:'#ffffff'}}>
           Use code GRATEFUL20 for 20% site wide!
        </Header>
      </animated.div>
    )
  )
}

export default function TopBanner (props) {
  const [bannerText, setBannerText] = useState(bannerMessages[0])
  const [bannerCounter, setBannerCounter] = useState(0)
  const [toggle, setToggle] = useState(false)

  useEffect( () => {
    setTimeout(() => setToggle((prevToggle) => !prevToggle), 10000);
    if (bannerCounter === 1){
      setBannerCounter(0)
    }
    else
    {
      setBannerCounter(1)
    }
  }, []
  );

  return(
    <Segment className='banner'>

    <Spring
      config= {{ frequency: 1, tension:	170,friction: 26 }}
     from={{ opacity: 0.25}}
     to={[
       {  opacity: 1},

     ]}>
     {styles => (
       <animated.div style={styles} className='spring-item'>
       <Header className='banner-item' as="h5" style={{color:'#ffffff'}}>
          {bannerMessages[0]}
       </Header>
       </animated.div>
     )}
   </Spring>


    </Segment>
  )
}
