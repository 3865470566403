import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useStripe,
  useElements,
  PaymentRequestButtonElement
} from "@stripe/react-stripe-js";

import {
  Container,
  Grid,
  Message,
  Header
} from "semantic-ui-react";
import axios from "axios";
import { applePayURL } from "../../constants";
import { ccySymbol } from "../../utils";







export const ApplePay = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);

  const chargeAmount = props.chargeAmount*100 ;
  const userCurrency = props.userCCY ;
  const userCountry = props.userCountry ;

  useEffect(() => {
    if(!stripe || !elements ) {
      return;
    }

    if (!userCurrency){
      return;
    }

    const pr = stripe.paymentRequest({
      currency:userCurrency,
      country:"US",
      requestPayerEmail:true,
      requestPayerName:true,
      total:{
        label:'Coella LLC',
        amount: chargeAmount,
      }
    })
    pr.canMakePayment().then((result)=> {
        if(result) {
          setPaymentRequest(pr);

        }
    });
    pr.on('paymentmethod', async (e) => {
        const {clientSecret} = await fetch(applePayURL,{
          method:'POST',
          headers:{
            'Content-Type':'application/json',
          },
          body: JSON.stringify({
            paymentMethodType: 'card',
            currency: userCurrency,
            chargeAmount: chargeAmount,
          }),
        }).then(r=>r.json());
          //confirm the payment intent on the client
          const {error,paymentIntent} = await stripe.confirmCardPayment(
            clientSecret,{
              payment_method:e.paymentMethod.id,
            },{
              handleActions:false,
            }
          )
          if(error){

            e.complete('fail');
            props.message(`Error: ${error}`);
            props.onError(error)
            return;
          }

          e.complete('success');
          if (paymentIntent.status === "requires_action") {
            stripe.confirmCardPayment(clientSecret)
          }
          props.onApprove(paymentIntent)
          props.message(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    });
  },[stripe,elements,props.message,userCurrency, userCountry] )




  return(
    <>
    {paymentRequest && <PaymentRequestButtonElement options={{paymentRequest}}/>}
    </>
  )

}


export function ApplePayTest (props){
  const userCCY = useSelector((state) => state.info.userCCY);
  const [message, addMessage] = useState(null);
  const [chargeAmount, setChargeAmount] = useState(0.51);

  const messageCallback = (m) => {
    addMessage(m)

  }

  return (
     <div style={{margin:"3em 0em"}}>
     <Container text>

     {userCCY &&
     <ApplePay userCCY={userCCY} chargeAmount={chargeAmount} message={messageCallback}/>
     }

     <p>
     Charge amount
     </p>
     <Header style={{margin:"1em 0em"}}>
     {ccySymbol[userCCY]}0.51
     </Header>
     <p>
     Updated 3/10/2023
     </p>
     {message &&
     <Message>
        <Message.Header>{message}</Message.Header>

      </Message>
      }
     </Container>
     </div>
   )



}
