import { USER_START, USER_SUCCESS, USER_FAIL } from "./actionTypes";
import { authAxios } from "../../utils";
import axios from "axios";
import { endpoint, userIDURL } from "../../constants";

export const userStart = () => {
  return {
    type: USER_START
  };
};

export const userSuccess = data => {
  return {
    type: USER_SUCCESS,
    data
  };

};

export const userFail = error => {
  return {
    type: USER_FAIL,
    error: error
  };
};

export const fetchUser = () => {
  return dispatch => {
    dispatch(userStart());
    authAxios
      .get(userIDURL)
      .then(res => {
        console.log(res.data)
        dispatch(userSuccess(res.data));
        })
      .catch(err => {
        dispatch(userFail(err));
      });
  };
};

export const fetchUserTest = () => {
  return dispatch => {
    dispatch(userStart());
    const token = localStorage.getItem("token");
    axios
        .create({
        baseURL: endpoint,
        headers: {
          Authorization: `Token ${token}`
        }
        })
      .get(userIDURL)
      .then(res => {
        
        dispatch(userSuccess(res.data));

      })
      .catch(err => {
        dispatch(userFail(err));
      });
  };
};
