import React, { useState, useEffect  } from "react";
import { useDispatch,useSelector } from 'react-redux'
import {baseServer} from "../../constants";

import {
  Grid,
  Divider,
  Header,
  Segment,
  Rating,
  Button,
  Comment,
  Form,
  Message,
  Dimmer
} from "semantic-ui-react";

import {isEmpty, has} from 'lodash';
import { createProductReview } from "../../store/actions/products";


const ReviewFeed =(props) => {
  return(
    <Comment.Group>
    {props.data.map((review, i) => (

  <Comment key={i}>
    <Comment.Avatar src={`${baseServer}${review.avatar}`} />
    <Comment.Content>
      <Comment.Author as='a'>{review.name}</Comment.Author>
      <Comment.Metadata>
        <div>{new Date(review.date_submitted).toLocaleDateString('en-us',{ year:"numeric", month:"short", day:"numeric"})}</div>
      </Comment.Metadata>
      <Comment.Text><Rating size="large" rating={review.rating} maxRating={5} disabled/></Comment.Text>
      <Comment.Text style={{margin:"0.5em 0em",fontSize:"16px",fontWeight:"600"}}>{review.title}</Comment.Text>
      <Comment.Text>{review.text}</Comment.Text>

    </Comment.Content>
  </Comment>
))}


</Comment.Group>
  )
}

const ReviewForm = (props) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.userData);
  const [formData, setformData] = useState({"slug":props.slug,"rating":null,"name":"","email":"","title":"","text":""});
  const formError = useSelector((state) => state.products.errorReview);

  useEffect( () => {

    const updatedFormdata = {
      ...formData,
      "user": user.userID,
      "name": user.first_name,
      "email": user.email
    };
    setformData(updatedFormdata);
  },[user]
  );

  const updateForm =(e) => {
      const updatedFormdata = {
        ...formData,
        [e.target.name]: e.target.value,
      };
      setformData(updatedFormdata);
  }

  const handleRate = (e, { rating, maxRating }) => {
    const updatedFormdata = {
      ...formData,
      "rating":rating,
    };
    setformData(updatedFormdata);

  }



  return(
    <Grid>
    <Grid.Row>
        <Grid.Column>
        <p>Write a Review</p>
        </Grid.Column>
    </Grid.Row>

    <Grid.Row>
    <Grid.Column>
    <Form>
    <Form.Field required>
    <label>Rating</label>
      <Rating size="huge" onRate={handleRate} rating={formData.rating} maxRating={5}/>
      {(has(formError, 'rating')) && (
            <Message >Please choose a rating </Message>
        )}
    </Form.Field>
    <Form.Group widths='equal'>
    <Form.Field required>
      <label>Your name</label>
      <Form.Input onChange={updateForm} name="name"
      value={formData.name}
      error={(has(formError, 'name')) ? {
            content: formError.name[0]
        }:false}/>
    </Form.Field>
    <Form.Field required>
      <label>Email</label>
      <Form.Input onChange={updateForm} name="email" value={formData.email}
      error={(has(formError, 'email')) ? {
            content: formError.email[0]
        }:false}/>
    </Form.Field>
    </Form.Group>
    <Form.Field required>
      <label>Title</label>
      <Form.Input onChange={updateForm} name="title" value={formData.title}
      error={(has(formError, 'title')) ? {
            content: formError.title[0]
        }:false}/>
    </Form.Field>
    <Form.Field required>
      <label>Review</label>
      <Form.TextArea onChange={updateForm} name="text" value={formData.text}
      error={(has(formError, 'text')) ? {
            content: formError.text[0]
        }:false}/>
    </Form.Field>


  </Form>

  </Grid.Column>
  </Grid.Row>
  <Grid.Row>
  <Grid.Column>

  <Button style={{borderRadius:"0em"}} onClick={()=> dispatch(createProductReview(formData))} color="black" floated="right">
  Post
  </Button>
  <a onClick={()=>props.hideForm()} color="black" floated="right">
  Cancel
  </a>
  </Grid.Column>
  </Grid.Row>
  </Grid>
  )

}


export function ProductReview (props){
  const [reviewData, setreviewData] = useState({});
  const [formActive, setformActive] = useState(false);
  const loading = useSelector((state) => state.products.loading);

  const formatReviewCount = (array) =>{
    if (array.length === 1) {
      return "1 Review"
    }
    else{
      return array.length + " Reviews"
    }
  }

  return(
    <Dimmer.Dimmable as={Grid} blurring dimmed={loading} verticalAlign="middle" style={{marginBottom:"3em"}}>

    <Grid.Row>
    <Grid.Column >
    <div >
    {props.avgRating > 0 && (
    <span style={{margin:"0em 0.2em", fontSize:"40px"}}>{props.avgRating.toFixed(1)}</span>
    )}

    <Rating   size='massive' rating={Math.ceil(props.avgRating)} maxRating={5} disabled/>


    <span style={{margin:"1em 1em"}}>{formatReviewCount(props.reviews)}</span>
    </div>
    </Grid.Column>
    </Grid.Row>
    <Divider/>
    <Grid.Row columns={2}>
    <Grid.Column>
    {!formActive && (
    <p className="nav-link" style={{fontSize:"14px"}}>Reviews</p>
    )}
    </Grid.Column>
    <Grid.Column>

    <Button style={{borderRadius:"0em"}} onClick={()=>setformActive(true)} basic floated="right">
    Write a Review
    </Button>
    </Grid.Column>
    </Grid.Row>
    <Divider/>
    <Grid.Row verticalAlign="middle" style={{minHeight:"10em"}}>
    <Grid.Column >
    {formActive ? (
      <ReviewForm hideForm={()=>setformActive(false)} slug={props.slug}/>


  ):(
    <div>
    {!isEmpty(props.reviews) ? (

      <ReviewFeed data={props.reviews}/>

    ):(

    <Segment basic textAlign="center">

    <Button onClick={()=>setformActive(true)} style={{backgroundColor:"#DABBBB", borderRadius:"0em",color:"#FFFFFF", fontWeight:"500"}}>Be the first to write a review</Button>
    </Segment>

  )}
  </div>
      )}
    </Grid.Column>
    </Grid.Row>
    </Dimmer.Dimmable>
  )

}
