import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector} from 'react-redux'
import { fetchCart } from "../../store/actions/cart";

import {
  Input,
  Message,
  Grid,
  Segment,
  Header,
  Divider,
  Icon
} from "semantic-ui-react";
import axios from "axios";
import {
    addGiftCardURL,
  } from "../../constants";
import { Spring, animated } from 'react-spring'
import './GiftCardForm.css'
import {Media} from "../../App";


export default function GiftCardForm (props) {
  const dispatch = useDispatch()
  const [code, setcode] = useState("");
  const [couponerror, setcouponerror] = useState("");
  const [visible, setvisible] = useState(false);
  const [loading, setloading] = useState(false);
  const giftCardApplied = useSelector((state) => state.cart.shoppingCart.giftcard > 0);
  const handleChange = e => {
    setcode(e.target.value);
  };

  const handleAddGiftcard = (code) => {

      setloading(true);
      axios
        .post(addGiftCardURL, { code })
        .then(res => {
          setloading(false);

          dispatch(fetchCart());
        })
        .catch(err => {

          setcouponerror(err.response.data.message);
          setloading(false);
          setTimeout(() => {
            setcouponerror(null)
          }, 2000);
        });


  };


  const toggleForm = () => {
    setvisible(!visible)


  }


  const AnimatedMessage = animated(Message);

  return (
    <Segment basic className="coupon-segment" style={{ margin: "2em 0em" }}>
    {giftCardApplied ? (
      <Grid>
      <Grid.Row columns={2}>
      <Grid.Column>
      <p className="giftcard-segment-header">

         Gift card applied
      </p>
      </Grid.Column>
      <Grid.Column textAlign="right">
      <p>
      <Icon link name="minus" size="small" onClick={toggleForm}/>
      Remove
      </p>
      </Grid.Column>
      </Grid.Row>
      </Grid>
    ):(
      <div>
    <p className="giftcard-segment-header">
       <Icon link name="plus" size="small" onClick={toggleForm}/>
       Apply a Gift Card
    </p>
    {visible && (
      <Grid style={{marginTop:"0.5em"}}>
      <Grid.Row>
      <Grid.Column>
      <Input fluid action={{content:'Apply', color:'black', onClick: () => handleAddGiftcard(code)}} placeholder='Gift card code' onChange={handleChange}/>
      {(couponerror) && (
        <Spring
          config= {{ velocity: 0.5, tension:	200,friction: 12 }}
          from={{  y: -5}}
          to={[
            {  y: 0},
         ]}>
         {styles => (
           <AnimatedMessage
            style={styles}
            color="purple"
             content={couponerror}
           />
         )}
       </Spring>
      )}
      </Grid.Column>
      </Grid.Row>
      </Grid>
    )}
    </div>
  )}
      </Segment>
    );
}
