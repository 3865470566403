import React from 'react'

import {
  Container,
  Button,
  Divider,
  Grid,
  Header,
  List,
  Segment,
  Icon,
  Input,
  Message
} from "semantic-ui-react";

import { createMedia } from '@artsy/fresnel';
import { Spring, animated, config, useSpring} from 'react-spring'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { emailCreateURL} from "../../constants";
import axios from "axios";
import './Footer.css'
import { fetchUser } from "../../store/actions/user";


class Footer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      emailList:'',
      emailSaving:false,
      emailSuccess:false,
      emailButton:'Subscribe',
      emailPlaceholder:'Enter your email',
      error:false
    };
  }

  componentDidMount() {

    console.log()
  }

  handleCreateEmail = () => {
    const {emailList} = this.state;
    this.setState({
      emailSaving: true,

    });
    axios
      .post(emailCreateURL, {

        email: emailList,

      })
      .then(res => {
        this.setState({
          emailSaving: false,
          emailSuccess: true,
          emailButton: 'Thank You!',
          emailPlaceholder: 'Thank you for joining!'
        });

      })
      .catch(err => {
        this.setState({
          emailSaving: false,
          error: true });
          console.log(err)
      });
  };

  onChangeEmail = (e) => {
    this.setState({
      error:false,
      emailList:  e.target.value
    });
  };


  render() {

    const { emailSaving, emailSuccess, emailButton, emailPlaceholder, error} = this.state;
    const AnimatedMessage = animated(Message);
    return (

        <Segment
          inverted
          vertical
          className="footer-segment"
        >
          <Container textAlign="center">
            <Grid divided inverted stackable>
            <Grid.Row columns={2}>
              <Grid.Column >
              <div>
                <Header inverted as="h4" content="Subscribe" />
                <p>Please subscribe to our email list!</p>
                <div >
                {emailSuccess && (
                  <Spring
                    config= {{ velocity: 0.5, tension:	200,friction: 12 }}
                    from={{  y: -5}}
                    to={[
                      {  y: 0},
                   ]}>
                   {styles => (
                     <AnimatedMessage
                      style={styles}
                      size='small'
                      content='Thank you for subscribing!'
                     />
                   )}
                 </Spring>

                )}
                <Input
                fluid
                action={{

                  labelPosition: 'left',
                  icon: 'mail',
                  content: 'Subscribe',
                  onClick:()=> this.handleCreateEmail(),
                  disabled:emailSuccess,
                  loading:emailSaving
                }}
                error={error}
                onChange={this.onChangeEmail}
                placeholder={emailPlaceholder}
                />
                </div>

                </div>
              </Grid.Column>

              <Grid.Column >
                  <List style={{color:"#FFFFFF"}}>

                    <List.Item className="footer-list" as={Link} to="/about">About Us</List.Item>
                    <List.Item className="footer-list" as={Link} to="/returns">Return Policy</List.Item>
                    <List.Item className="footer-list" as={Link} to="/terms">Terms & Conditions</List.Item>

                  </List>

                  <div>

                   <p>Support <Icon name="mail outline"/> <span><b><a className="support-link" href="mailto:support@coellalove.com">support@coellalove.com</a></b></span></p>
                 </div>


              </Grid.Column>
              </Grid.Row>
            </Grid>

            <Divider inverted section />

            <Header as='h5' inverted>

            Coella LLC 2022
            </Header>
          </Container>
        </Segment>

    );
  }
}

const mapStateToProps = state => {

  return {
    authenticated: state.auth.token !== null,
    authLoading: state.auth.loading,
    user: state.user.userData,
    userLoading: state.user.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {

    fetchUser: () => dispatch(fetchUser()),
  };
};

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Footer)
;
