

const server = `https://api.coellalove.com`;
const localhost = `http://127.0.0.1:8000`;

export const baseServer = server;
export const endpoint = `${baseServer}/v1`;


export const loginAPI = `${endpoint}/rest-auth/login/`;
export const registrationAPI = `${endpoint}/rest-auth/registration/`;
export const productListURL = ccy => `${endpoint}/products/?ccy=${ccy}`;
export const productDetailURL = (id,ccy) => `${endpoint}/products/${id}/?ccy=${ccy}`;
export const addToCartURL = `${endpoint}/add-cart/`;
export const reduceCartURL = `${endpoint}/reduce-cart/`;
export const removeCartURL = `${endpoint}/remove-cart/`;
export const getCartURL = `${endpoint}/cart/`;
export const orderSummaryURL = `${endpoint}/order-summary/`;
export const checkoutURL = `${endpoint}/test-order/`;
export const orderDetailURL = id => `${endpoint}/order/${id}/`;
export const addCouponURL = `${endpoint}/add-coupon/`;
export const addGiftCardURL = `${endpoint}/add-giftcard/`;
export const removeGiftCardURL = `${endpoint}/remove-giftcard/`;
export const countryListURL = `${endpoint}/countries/`;
export const stateListURL = `${endpoint}/states/`;
export const userIDURL = `${endpoint}/user-id/`;
export const paymentListURL = `${endpoint}/payments/`;
export const paymentCreateURL = `${endpoint}/payments/create/`;
export const paymentUpdateURL = id =>`${endpoint}/payments/${id}/update/`;
export const paymentDeleteURL = id =>`${endpoint}/payments/${id}/delete/`;
export const emailCreateURL = `${endpoint}/email/create/`;
export const addressListURL = addressType => `${endpoint}/addresses/?address_type=${addressType}`;
export const addressCreateURL = `${endpoint}/addresses/create/`;
export const addressUpdateURL = id => `${endpoint}/addresses/${id}/update/`;
export const addressDeleteURL = id => `${endpoint}/addresses/${id}/delete/`;
export const createOrderURL = `${endpoint}/create-order/`;
export const orderItemDeleteURL = id => `${endpoint}/order-items/${id}/delete/`;
export const orderItemUpdateQuantityURL = `${endpoint}/order-item/update-quantity/`;
export const orderHistoryURL = `${endpoint}/order-history/`;
export const orderQueryURL = id => `${endpoint}/order/${id}/`;
export const siteInfoURL = country => `${endpoint}/site-info/?country=${country}`;
export const createReviewURL =  `${endpoint}/products/review/create/`;
export const applePayURL =  `${endpoint}/apple-pay/`;
export const addPostageURL =  `${endpoint}/add-postage/`;

export const shipping_types = [
  {key:'US-FC', type:'dom', content:'USPS First-Class Mail', fee:'FREE', active:true},
  {key:'SC-GPP', type:'intl', content:'GlobalPost Standard International', fee:'FREE', active:true},
  {key:'US-XM', type:'dom', content:'USPS Priority Mail Express', fee:'$25.00', active:false}
]

export const ccySymbol = {
  "usd":"$",
  "gbp":"£",
  "eur":"€",
}

export const collections = {
                    'stella':['All','Stella Antoinette'],

                    }

export const categories = {

                    'necklaces':['Necklace','Necklaces'],
                    'earrings':['Earring','Earrings'],
                    'bracelets':['Bracelet','Bracelets'],
                    'anklets':['Anklet','Anklets'],
                    'extras':['Extra','Extras']
                    }

export const urls = {
              'Stella':'stella',
              'Necklace':'necklaces',
              'Bracelet':'bracelets',
              'Earring':'earrings',
              'Anklet':'anklets',
              'Extra':'extras'
              }
