import { PRODUCT_START, REVIEW_START, PRODUCT_SUCCESS, PRODUCT_DETAIL_SUCCESS, PRODUCT_FAIL, REVIEW_FAIL } from "./actionTypes";
import { authAxios } from "../../utils";
import axios from "axios";
import { productListURL,productDetailURL,createReviewURL } from "../../constants";

export const productStart = () => {
  return {
    type: PRODUCT_START
  };
};

export const reviewStart = () => {
  return {
    type: REVIEW_START
  };
};

export const productSuccess = data => {
  return {
    type: PRODUCT_SUCCESS,
    data
  };

};

export const productDetailSuccess = data => {
  return {
    type: PRODUCT_DETAIL_SUCCESS,
    data
  };

};

export const productFail = error => {
  return {
    type: PRODUCT_FAIL,
    error: error
  };
};

export const reviewFail = error => {
  return {
    type: REVIEW_FAIL,
    error: error
  };
};

export const fetchProducts = (ccy) => {
  return dispatch => {
    dispatch(productStart());
    axios
      .get(productListURL(ccy))
      .then(res => {
        dispatch(productSuccess(res.data));

        })
      .catch(err => {
        dispatch(productFail(err));
      });
  };
};

export const fetchProductDetail = (productSlug, history, ccy) => {
  return dispatch => {
    dispatch(productStart());
    axios
      .get(productDetailURL(productSlug,ccy))
      .then(res => {
        dispatch(productDetailSuccess(res.data));
        })
      .catch(err => {
        history.push(`/products`);
        dispatch(productFail(err));
      });
  };
};


export const createProductReview = (formData) => {
  return dispatch => {
  dispatch(reviewStart());

  axios
    .post(createReviewURL, formData)
    .then(res => {
      dispatch(fetchProductDetail(formData.slug));
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        dispatch(reviewFail(error.response.data.message));
        //setTimeout(() => {
          //  dispatch({ type: ERROR_TIMEOUT })
        //  }, 5000)
      } else if (error.request) {
        // The request was made but no response was received
        console.log("ERROR REQUEST");
        dispatch(reviewFail(error.message));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }

    });
  };
};
