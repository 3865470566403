import React, { useState, useEffect  } from "react";
import { useSelector, useDispatch } from 'react-redux'


import {
  Grid,
  Divider,
  Header,
  Radio,
  Card,
  Segment
} from "semantic-ui-react";

import { buildOrder } from "../../store/actions/order";
import { shipping_types } from "../../constants";




export default function Shipping (props) {
  const [shippingMethod, setshippingMethod] = useState('US-FC');
  const [shippingTypes, setshippingTypes] = useState(shipping_types);
  const dispatch = useDispatch()
  const address = props.address

  const order = useSelector((state) => state.order.order)
  const [postage, setPostage] = useState(order.postage);


  useEffect( () => {

    const formData = postage;
    const updatedFormdata = {
      ...formData,
      "type":shippingMethod,
      "description":shipping_types.find(obj => obj.key === shippingMethod).content,
      "amount":shipping_types.find(obj => obj.key === shippingMethod).fee
    };


    dispatch(buildOrder({"postage": updatedFormdata}));

  },[shippingMethod, shipping_types,dispatch]
  );

  useEffect( () => {

    if (address.country === "US") {
      setshippingTypes(
        shipping_types.filter(function(el){
         return el.type === "dom"
        })
    )
    }
    else {

      setshippingTypes(
        shipping_types.filter(function(el){
          return el.type === "intl"
        })
      );
      setshippingMethod("SC-GPP");
    }

  },[address]
  );

  const radioToggle = (e, value) => {
    const postage={"type": value.value}
    setshippingMethod(value.value);

  };

  return(
    <Segment basic className="order-segment">
    <p className="order-segment-header">
       Shipping Method
    </p>

    {shippingTypes.map((type, i) => (
      <Card key={i} fluid>
      <Card.Content>
        <Grid>
          <Grid.Row>
          <Grid.Column width={10}>
          <Radio value={type.key} label={type.content} checked={shippingMethod === type.key} onChange={radioToggle} disabled={!type.active}/>
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            <p>{type.fee}</p>
          </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
      </Card>
    ))}
    </Segment>
  )
}
