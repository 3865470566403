import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useTransition, animated } from 'react-spring'

import './HeaderAnimation.css'
import {
  Header,
} from "semantic-ui-react";



export function HeaderAnimation() {
  const ref = useRef([])
  const [items, set] = useState([])
  const transitions = useTransition(items, {
    from: {
      opacity: 0,
      height: 0,
      innerHeight: 0,
      transform: 'perspective(600px) rotateX(0deg)',
      color: '#BEA69C',
      },
    enter: [
      { opacity: 1, height: 80, innerHeight: 80 },
      { transform: 'perspective(600px) rotateX(0deg)', color: '#DC9A90' },
      { transform: 'perspective(600px) rotateX(0deg)' },
    ],
    leave: [{ color: '#D9C890' }, { innerHeight: 0 }, { opacity: 1, height: 0 }],
    update: { color: '#FAF3E6' },
  })

  const reset = useCallback(() => {
    ref.current.forEach(clearTimeout)
    ref.current = []
    set([])
    ref.current.push(setTimeout(() => set(['Feel', 'Love']), 0))
    ref.current.push(setTimeout(() => set(['Show', 'Love']), 2000))
    ref.current.push(setTimeout(() => set(['Coella', 'Love']), 5000))
  }, [])

  useEffect(() => {
    reset()
    return () => ref.current.forEach(clearTimeout)
  }, [])

  const AnimatedHeader = animated(Header);

  return (
    <div className='header-container'>
      <div className='main'>
        {transitions(({ innerHeight, ...rest }, item) => (
          <animated.div className='transitionsItem' style={rest} onClick={reset}>
            <animated.div style={{ overflow: 'hidden', height: innerHeight }}>{item}</animated.div>
          </animated.div>

        ))}
      </div>

    </div>
  )
}
